import { ServiceRegion } from "@/const/service/region";
import i18n from "@/plugins/vue-i18n";
import { Channel, CONTENT_CHANNEL } from "@/views/cms/content/common/choices";
import { SEARCH_TYPE, SearchType } from "@/views/member/search/common/choices";
import { AgeChoiceItem } from "@/const/service/common/types";
import { ChoiceItem } from "@/common/types";
import { FilterItem } from "@/components/filter/common/types";
import { singleContentBMFilterItems } from "@/components/filter/common/items";
import { DataLanguage } from "@/store/modules/data-language";

//TODO: 추후 확실히 정해지면 바꿔야한다.
export class Thailand extends ServiceRegion {
  getAgeChoices(): AgeChoiceItem[] {
    return [
      { text: i18n.t("cms.ageLimit.all").toString(), value: 0, color: "green" },
      {
        text: "15",
        value: 15,
        color: "orange",
      },
      {
        text: i18n.t("cms.ageLimit.adult").toString(),
        value: 18,
        color: "red",
      },
    ];
  }

  getAdultAge(): number {
    return 18;
  }

  getChannelChoices(): ChoiceItem<Channel>[] {
    return CONTENT_CHANNEL.getChoices().filter(
      (choice) => choice.value === Channel.ONE,
    );
  }

  getAvailableSectionList(): string[] {
    return [
      "main_featured",
      "main_featured_last",
      "main_gidamoo",
      "main_special",
      "channel_mon",
      "channel_tue",
      "channel_wed",
      "channel_thu",
      "channel_fri",
      "channel_sat",
      "channel_sun",
      "channel_completed",
      "ad_event_viewer",
      "ad_content_home",
      "ad_rank_all",
    ];
  }

  genreRankingEnabled(): boolean {
    return true;
  }

  ch2Enabled(): boolean {
    return false;
  }

  possessionTicketEnabled(): boolean {
    return true;
  }

  getMemberSearchType(): ChoiceItem<string>[] {
    return Object.values(SEARCH_TYPE.getChoices()).filter((choice) =>
      [SearchType.USER_ID, SearchType.EMAIL, SearchType.CS_ID].includes(
        choice.value as SearchType,
      ),
    );
  }

  getMemberSearchTypeHint(): Partial<Record<SearchType, string>> {
    return {
      [SearchType.USER_ID]: i18n
        .t("member.search.searchTypeHints.USER_ID_HINT")
        .toString(),
      [SearchType.CS_ID]: i18n
        .t("member.search.searchTypeHints.CS_ID_HINT")
        .toString(),
      [SearchType.EMAIL]: i18n
        .t("member.search.searchTypeHints.GLO_EMAIL_HINT")
        .toString(),
    };
  }

  getMemberDataShow(): boolean {
    return false;
  }

  changeNormalBannerTextColorEnabled(): boolean {
    return true;
  }

  adEnabled(): boolean {
    return false;
  }

  webToAppEnabled(): boolean {
    return true;
  }

  bannerEventSpecialCardMaxLength(): number {
    return 4;
  }

  getDataLanguages(): DataLanguage[] {
    return [{ lang: "th", flag: "th", label: "ภาษาไทย" }];
  }

  combinationBMEnabled(): boolean {
    return false;
  }

  sendManuscriptToolEnabled(): boolean {
    return false;
  }

  getContentBMFilterItems(): FilterItem[] {
    return singleContentBMFilterItems();
  }

  showReleasedForFreeDateTime(): boolean {
    return true;
  }

  showKakaopageEpisodeGet(): boolean {
    return false;
  }

  showKakaopageOriginalNovelContentLink(): boolean {
    return false;
  }

  getRewardCautionInCampaignNoticeModule(): string {
    return "เงื่อนไขในการเข้าร่วมกิจกรรม";
  }

  getEventCautionInCampaignNoticeModule(): string {
    return (
      "หากมีตั๋วที่แลกไว้แล้ว สามารถใช้ได้โดยไม่ต้องแลกใหม่\n" +
      "สามารถตรวจสอบรางวัลที่ได้รับ และวันหมดอายุของรางวัล ได้ที่หน้า \"แจ้งเตือน>ข่าวสารของฉัน\"\n" +
      "ของรางวัลไม่สามารถแลกเปลี่ยนเป็นเงินสดได้\n" +
      "การตัดสินของกรรมการถือเป็นที่สิ้นสุด"
    );
  }

  gidamooPlusEnabled(): boolean {
    return true;
  }
}
