export interface Setting {
  lang: string;
}

const state: Setting = {
  lang: "ko",
};

const getters = {
  lang: (state: Setting): string => {
    return state.lang;
  },
};

const mutations = {
  setLanguage(state: Setting, lang: string): void {
    state.lang = lang;
  },
};

export default {
  state,
  getters,
  mutations,
};
