import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,{attrs:{"id":"inspire"}},[(_setup.signedIn)?_c(_setup.NavigationDrawer,{model:{value:(_setup.drawer),callback:function ($$v) {_setup.drawer=$$v},expression:"drawer"}}):_vm._e(),_c(VAppBar,{attrs:{"app":"","clipped-left":""}},[_c(VAppBarNavIcon,{on:{"click":function($event){_setup.drawer = !_setup.drawer}}}),_c(VToolbarTitle,{domProps:{"textContent":_vm._s(_vm.$t('msg.kakaoWebtoon'))}}),_c(VSpacer),(_setup.signedIn)?_c(_setup.ToolbarDataLanguage):_vm._e(),_vm._v(" "+_vm._s(_setup.time)+" "),_c(VDivider,{staticClass:"ml-5 mr-3",attrs:{"vertical":"","inset":""}}),(_setup.signedIn)?_c('span',[_c('span',{domProps:{"textContent":_vm._s(_setup.staffId)}}),_c(VMenu,{attrs:{"offset-y":"","min-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({attrs:{"icon":""}},on),[_c(VIcon,[_vm._v("account_circle")])],1)]}}],null,false,255133734)},[_c(VList,{attrs:{"subheader":"","dense":""}},[_c(VSubheader,[_vm._v("Settings")]),_c(VListItem,[_c(VListItemTitle,[_vm._v("Language")]),_c(VListItemSubtitle,[_c(_setup.ToolbarAdminLanguage)],1)],1)],1),_c(VDivider),_c(VList,{attrs:{"dense":""}},[_c(VListItem,{on:{"click":function($event){return _setup.signOut()}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(_vm.$t('msg.signOut'))}})],1),_c(VListItemIcon,[_c(VIcon,[_vm._v("exit_to_app")])],1)],1)],1)],1)],1):_vm._e()],1),_c(VMain,[(!_setup.signedIn)?_c(VContainer,{attrs:{"fluid":"","fill-height":""}},[_c(VLayout,{attrs:{"justify-center":"","align-center":""}},[_c(VBtn,{staticClass:"ma-2 white--text",attrs:{"color":"grey"},domProps:{"textContent":_vm._s(_vm.$t('msg.signIn'))},on:{"click":function($event){return _setup.openSignInPage()}}})],1)],1):_vm._e(),(_setup.signedIn)?_c('router-view'):_vm._e()],1),_c(VFooter,{attrs:{"app":""}}),_c(_setup.GoTopButton)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }