import { Snack } from "@/store/types";
import {
  toastError,
  toastInfo,
  toastSuccess,
  toastWarning,
} from "@/utils/alerts";

const mutations = {
  async pushSnack(state: unknown, snack: Snack): Promise<void> {
    switch (snack.color) {
      case "success":
        await toastSuccess(snack.text, snack.timeout);
        return;
      case "warning":
        await toastWarning(snack.text, snack.timeout);
        return;
      case "error":
        await toastError(snack.text, snack.timeout);
        return;
      default:
      case "info":
        await toastInfo(snack.text, snack.timeout);
        return;
    }
  },
};

export default {
  mutations,
};
