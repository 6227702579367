import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{staticClass:"leftDrawer",attrs:{"fixed":"","clipped":_setup.Vuetify.framework.breakpoint.mdAndUp,"app":""},model:{value:(_setup.value),callback:function ($$v) {_setup.value=$$v},expression:"value"}},[_c(VList,{attrs:{"dense":""}},[_vm._l((_setup.menuItems.filter(
        (_item) =>
          _setup.hasPermission(_item) &&
          _setup.isAvailableRegion(_item) &&
          _setup.isAvailableEnv(_item),
      )),function(item){return [(item.children)?_c(VListGroup,{key:item.text,attrs:{"prepend-icon":item.icon,"value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)]},proxy:true}],null,true)},_vm._l((item.children.filter(
            (_item) =>
              _setup.hasPermission(_item) &&
              _setup.isAvailableRegion(_item) &&
              _setup.isAvailableEnv(_item),
          )),function(child,i){return _c(VListItem,{key:i,on:{"click":function($event){return _setup.go(child)}}},[(!child.children)?_c(VListItemAction,[(child.icon)?_c(VIcon,{domProps:{"textContent":_vm._s(child.icon)}}):_vm._e()],1):_vm._e(),(!child.children)?_c(VListItemContent,[_c(VListItemTitle,{class:child.path &&
                _vm.$route.path.startsWith(child.path) &&
                'primary--text text--lighten-1 font-weight-black',domProps:{"textContent":_vm._s(child.text)}})],1):_vm._e(),(!child.children && child.endIcon)?_c(VListItemAction,[_c(VIcon,{domProps:{"textContent":_vm._s(child.endIcon)}})],1):_vm._e(),(child.children)?_c(VListGroup,{key:child.text,attrs:{"prepend-icon":child.icon,"value":false,"no-action":"","sub-group":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(child.text)}})],1)]},proxy:true}],null,true)},_vm._l((child.children.filter(
                (_item) =>
                  _setup.hasPermission(_item) &&
                  _setup.isAvailableRegion(_item) &&
                  _setup.isAvailableEnv(_item),
              )),function(grandchild,_i){return _c(VListItem,{key:_i,on:{"click":function($event){return _setup.go(grandchild)}}},[_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(grandchild.text)}})],1),(grandchild.endIcon)?_c(VListItemAction,[_c(VIcon,{domProps:{"textContent":_vm._s(grandchild.endIcon)}})],1):_vm._e()],1)}),1):_vm._e()],1)}),1):(!item.children)?_c(VListItem,{key:item.text,on:{"click":function($event){return _setup.go(item)}}},[_c(VListItemAction,[_c(VIcon,{domProps:{"textContent":_vm._s(item.icon)}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(item.text)}})],1)],1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }