import axios, { AxiosInstance } from "axios";
import { envs } from "@/const/dotenv";
import store from "@/store";

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: envs.GATEWAY_URL,
});

export const axiosKakaopageInstance: AxiosInstance = axios.create({
  baseURL: envs.GATEWAY_URL,
});

// 모든 요청마다 헤더에 토큰 추가
axiosInstance.interceptors.request.use(
  (config) => {
    const token = store.getters.accessToken;
    if (config.headers) {
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      config.headers["Language"] = store.getters.dataLanguage;
      config.headers["Staff-Id"] = store.getters.staffId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 토큰 갱신이 필요하면 갱신 후 재요청
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response?.status !== 401 ||
      error.response?.data?.detail !== "ACCESS_TOKEN_EXPIRED"
    ) {
      return Promise.reject(error);
    }

    const originalRequest = error.config;
    if (!originalRequest._retry) {
      // refreshToken 으로 accessToken 갱신 시도
      originalRequest._retry = true;
      const refreshToken = store.getters.refreshToken;

      if (!refreshToken) {
        // refreshToken 이 없는 경우
        store.commit("clearToken");
        return Promise.resolve(error);
      }

      return axiosInstance
        .put("/auth/token", {
          token: refreshToken,
        })
        .then((res) => {
          if (res.status !== 200) {
            return Promise.reject(error);
          }

          store.commit("setToken", res.data);
          axiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + store.getters.accessToken;
          return axiosInstance(originalRequest);
        })
        .catch(function (error) {
          store.commit("clearToken");
          return Promise.resolve(error);
        });
    }

    return Promise.reject(error);
  },
);

axiosKakaopageInstance.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers["Authorization"] = "Bearer " + store.getters.accessToken;
      config.headers["Language"] = store.getters.dataLanguage;
      config.headers["Kakaopage-Access-Token"] =
        store.getters.kakaopageAccessToken;
      config.headers["Staff-Id"] = store.getters.staffId;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
