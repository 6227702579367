import jwt_decode from "jwt-decode";

export interface Token {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export interface AccessToken {
  auth_time: number;
  exp: number;
  iat: number;
  username: string;
  staff_id: string;
  permitted: boolean;
  permissions: string[];
}

export interface AuthState {
  accessToken: string;
  refreshToken: string;
  expirationTime: number;
  username: string;
  staffId: string;
  permitted: boolean;
  permissions: string[];
}

const state = {
  accessToken: "",
  refreshToken: "",
  expirationTime: 0,
  username: "",
  staffId: "",
  permitted: false,
  permissions: [],
} as AuthState;

const getters = {
  accessToken: (state: AuthState): string => {
    return state.accessToken;
  },
  refreshToken: (state: AuthState): string => {
    return state.refreshToken;
  },
  expirationTime: (state: AuthState): number => {
    return state.expirationTime;
  },
  username: (state: AuthState): string => {
    return state.username;
  },
  staffId: (state: AuthState): string => {
    return state.staffId;
  },
  permitted: (state: AuthState): boolean => {
    return state.permitted;
  },
  permissions: (state: AuthState): string[] => {
    return state.permissions;
  },
};

const mutations = {
  setToken(state: AuthState, token: Token): void {
    state.accessToken = token.access_token;
    if (token.refresh_token) state.refreshToken = token.refresh_token;

    if (token.access_token) {
      const decodedAccessToken: AccessToken = jwt_decode(token.access_token);
      state.expirationTime = decodedAccessToken.exp;
      state.username = decodedAccessToken.username;
      state.staffId = decodedAccessToken.staff_id;
      state.permitted = decodedAccessToken.permitted;
      state.permissions = decodedAccessToken.permissions;
    } else {
      state.expirationTime = 0;
      state.username = "";
      state.staffId = "";
      state.permitted = false;
      state.permissions = [];
    }
  },

  clearToken(state: AuthState): void {
    state.accessToken = "";
    state.refreshToken = "";
    state.expirationTime = 0;
    state.username = "";
    state.staffId = "";
    state.permitted = false;
    state.permissions = [];
  },
};

export default {
  state,
  getters,
  mutations,
};
