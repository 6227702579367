export default Object.freeze({
  en: {
    guide: {
      displayForAdmin:
        "Entered information will not be displayed to the users (for internal reference only).",
    },
    moduleTitle: "Reward settings",
    mainModuleTitle: "Main reward settings",
    itemType: "Reward type",
    settlementCode: "Cash settlement code",
    cashName: "Cash name",
    cashCode: "Cash code",
    rewardAmount: "Reward amount",
    cashAmount: "Reward amount",
    validPeriod: "Expiry date",
    plusReceived: "Expires +",
    validPeriodBy0DayMessage: "on the day received (until 23:59)",
    validPeriodByDayMessage: "{value} day(s) from date received (until 23:59)",
    validPeriodBy0HourMessage: "on the time received (until 59m 59s)",
    validPeriodByHourMessage:
      "{value} hour(s) from time received (until 59m 59s)",
    emptyContentThumbnail:
      "Unable to save because primary thumbnail A does not exist.",
    notForSaleContent: "The content is not for sale.",
    checkTheEMT: "Please check the EMT.",
    beingPublishedContentErrorMessage1:
      "You have selected a ticket for 'pre-sale' content as the reward.",
    beingPublishedContentErrorMessage2:
      "Please be careful not to give the user a ticket before the content is converted to 'on-sale' status.",
    beingPublishedContentErrorMessage3: "Date & time of reservation: {value}",
    choices: {
      REWARD_ITEM_TYPE: {
        CASH: "Event cash",
        TICKET: "Gift ticket",
        PRESENT: "Present",
        LUCKY_DRAW: "Lucky draw",
        RAFFLE: "Raffle",
      },
      REWARD_VALID_PERIOD_TYPE: {
        BY_DAY: "By day",
        BY_HOUR: "By hour",
        EXPIRED: "By specific date",
      },
    },
    cash: {
      eventRemainAmount: "Remain amount : {value}",
      validRemainAmount:
        "The remaining cash is less than the reward amount and cannot be selected.",
    },
    present: {
      listPageTitle: "Present list",
      listPageTitleHint: "Create and manage rewards for the campaign.",
      name: "Present name",
      schedule: "Available time period",
      winnerList: "Winners list",
      newPageTitle: "Create present",
      editPageTitle: "Edit present",
      viewPageTitle: "View present",
      type: "Winner information acquisition type",
      userInformation: "User information",
      userInformationHint:
        "Please enter the information you want to receive from the user.\n" +
        "Minumum 1, maximum 4 items can be entered.",
      limit: "Number of presents available",
      limitHint: "Enter number of presents created on EMT.",
      luckyDrawPresentImage: "Lucky draw background image",
      luckyDrawWinningImage: "Lucky draw result page image",
      tickerPresentImage: "Ticker image",
      rewardPresentImage: "Reward image",
      eventPagePresentImage: "Event page box image",
      choices: {
        PRESENT_METADATA_TYPE: {
          INTERNAL: "Information direct input (Global)",
          EXTERNAL: "Information external input (Korea)",
        },
      },
    },
    luckyDraw: {
      listPageTitle: "Lucky draw list",
      listPageTitleHint:
        "Create and manage lucky draw rewards for the campaign.",
      newPageTitle: "Create new lucky draw",
      editPageTitle: "Edit lucky draw",
      viewPageTitle: "View lucky draw",
      name: "Lucky draw name",
      theme: "Theme",
      currentWinner: "Number of winners",
      report: "Status",
      priority: "Weight",
      priorityHint:
        "Reward with the lowest probability and reward with the largest weight value will be displayed as a representative reward.",
      perPerson: "th(st/nd) user",
      repeatWin: "Repeat win",
      percentageHint: "Total probability must be 100%.",
      repeatUserWin: "Repeat user win",
      limitWinningCount: "Set maximum winning limit",
      limitDailyWinningCount: "Maximum number of wins per day",
      limitTotalWinningCount: "Maximum number of wins",
      invalidPercent100: "Total probability of winning must be 100.",
      invalidDuplicatePercent: "Each reward must have different probability.",
      invalidDuplicatePriority: "Each reward must have different weight.",
      invalidFirstPercentage:
        "The percentage (%) of the base reward must be the highest.",
      choices: {
        LUCKY_DRAW_THEME_TYPE: {
          SLOT_MACHINE: "Slot machine",
          GIFT_BOX: "Gift box",
        },
        LUCKY_DRAW_WINNING_RULE_TYPE: {
          SEQUENTIAL: "Sequential",
          PROBABILITY: "Percentage (%)",
        },
      },
    },
    raffle: {
      listPageTitle: "Raffle list",
      listPageTitleHint: "Create and manage rewards for the campaign.",
      name: "Raffle name",
      nameHint:
        "The name of the raffle will be exposed to gift boxes and my news.",
      schedule: "Available time period",
      newPageTitle: "Create raffle",
      editPageTitle: "Edit raffle",
      viewPageTitle: "View raffle",
      limit: "Number of raffles available",
      limitHint: "Enter number of raffles created on EMT.",
      luckyDrawRaffleImage: "Lucky draw background image",
      luckyDrawWinningImage: "Lucky draw result page image",
      tickerRaffleImage: "Ticker image",
      rewardRaffleImage: "Reward image",
      eventPageRaffleImage: "Event page box image",
    },
  },
  ko: {
    guide: {
      displayForAdmin:
        "사용자의 화면에선 노출되지 않는 이름입니다.(내부 참고용)",
    },
    moduleTitle: "리워드 설정",
    mainModuleTitle: "메인 리워드 설정",
    itemType: "리워드 종류",
    settlementCode: "캐시 정산 코드",
    cashName: "캐시 명",
    cashCode: "캐시 코드",
    rewardAmount: "리워드 수량",
    cashAmount: "리워드 금액",
    validPeriod: "유효 기간",
    plusReceived: "받은 시점 +",
    validPeriodBy0DayMessage: "지급 당일 (23:59까지)",
    validPeriodByDayMessage: "지급일로부터 {value}일 (23:59까지)",
    validPeriodBy0HourMessage: "지급 시간 (59분 59초까지)",
    validPeriodByHourMessage: "지급일로부터 {value}시간 (59분 59초까지)",
    emptyContentThumbnail:
      "작품의 대표 섬네일 이미지 A가 존재하지 않아 저장할 수 없습니다.",
    notForSaleContent: "해당 작품은 이용권을 판매하지 않는 작품입니다.",
    checkTheEMT: "EMT를 확인해주세요",
    beingPublishedContentErrorMessage1:
      "판매 전 작품의 이용권을 리워드로 선택했습니다.",
    beingPublishedContentErrorMessage2:
      "작품이 판매중으로 전환되기 전 유저에게 이용권이 지급되지 않도록 유의해주세요.",
    beingPublishedContentErrorMessage3:
      "해당 작품의 판매 전환 예정 시각: {value}",
    choices: {
      REWARD_ITEM_TYPE: {
        CASH: "이벤트 캐시",
        TICKET: "선물 이용권",
        PRESENT: "경품",
        LUCKY_DRAW: "뽑기권",
        RAFFLE: "응모권",
      },
      REWARD_VALID_PERIOD_TYPE: {
        BY_DAY: "일자 기준",
        BY_HOUR: "시간 기준",
        EXPIRED: "특정일 기준",
      },
    },
    cash: {
      eventRemainAmount: "잔여 금액 : {value}",
      validRemainAmount: "잔여 캐시가 리워드 금액보다 적어 선택할 수 없습니다.",
    },
    present: {
      listPageTitle: "경품 목록",
      listPageTitleHint: "캠페인에 지급될 리워드를 생성 및 관리합니다.",
      name: "경품 명",
      schedule: "경품 설정 가능 기간",
      winnerList: "당첨자 목록",
      newPageTitle: "새로운 경품 생성",
      editPageTitle: "경품 수정",
      viewPageTitle: "경품 보기",
      type: "당첨자 정보 취득 타입",
      userInformation: "유저 입력 정보",
      userInformationHint:
        "사용자에게 받고자 하는 정보를 입력해주세요.\n" +
        "항목은 최소 1개, 최대 4개까지 입력 가능합니다.",
      limit: "지급 가능 개수",
      limitHint: "EMT 에서 생성한 경품의 갯수를 입력 합니다.",
      luckyDrawPresentImage: "뽑기권 시작 이미지",
      luckyDrawWinningImage: "당첨 결과 이미지",
      tickerPresentImage: "티커 이미지",
      rewardPresentImage: "리워드 이미지",
      eventPagePresentImage: "이벤트 페이지 박스 이미지",
      choices: {
        PRESENT_METADATA_TYPE: {
          INTERNAL: "정보 직접 입력형(글로벌)",
          EXTERNAL: "정보 외부 입력형(한국)",
        },
      },
    },
    luckyDraw: {
      listPageTitle: "뽑기권 리스트",
      listPageTitleHint: "캠페인에 지급될 뽑기권을 생성 및 관리합니다.",
      newPageTitle: "새로운 뽑기권 생성",
      editPageTitle: "뽑기권 수정",
      viewPageTitle: "뽑기권 보기",
      name: "뽑기권 명",
      theme: "테마",
      currentWinner: "실시간 당첨자 수",
      report: "현황",
      priority: "가중치",
      priorityHint:
        "확률이 가장 낮은 리워드, 가중치 값이 가장 큰 리워드가 대표 리워드로 노출됩니다.",
      perPerson: "명 마다",
      repeatWin: "반복 당첨",
      percentageHint: "확률의 총합이 100%가 되어야 합니다.",
      repeatUserWin: "유저 반복 당첨",
      limitWinningCount: "최대 당첨 제한 설정",
      limitDailyWinningCount: "하루 최대 당첨 수",
      limitTotalWinningCount: "최대 당첨 수",
      invalidPercent100: "당첨 확률의 총합은 100이어야 합니다.",
      invalidDuplicatePercent: "동일한 확률을 가질 수 없습니다.",
      invalidDuplicatePriority: "동일한 가중치를 가질 수 없습니다.",
      invalidFirstPercentage: "기본 리워드의 퍼센트(%)가 제일 높아야 합니다.",
      choices: {
        LUCKY_DRAW_THEME_TYPE: {
          SLOT_MACHINE: "슬롯머신",
          GIFT_BOX: "선물박스",
        },
        LUCKY_DRAW_WINNING_RULE_TYPE: {
          SEQUENTIAL: "순차 지급",
          PROBABILITY: "퍼센트(%) 지급",
        },
      },
    },
    raffle: {
      listPageTitle: "응모권 목록",
      listPageTitleHint: "캠페인에 지급될 리워드를 생성 및 관리합니다.",
      name: "응모권 명",
      nameHint: "입력한 응모권 명칭은 선물함 및 내소식에 노출됩니다",
      schedule: "응모권 설정 가능 기간",
      newPageTitle: "새로운 응모권 생성",
      editPageTitle: "응모권 수정",
      viewPageTitle: "응모권 보기",
      luckyDrawRaffleImage: "뽑기권 시작 이미지",
      luckyDrawWinningImage: "당첨 결과 이미지",
      tickerRaffleImage: "티커 이미지",
      rewardRaffleImage: "리워드 이미지",
      eventPageRaffleImage: "이벤트 페이지 박스 이미지",
    },
  },
});
