export interface KakaopageUserToken {
  tokenType: string;
  accessToken: string;
  expires: string;
  createdDateTime?: number;
}

interface KakaopageUserTokenState extends KakaopageUserToken {
  createdDateTime: number;
}

const state: KakaopageUserTokenState = {
  tokenType: "",
  accessToken: "",
  expires: "",
  createdDateTime: 0,
};

const getters = {
  kakaopageAccessToken: (state: KakaopageUserTokenState): string => {
    return state.accessToken;
  },
  kakaopageExpires: (state: KakaopageUserTokenState): string => {
    return state.expires;
  },
  kakaopageUserTokenCreatedDateTime: (
    state: KakaopageUserTokenState,
  ): number => {
    return state.createdDateTime;
  },
  kakaopageUserToken: (state: KakaopageUserTokenState): KakaopageUserToken => {
    return state;
  },
};

const mutations = {
  setKakaopageUserToken(
    state: KakaopageUserTokenState,
    token: KakaopageUserToken,
  ): void {
    state.tokenType = token.tokenType;
    state.accessToken = token.accessToken;
    state.expires = token.expires;
    state.createdDateTime = Date.now();
  },
  clearKakaopageUserToken(state: KakaopageUserTokenState): void {
    state.tokenType = "";
    state.accessToken = "";
    state.expires = "";
    state.createdDateTime = 0;
  },
};

export default {
  state,
  getters,
  mutations,
};
