import { Channel } from "@/views/cms/content/common/choices";
import { AgeChoiceItem } from "@/const/service/common/types";
import { ChoiceItem } from "@/common/types";
import { SearchType } from "@/views/member/search/common/choices";
import { FilterItem } from "@/components/filter/common/types";
import { DataLanguage } from "@/store/modules/data-language";

export abstract class ServiceRegion {
  abstract getAgeChoices(): AgeChoiceItem[];
  abstract getChannelChoices(): ChoiceItem<Channel>[];
  abstract getAdultAge(): number;
  abstract getAvailableSectionList(): string[];
  abstract ch2Enabled(): boolean;
  abstract genreRankingEnabled(): boolean;
  abstract possessionTicketEnabled(): boolean;
  abstract getMemberSearchType(): ChoiceItem<string>[];
  abstract getMemberSearchTypeHint(): Partial<Record<SearchType, string>>;
  abstract getMemberDataShow(): boolean;
  abstract changeNormalBannerTextColorEnabled(): boolean;
  abstract adEnabled(): boolean;
  abstract webToAppEnabled(): boolean;
  abstract bannerEventSpecialCardMaxLength(): number;
  abstract getDataLanguages(): DataLanguage[];
  abstract combinationBMEnabled(): boolean;
  abstract sendManuscriptToolEnabled(): boolean;
  abstract getContentBMFilterItems(): FilterItem[];
  abstract showReleasedForFreeDateTime(): boolean;
  abstract showKakaopageEpisodeGet(): boolean;
  abstract showKakaopageOriginalNovelContentLink(): boolean;
  abstract getRewardCautionInCampaignNoticeModule(): string;
  abstract getEventCautionInCampaignNoticeModule(): string;
  abstract gidamooPlusEnabled(): boolean;
}
