import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VDialog,{attrs:{"max-width":_vm.$vuetify.breakpoint.thresholds.xs,"fullscreen":_vm.$vuetify.breakpoint.xsOnly},on:{"click:outside":_setup.close},model:{value:(_setup.dialog),callback:function ($$v) {_setup.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"rgb(66,66,66, 0.95)"}},[_c(VCardTitle,{domProps:{"textContent":_vm._s(_vm.$t('msg.adminLanguageSettings'))}}),_c(VCardText,[_vm._v(" "+_vm._s(_vm.$t("msg.adminLanguageSettingsComment"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("msg.saveBeforeChange"))+" "),_c(VRow,[_c(VSpacer),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"outlined":""},domProps:{"textContent":_vm._s(_vm.$t('msg.cancel'))},on:{"click":_setup.close}})],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"outlined":"","color":"create"},domProps:{"textContent":_vm._s(_vm.$t('msg.set'))},on:{"click":_setup.setLanguage}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }