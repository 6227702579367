import { ServiceLanguage } from "@/const/service/language";
import { CardLabel } from "@/views/sms/section/common/types/featured";
import { ChoiceItem } from "@/common/types";
import { MaxLength } from "@/const/service/common/types";

export class Taiwanese extends ServiceLanguage {
  getGenreChoices(): ChoiceItem<string>[] {
    return [
      { text: "奇幻", value: "FANTASY" },
      { text: "奇幻愛情", value: "FANTASY_ROMANCE" },
      { text: "動作冒險", value: "ACTION" },
      { text: "愛情", value: "SUNJUNG_ROMANCE" },
      { text: "劇情", value: "DRAMA" },
      { text: "BL/GL", value: "BL_GL" },
      { text: "懸疑恐怖", value: "THRILLER" },
    ];
  }

  getCardLabels(): CardLabel[] {
    return [
      // RED
      {
        text: "新作",
        backgroundColor: "#FF3042",
      },
      {
        text: "即將完結",
        backgroundColor: "#FF3042",
      },
      {
        text: "戲劇原著",
        backgroundColor: "#FF3042",
      },
      {
        text: "新一季上架",
        backgroundColor: "#FF3042",
      },
      {
        text: "人氣",
        backgroundColor: "#FF3042",
      },
      {
        text: "近期上架",
        backgroundColor: "#FF3042",
      },
      {
        text: "經典",
        backgroundColor: "#FF3042",
      },
      {
        text: "完結",
        backgroundColor: "#FF3042",
      },
      {
        text: "電影原著",
        backgroundColor: "#FF3042",
      },
      {
        text: "季完結",
        backgroundColor: "#FF3042",
      },
      // GOLD
      {
        text: "活動",
        backgroundColor: "#C6A66D",
      },
      {
        text: "新作搶先看",
        backgroundColor: "#C6A66D",
      },
      {
        text: "限時活動",
        backgroundColor: "#C6A66D",
      },
      {
        text: "完結活動",
        backgroundColor: "#C6A66D",
      },
      {
        text: "預約",
        backgroundColor: "#C6A66D",
      },
      {
        text: "今日限定",
        backgroundColor: "#C6A66D",
      },
      {
        text: "編輯推薦",
        backgroundColor: "#C6A66D",
      },
      {
        text: "新作上架",
        backgroundColor: "#C6A66D",
      },
      {
        text: "本週限定",
        backgroundColor: "#C6A66D",
      },
      {
        text: "爆更",
        backgroundColor: "#C6A66D",
      },
    ];
  }

  getCompletedTabLabels(): CardLabel[] {
    return [
      { text: "經典作品", backgroundColor: "#FF3042" }, // 레전드
      { text: "PD推薦", backgroundColor: "#FF3042" }, // PD추천
      { text: "電影預告", backgroundColor: "#FF3042" }, // 영화 예고편
      { text: "推薦", backgroundColor: "#FF3042" }, // 추천
      { text: "追漫誌", backgroundColor: "#FF3042" }, // 매거진
      { text: "借閱券", backgroundColor: "#FF3042" }, // 대여권
      { text: "金幣回饋", backgroundColor: "#FF3042" }, // 캐시백
    ];
  }

  getContentCatchphraseMaxLength(): MaxLength {
    return {
      threeLines: 15,
      twoLines: 20,
    };
  }

  getIpPromotionVideoTitleMaxLength(): number {
    return 12;
  }

  getIpPromotionVideoSubtitleMaxLength(): number {
    return 19;
  }

  getSpecialMatchingTitleMaxLength(): number {
    return 20;
  }

  getCustomLabelMaxLength(): number {
    return 8;
  }

  getCampaignPageTitleMaxLength(): number {
    return 13;
  }
  getCampaignPageModuleTitleMaxLength(): number {
    return 16;
  }
  getCampaignPageModuleTitleRowLength(): number {
    return 2;
  }
  getCampaignPageModuleSubTitleMaxLength(): number {
    return 23;
  }
  getCampaignPageModuleSubTitleRowLength(): number {
    return 4;
  }
}
