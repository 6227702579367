import i18n from "@/plugins/vue-i18n";
import { Choices } from "@/utils/choices";

enum ContentMenuTab {
  FORM = 0,
  PRODUCT = 1,
  EPISODES = 2,
  NOTICES = 3,
  EVENT_TAB = 4,
}
export const CONTENT_MENU_TAB = Object.freeze({ ...ContentMenuTab });

export enum Language {
  KOREAN = "ko",
  THAI = "th",
  INDONESIAN = "id",
  CHINESE = "zhc",
  TAIWANESE = "zht",
  FRENCH = "fr",
}

export enum Region {
  KOREA = "kor",
  THAILAND = "tha",
  TAIWAN = "twn",
  INDONESIA = "idn",
}

export enum BM {
  pay = "pay",
  early_access = "early_access",
  wait_for_free = "wait_for_free",
  wait_for_free_fix = "wait_for_free_fix",
  rerun_series = "rerun_series",
  pay_and_early_access = "pay_and_early_access",
  wait_for_free_and_early_access = "wait_for_free_and_early_access",
  pay_and_wait_for_free_fix = "pay_and_wait_for_free_fix",
  wait_for_free_and_fix = "wait_for_free_and_fix",
  free = "free",
}
export const CONTENT_BM = Object.freeze(
  new Choices(BM, {
    [BM.pay]: {
      text: i18n.t("cms.bm.choices.PAY").toString(),
      value: BM.pay,
    },
    [BM.early_access]: {
      text: i18n.t("cms.bm.choices.EARLY_ACCESS").toString(),
      value: BM.early_access,
    },
    [BM.wait_for_free]: {
      text: i18n.t("cms.bm.choices.WAIT_FOR_FREE").toString(),
      value: BM.wait_for_free,
    },
    [BM.wait_for_free_fix]: {
      text: i18n.t("cms.bm.choices.WAIT_FOR_FREE_FIX").toString(),
      value: BM.wait_for_free_fix,
    },
    [BM.rerun_series]: {
      text: i18n.t("cms.bm.choices.RERUN_SERIES").toString(),
      value: BM.rerun_series,
    },
    [BM.pay_and_early_access]: {
      text: i18n.t("cms.bm.choices.PAY_AND_EARLY_ACCESS").toString(),
      value: BM.pay_and_early_access,
    },
    [BM.wait_for_free_and_early_access]: {
      text: i18n.t("cms.bm.choices.WAIT_FOR_FREE_AND_EARLY_ACCESS").toString(),
      value: BM.wait_for_free_and_early_access,
    },
    [BM.pay_and_wait_for_free_fix]: {
      text: i18n.t("cms.bm.choices.PAY_AND_WAIT_FOR_FREE_FIX").toString(),
      value: BM.pay_and_wait_for_free_fix,
    },
    [BM.wait_for_free_and_fix]: {
      text: i18n
        .t("cms.bm.choices.WAIT_FOR_FREE_AND_WAIT_FOR_FREE_FIX")
        .toString(),
      value: BM.wait_for_free_and_fix,
    },
    [BM.free]: {
      text: i18n.t("cms.bm.choices.FREE").toString(),
      value: BM.free,
    },
  }),
);

export enum Channel {
  ONE = "ONE",
  TWO = "TWO",
}
export const CONTENT_CHANNEL = Object.freeze(
  new Choices(Channel, {
    [Channel.ONE]: {
      text: i18n.t("cms.content.choices.CONTENT_CHANNEL.ONE").toString(),
      value: Channel.ONE,
    },
    [Channel.TWO]: {
      text: i18n.t("cms.content.choices.CONTENT_CHANNEL.TWO").toString(),
      value: Channel.TWO,
    },
  }),
);

export enum WebtoonType {
  GENERAL = "GENERAL",
  BRAND = "BRAND",
}
export const WEBTOON_TYPE = Object.freeze(
  new Choices(WebtoonType, {
    [WebtoonType.GENERAL]: {
      text: i18n.t("cms.content.choices.WEBTOON_TYPE.GENERAL").toString(),
      value: WebtoonType.GENERAL,
    },
    [WebtoonType.BRAND]: {
      text: i18n.t("cms.content.choices.WEBTOON_TYPE.BRAND").toString(),
      value: WebtoonType.BRAND,
    },
  }),
);

export enum AuthorType {
  AUTHOR = "AUTHOR",
  ILLUSTRATOR = "ILLUSTRATOR",
  ORIGINAL_STORY = "ORIGINAL_STORY",
  ADAPTATION = "ADAPTATION",
  COLOR = "COLOR",
  PUBLISHER = "PUBLISHER",
}
export const CONTENT_AUTHOR_TYPE = Object.freeze(
  new Choices(AuthorType, {
    [AuthorType.AUTHOR]: {
      text: i18n.t("cms.content.choices.CONTENT_AUTHOR_TYPE.AUTHOR").toString(),
      value: AuthorType.AUTHOR,
    },
    [AuthorType.ILLUSTRATOR]: {
      text: i18n
        .t("cms.content.choices.CONTENT_AUTHOR_TYPE.ILLUSTRATOR")
        .toString(),
      value: AuthorType.ILLUSTRATOR,
    },
    [AuthorType.ORIGINAL_STORY]: {
      text: i18n
        .t("cms.content.choices.CONTENT_AUTHOR_TYPE.ORIGINAL_STORY")
        .toString(),
      value: AuthorType.ORIGINAL_STORY,
    },
    [AuthorType.ADAPTATION]: {
      text: i18n
        .t("cms.content.choices.CONTENT_AUTHOR_TYPE.ADAPTATION")
        .toString(),
      value: AuthorType.ADAPTATION,
    },
    [AuthorType.COLOR]: {
      text: i18n.t("cms.content.choices.CONTENT_AUTHOR_TYPE.COLOR").toString(),
      value: AuthorType.COLOR,
    },
    [AuthorType.PUBLISHER]: {
      text: i18n
        .t("cms.content.choices.CONTENT_AUTHOR_TYPE.PUBLISHER")
        .toString(),
      value: AuthorType.PUBLISHER,
    },
  }),
);

export enum Weekday {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 4,
  THURSDAY = 8,
  FRIDAY = 16,
  SATURDAY = 32,
  SUNDAY = 64,
}
export const WEEKDAY = Object.freeze(
  new Choices(Weekday, {
    [Weekday.MONDAY]: {
      text: i18n.t("cms.content.choices.WEEKDAY.MONDAY").toString(),
      value: Weekday.MONDAY,
    },
    [Weekday.TUESDAY]: {
      text: i18n.t("cms.content.choices.WEEKDAY.TUESDAY").toString(),
      value: Weekday.TUESDAY,
    },
    [Weekday.WEDNESDAY]: {
      text: i18n.t("cms.content.choices.WEEKDAY.WEDNESDAY").toString(),
      value: Weekday.WEDNESDAY,
    },
    [Weekday.THURSDAY]: {
      text: i18n.t("cms.content.choices.WEEKDAY.THURSDAY").toString(),
      value: Weekday.THURSDAY,
    },
    [Weekday.FRIDAY]: {
      text: i18n.t("cms.content.choices.WEEKDAY.FRIDAY").toString(),
      value: Weekday.FRIDAY,
    },
    [Weekday.SATURDAY]: {
      text: i18n.t("cms.content.choices.WEEKDAY.SATURDAY").toString(),
      value: Weekday.SATURDAY,
    },
    [Weekday.SUNDAY]: {
      text: i18n.t("cms.content.choices.WEEKDAY.SUNDAY").toString(),
      value: Weekday.SUNDAY,
    },
  }),
);

export const getWeekdaysText = function (weekdays: number): string {
  return WEEKDAY.getChoices()
    .map((item) => ((weekdays & item.value) > 0 ? item.text : ""))
    .filter(Boolean)
    .join(", ");
};

export enum Status {
  BEING_PUBLISHED = "BEING_PUBLISHED",
  SELLING = "SELLING",
  STOP_SELLING = "STOP_SELLING",
  PROHIBITED = "PROHIBITED",
}
export const CONTENT_STATUS = Object.freeze(
  new Choices(Status, {
    [Status.BEING_PUBLISHED]: {
      text: i18n
        .t("cms.content.choices.CONTENT_STATUS.BEING_PUBLISHED")
        .toString(),
      value: Status.BEING_PUBLISHED,
    },
    [Status.SELLING]: {
      text: i18n.t("cms.content.choices.CONTENT_STATUS.SELLING").toString(),
      value: Status.SELLING,
    },
    [Status.STOP_SELLING]: {
      text: i18n
        .t("cms.content.choices.CONTENT_STATUS.STOP_SELLING")
        .toString(),
      value: Status.STOP_SELLING,
    },
    [Status.PROHIBITED]: {
      text: i18n.t("cms.content.choices.CONTENT_STATUS.PROHIBITED").toString(),
      value: Status.PROHIBITED,
    },
  }),
);

export enum Substatus {
  DRAFT = "DRAFT",
  ABLE_TO_PRE_ORDER = "ABLE_TO_PRE_ORDER",
  PUBLISHABLE = "PUBLISHABLE",
}
export const CONTENT_SUBSTATUS = Object.freeze(
  new Choices(Substatus, {
    [Substatus.DRAFT]: {
      text: i18n.t("cms.content.choices.CONTENT_SUBSTATUS.DRAFT").toString(),
      value: Substatus.DRAFT,
    },
    [Substatus.ABLE_TO_PRE_ORDER]: {
      text: i18n
        .t("cms.content.choices.CONTENT_SUBSTATUS.ABLE_TO_PRE_ORDER")
        .toString(),
      value: Substatus.ABLE_TO_PRE_ORDER,
    },
    [Substatus.PUBLISHABLE]: {
      text: i18n
        .t("cms.content.choices.CONTENT_SUBSTATUS.PUBLISHABLE")
        .toString(),
      value: Substatus.PUBLISHABLE,
    },
  }),
);

export enum OngoingStatus {
  // N_A = "N_A",
  EPISODES_PUBLISHING = "EPISODES_PUBLISHING",
  EPISODES_NOT_PUBLISHING = "EPISODES_NOT_PUBLISHING",
  END_OF_SEASON = "END_OF_SEASON",
  SEASON_COMPLETED = "SEASON_COMPLETED",
  COMPLETED = "COMPLETED",
}
export const ONGOING_STATUS = Object.freeze(
  new Choices(OngoingStatus, {
    // [OngoingStatus.N_A]: {
    //   text: i18n.t("cms.content.choices.ONGOING_STATUS.N_A").toString(),
    //   value: OngoingStatus.N_A,
    // },
    [OngoingStatus.EPISODES_PUBLISHING]: {
      text: i18n
        .t("cms.content.choices.ONGOING_STATUS.EPISODES_PUBLISHING")
        .toString(),
      value: OngoingStatus.EPISODES_PUBLISHING,
    },
    [OngoingStatus.EPISODES_NOT_PUBLISHING]: {
      text: i18n
        .t("cms.content.choices.ONGOING_STATUS.EPISODES_NOT_PUBLISHING")
        .toString(),
      value: OngoingStatus.EPISODES_NOT_PUBLISHING,
    },
    [OngoingStatus.END_OF_SEASON]: {
      text: i18n
        .t("cms.content.choices.ONGOING_STATUS.END_OF_SEASON")
        .toString(),
      value: OngoingStatus.END_OF_SEASON,
    },
    [OngoingStatus.SEASON_COMPLETED]: {
      text: i18n
        .t("cms.content.choices.ONGOING_STATUS.SEASON_COMPLETED")
        .toString(),
      value: OngoingStatus.SEASON_COMPLETED,
    },
    [OngoingStatus.COMPLETED]: {
      text: i18n.t("cms.content.choices.ONGOING_STATUS.COMPLETED").toString(),
      value: OngoingStatus.COMPLETED,
    },
  }),
);

export enum ViewerDefaultView {
  SCROLL = "SCROLL",
  TURNING_PAGE = "TURNING_PAGE",
}
export const VIEWER_DEFAULT_VIEW = Object.freeze(
  new Choices(ViewerDefaultView, {
    [ViewerDefaultView.SCROLL]: {
      text: i18n.t("cms.content.choices.VIEWER_DEFAULT_VIEW.SCROLL").toString(),
      value: ViewerDefaultView.SCROLL,
    },
    [ViewerDefaultView.TURNING_PAGE]: {
      text: i18n
        .t("cms.content.choices.VIEWER_DEFAULT_VIEW.TURNING_PAGE")
        .toString(),
      value: ViewerDefaultView.TURNING_PAGE,
    },
  }),
);

export enum ExternalVideoFrom {
  YOUTUBE = "YOUTUBE",
  KAKAO_TV = "KAKAO_TV",
}

export const EXTERNAL_VIDEO_FROM = Object.freeze(
  new Choices(ExternalVideoFrom, {
    [ExternalVideoFrom.KAKAO_TV]: {
      text: i18n
        .t("cms.content.choices.EXTERNAL_VIDEO_FROM.KAKAO_TV")
        .toString(),
      value: ExternalVideoFrom.KAKAO_TV,
    },
    [ExternalVideoFrom.YOUTUBE]: {
      text: i18n
        .t("cms.content.choices.EXTERNAL_VIDEO_FROM.YOUTUBE")
        .toString(),
      value: ExternalVideoFrom.YOUTUBE,
    },
  }),
);

export enum AnchorClipType {
  ALIVE = "ALIVE",
  LOOP = "LOOP",
}

export const ANCHOR_CLIP_TYPE = Object.freeze(
  new Choices(AnchorClipType, {
    [AnchorClipType.ALIVE]: {
      text: i18n.t("cms.media.choices.ANCHOR_CLIP.ALIVE").toString(),
      value: AnchorClipType.ALIVE,
    },
    [AnchorClipType.LOOP]: {
      text: i18n.t("cms.media.choices.ANCHOR_CLIP.LOOP").toString(),
      value: AnchorClipType.LOOP,
    },
  }),
);

export enum EventRequestType {
  ENCODE_IMAGE = "ENCODE_IMAGE",
  EPSODE = "EPISODE",
}

export enum KakaopageContentState {
  BEING_PUBLISHED = "ST51", // 발행전
  SELLING = "ST61", // 판매중
  STOP_SELLING = "ST62", // 판매중지
}
