import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VMenu,{attrs:{"offset-y":"","left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c(VBtn,_vm._g({staticClass:"ml-0",attrs:{"text":""}},on),[_c('span',{domProps:{"textContent":_vm._s(_setup.currentLocale?.label)}}),_c(VIcon,[_vm._v("mdi-menu-down")])],1)]}}])},[_c(VList,{attrs:{"dense":"","nav":""}},_vm._l((_setup.computedAvailableLocales),function(locale){return _c(VListItem,{key:locale.code,on:{"click":function($event){return _setup.openToolbarLanguageDialog(locale.code)}}},[_c(VListItemTitle,{staticClass:"pl-2",domProps:{"textContent":_vm._s(locale.label)}})],1)}),1)],1),_c(_setup.ToolbarAdminLanguageDialog,{ref:"toolbarAdminLanguageDialog"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }