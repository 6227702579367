export default Object.freeze({
  en: {
    selectDetailForURI: "Select URI details",
    inputExternalURL: "Input External URL",
    Main: "Main tab",
    ContentHome: "Content home",
    ContentHomeNotice: "Content home > Notice",
    ContentHomeTicketList: "Content home > Ticket history",
    ContentHomeTicketRefund: "Content home > Ticket history > Refund",
    ContentHomeTicketPurchase: "Content home > Ticket purchase",
    SpecialBridgePage: "Special bridge page",
    TimeTableCh1: "Timetable > Webtoon original",
    TimeTableCh2: "Timetable > Novel original",
    EpisodeViewer: "Episode viewer",
    NotificationMyNews: "Notification > My news tab",
    NotificationWaitOrFree: "Notification > Wait or pay tab",
    NotificationNotice: "Notification > Notice tab",
    MyPage: "Library",
    Gift: "Gift box",
    BridgePage: "Bridge page",
    EventPage: "Event page",
    GidamooPlus: "Wait for free+ Tab",
    LuckyDraw: "Lucky draw",
    Ranking: "Ranking",
    Search: "Search",
    More: "Side menu",
    MoreMyInfo: "My info",
    MoreMyInfoNickname: "My info > Edit nickname",
    MoreMyInfoWithdraw: "My info > Delete account",
    MoreRegisterCoupon: "Redeem coupon",
    MoreInviteFriends: "Invite friends",
    MoreCSCenter: "Customer service",
    MoreNotice: "Notice",
    MoreFAQ: "Help (FAQ)",
    MoreAddedCashHistory: "Cash added history",
    MoreUsedCashHistory: "Cash used history",
    MoreCashPurchase: "Purchase cash",
    MoreTicketHistory: "Ticket history",
    MoreSetting: "Settings",
    MoreServiceInfo: "Service info",
    MoreServiceInfoPolicy: "Service info > Terms and policies",
    MoreServiceInfoPolicyTerms:
      "Service info > Terms and policies > Terms of use",
    MoreServiceInfoPolicyPaidTerms:
      "Service info > Terms and policies > Payment terms of use",
    MoreServiceInfoPolicyPrivacy:
      "Service info > Terms and policies > Privacy policy",
    MoreServiceInfoPolicyOperationPolicy:
      "Service info > Terms and policies > Operational policy",
    MoreServiceInfoPolicyAdPolicy:
      "Service info > Terms and policies > Customized advertisements and contents guides",
    MoreServiceInfoPolicyYouthPolicy:
      "Service info > Terms and policies > Youth protection policy",
    MoreServiceInfoBusiness: "Service info > Business info",
    MoreServiceInfoInquiry: "Service inquiry",
    NoUrl: "No URL",
    LoginPopup: "Login Popup",
    TimeTable: "Time Table",
    ExternalUrl: "External URL",
  },
  ko: {
    selectDetailForURI: "URI 상세 선택",
    inputExternalURL: "외부 URL을 입력하세요",
    Main: "메인 홈",
    ContentHome: "작품 홈",
    ContentHomeNotice: "작품 홈 > 공지사항",
    ContentHomeTicketList: "작품 홈 > 이용권 내역",
    ContentHomeTicketRefund: "작품 홈 > 이용권 내역 > 환불",
    ContentHomeTicketPurchase: "작품 홈 > 이용권 구매",
    SpecialBridgePage: "스페셜 브릿지 페이지",
    TimeTableCh1: "시간표 > 웹툰 원작",
    TimeTableCh2: "시간표 > 소설 원작",
    EpisodeViewer: "에피소드 뷰어",
    NotificationMyNews: "알림 > 내소식 탭",
    NotificationWaitOrFree: "알림 > 기다무 탭",
    NotificationNotice: "알림 > 공지사항 탭",
    MyPage: "보관함",
    Gift: "선물함",
    BridgePage: "브릿지페이지",
    GidamooPlus: "기다무+ 탭",
    EventPage: "이벤트페이지",
    LuckyDraw: "뽑기권",
    Ranking: "랭킹",
    Search: "검색",
    More: "사이드메뉴",
    MoreMyInfo: "내정보",
    MoreMyInfoNickname: "내정보 > 닉네임 수정",
    MoreMyInfoWithdraw: "내정보 > 서비스 탈퇴",
    MoreRegisterCoupon: "쿠폰등록",
    MoreInviteFriends: "친구초대",
    MoreCSCenter: "고객센터",
    MoreNotice: "공지사항",
    MoreFAQ: "도움말 (FAQ)",
    MoreAddedCashHistory: "캐시 적립 내역",
    MoreUsedCashHistory: "캐시 사용 내역",
    MoreCashPurchase: "캐시 충전",
    MoreTicketHistory: "이용권 내역",
    MoreSetting: "설정",
    MoreServiceInfo: "서비스 정보",
    MoreServiceInfoPolicy: "서비스 정보 > 약관 및 정책",
    MoreServiceInfoPolicyTerms: "서비스 정보 > 약관 및 정책 > 이용약관",
    MoreServiceInfoPolicyPaidTerms:
      "서비스 정보 > 약관 및 정책 > 유료 이용 약관",
    MoreServiceInfoPolicyPrivacy:
      "서비스 정보 > 약관 및 정책 > 개인정보 처리 방침",
    MoreServiceInfoPolicyOperationPolicy:
      "서비스 정보 > 약관 및 정책 > 운영 정책",
    MoreServiceInfoPolicyAdPolicy:
      "서비스 정보 > 약관 및 정책 > 맞춤형 광고 및 컨텐츠 제공 안내",
    MoreServiceInfoPolicyYouthPolicy:
      "서비스 정보 > 약관 및 정책 > 청소년 보호 정책",
    MoreServiceInfoBusiness: "서비스 정보 > 사업자 정보",
    MoreServiceInfoInquiry: "서비스 문의",
    NoUrl: "없음",
    LoginPopup: "로그인 팝업",
    TimeTable: "시간표",
    ExternalUrl: "외부 URL",
  },
});
