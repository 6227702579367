import { ServiceRegion } from "@/const/service/region";
import i18n from "@/plugins/vue-i18n";
import { SEARCH_TYPE, SearchType } from "@/views/member/search/common/choices";
import { Channel, CONTENT_CHANNEL } from "@/views/cms/content/common/choices";
import { AgeChoiceItem } from "@/const/service/common/types";
import { ChoiceItem } from "@/common/types";
import { FilterItem } from "@/components/filter/common/types";
import {
  combinationContentBMFilterItems,
  singleContentBMFilterItems,
} from "@/components/filter/common/items";
import { DataLanguage } from "@/store/modules/data-language";

export class Korea extends ServiceRegion {
  getAgeChoices(): AgeChoiceItem[] {
    return [
      { text: i18n.t("cms.ageLimit.all").toString(), value: 0, color: "green" },
      {
        text: "12",
        value: 12,
        color: "blue",
      },
      {
        text: "15",
        value: 15,
        color: "orange",
      },
      {
        text: i18n.t("cms.ageLimit.adult").toString(),
        value: 19,
        color: "red",
      },
    ];
  }

  getAdultAge(): number {
    return 19;
  }

  getChannelChoices(): { text: string; value: Channel }[] {
    return CONTENT_CHANNEL.getChoices();
  }

  getAvailableSectionList(): string[] {
    return [
      "main_featured",
      "main_featured_last",
      "main_gidamoo",
      "main_special",
      "channel_mon",
      "channel_tue",
      "channel_wed",
      "channel_thu",
      "channel_fri",
      "channel_sat",
      "channel_sun",
      "channel_completed",
      "novel_mon",
      "novel_tue",
      "novel_wed",
      "novel_thu",
      "novel_fri",
      "novel_sat",
      "novel_sun",
      "novel_completed",
      "ad_event_viewer",
      "ad_moment_viewer",
      "ad_content_home",
      "ad_time_table_mon",
      "ad_time_table_tue",
      "ad_time_table_wed",
      "ad_time_table_thu",
      "ad_time_table_fri",
      "ad_time_table_sat",
      "ad_time_table_sun",
      "ad_novel_mon",
      "ad_novel_tue",
      "ad_novel_wed",
      "ad_novel_thu",
      "ad_novel_fri",
      "ad_novel_sat",
      "ad_novel_sun",
      "ad_gift",
      "ad_rank_all",
    ];
  }

  genreRankingEnabled(): boolean {
    return true;
  }

  ch2Enabled(): boolean {
    return true;
  }

  possessionTicketEnabled(): boolean {
    return true;
  }

  getMemberSearchType(): ChoiceItem<string>[] {
    return Object.values(SEARCH_TYPE.getChoices()).filter((choice) =>
      [
        SearchType.USER_ID,
        SearchType.ACCOUNT_ID,
        SearchType.DAUM_ID,
        SearchType.DAUM_USER_ID,
        SearchType.APP_USER_ID,
        SearchType.EMAIL,
      ].includes(choice.value as SearchType),
    );
  }

  getMemberSearchTypeHint(): Partial<Record<SearchType, string>> {
    return {
      [SearchType.USER_ID]: i18n
        .t("member.search.searchTypeHints.USER_ID_HINT")
        .toString(),
      [SearchType.ACCOUNT_ID]: i18n
        .t("member.search.searchTypeHints.ACCOUNT_ID_HINT")
        .toString(),
      [SearchType.DAUM_ID]: i18n
        .t("member.search.searchTypeHints.DAUM_ID_HINT")
        .toString(),
      [SearchType.DAUM_USER_ID]: i18n
        .t("member.search.searchTypeHints.DAUM_USER_ID_HINT")
        .toString(),
      [SearchType.APP_USER_ID]: i18n
        .t("member.search.searchTypeHints.APP_USER_ID_HINT")
        .toString(),
      [SearchType.EMAIL]: i18n
        .t("member.search.searchTypeHints.KOR_EMAIL_HINT")
        .toString(),
    };
  }

  getMemberDataShow(): boolean {
    return true;
  }

  changeNormalBannerTextColorEnabled(): boolean {
    return true;
  }

  adEnabled(): boolean {
    return true;
  }

  webToAppEnabled(): boolean {
    return true;
  }

  bannerEventSpecialCardMaxLength(): number {
    return 3;
  }

  getDataLanguages(): DataLanguage[] {
    return [{ lang: "ko", flag: "kr", label: "한국어" }];
  }

  combinationBMEnabled(): boolean {
    return true;
  }

  sendManuscriptToolEnabled(): boolean {
    return true;
  }

  getContentBMFilterItems(): FilterItem[] {
    return singleContentBMFilterItems().concat(
      combinationContentBMFilterItems(),
    );
  }

  showReleasedForFreeDateTime(): boolean {
    return true;
  }

  showKakaopageEpisodeGet(): boolean {
    return true;
  }

  showKakaopageOriginalNovelContentLink(): boolean {
    return true;
  }

  getRewardCautionInCampaignNoticeModule(): string {
    return "미션 완료 시 지급";
  }

  getEventCautionInCampaignNoticeModule(): string {
    return "기한 내 사용하지 않은 선물은 자동 소멸됩니다. \n본 이벤트는 당사 사정에 따라 사전 예고 없이 변경되거나 취소될 수 있습니다.";
  }

  gidamooPlusEnabled(): boolean {
    return true;
  }
}
