import { ServiceLanguage } from "@/const/service/language";
import { CardLabel } from "@/views/sms/section/common/types/featured";
import { ChoiceItem } from "@/common/types";
import { MaxLength } from "@/const/service/common/types";

export class Korean extends ServiceLanguage {
  getGenreChoices(): ChoiceItem<string>[] {
    return [
      { text: "로맨스", value: "ROMANCE" },
      { text: "로맨스 판타지", value: "ROMANCE_FANTASY" },
      { text: "학원,판타지", value: "SCHOOL_ACTION_FANTASY" },
      { text: "액션,무협", value: "ACTION_WUXIA" },
      { text: "드라마", value: "DRAMA" },
      { text: "판타지 드라마", value: "FANTASY_DRAMA" },
      { text: "공포,스릴러", value: "HORROR_THRILLER" },
      { text: "코믹,일상", value: "COMIC_EVERYDAY_LIFE" },
    ];
  }

  getCardLabels(): CardLabel[] {
    return [
      {
        text: "신작",
        backgroundColor: "#FF3042",
      },
      {
        text: "시즌 종료",
        backgroundColor: "#FF3042",
      },
      {
        text: "레전드",
        backgroundColor: "#FF3042",
      },
      {
        text: "영화 원작",
        backgroundColor: "#FF3042",
      },
      {
        text: "새시즌",
        backgroundColor: "#FF3042",
      },
      {
        text: "시즌 완결",
        backgroundColor: "#FF3042",
      },
      {
        text: "장르 추천",
        backgroundColor: "#FF3042",
      },
      {
        text: "드라마 원작",
        backgroundColor: "#FF3042",
      },
      {
        text: "인기",
        backgroundColor: "#FF3042",
      },
      {
        text: "오늘 완결",
        backgroundColor: "#FF3042",
      },
      {
        text: "완결 추천",
        backgroundColor: "#FF3042",
      },
      {
        text: "영화화",
        backgroundColor: "#FF3042",
      },
      {
        text: "HOT",
        backgroundColor: "#FF3042",
      },
      {
        text: "완결 임박",
        backgroundColor: "#FF3042",
      },
      {
        text: "정주행 추천",
        backgroundColor: "#FF3042",
      },
      {
        text: "드라마화",
        backgroundColor: "#FF3042",
      },
      {
        text: "요즘 핫한",
        backgroundColor: "#FF3042",
      },
      {
        text: "오늘 복귀",
        backgroundColor: "#FF3042",
      },
      {
        text: "역주행 추천",
        backgroundColor: "#FF3042",
      },
      {
        text: "랭킹 UP",
        backgroundColor: "#FF3042",
      },
      {
        text: "입소문",
        backgroundColor: "#FF3042",
      },
      {
        text: "커밍쑨",
        backgroundColor: "#FF3042",
      },
      {
        text: "이용권",
        backgroundColor: "#FF3042",
      },
      {
        text: "랭킹 TOP",
        backgroundColor: "#FF3042",
      },
      {
        text: "무료",
        backgroundColor: "#FF3042",
      },
      {
        text: "랭킹 급상승",
        backgroundColor: "#FF3042",
      },
      {
        text: "이벤트",
        backgroundColor: "#C6A66D",
      },
      {
        text: "월간 웹툰",
        backgroundColor: "#C6A66D",
      },
      {
        text: "특별전",
        backgroundColor: "#C6A66D",
      },
      {
        text: "캐시 드림",
        backgroundColor: "#C6A66D",
      },
      {
        text: "사전 예약",
        backgroundColor: "#C6A66D",
      },
      {
        text: "이용권",
        backgroundColor: "#C6A66D",
      },
      {
        text: "특집",
        backgroundColor: "#C6A66D",
      },
      {
        text: "테마전",
        backgroundColor: "#C6A66D",
      },
      {
        text: "런칭 기념",
        backgroundColor: "#C6A66D",
      },
      {
        text: "단 하루",
        backgroundColor: "#C6A66D",
      },
      {
        text: "기간 한정",
        backgroundColor: "#C6A66D",
      },
      {
        text: "기획",
        backgroundColor: "#C6A66D",
      },
    ];
  }

  getCompletedTabLabels(): CardLabel[] {
    return [
      { text: "레전드", backgroundColor: "#FF3042" },
      { text: "PD추천", backgroundColor: "#FF3042" },
      { text: "영화 예고편", backgroundColor: "#FF3042" },
      { text: "추천", backgroundColor: "#FF3042" },
      { text: "매거진", backgroundColor: "#FF3042" },
      { text: "대여권", backgroundColor: "#FF3042" },
      { text: "캐시백", backgroundColor: "#FF3042" },
    ];
  }

  getContentCatchphraseMaxLength(): MaxLength {
    return {
      threeLines: 12,
      twoLines: 20,
    };
  }

  getIpPromotionVideoTitleMaxLength(): number {
    return 12;
  }

  getIpPromotionVideoSubtitleMaxLength(): number {
    return 19;
  }

  getSpecialMatchingTitleMaxLength(): number {
    return 15;
  }

  getCustomLabelMaxLength(): number {
    return 8;
  }

  getCampaignPageTitleMaxLength(): number {
    return 0;
  }
  getCampaignPageModuleTitleMaxLength(): number {
    return 0;
  }
  getCampaignPageModuleTitleRowLength(): number {
    return 1;
  }
  getCampaignPageModuleSubTitleMaxLength(): number {
    return 0;
  }
  getCampaignPageModuleSubTitleRowLength(): number {
    return 2;
  }
}
