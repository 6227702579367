import { Choices } from "@/utils/choices";
import i18n from "@/plugins/vue-i18n";

export enum SearchType {
  USER_ID = "user_id",
  ACCOUNT_ID = "account_id",
  DAUM_USER_ID = "daum_user_id",
  APP_USER_ID = "app_user_id",
  EMAIL = "email",
  PHONE_NUMBER = "phone_number",
  NICKNAME = "user_nick",
  CS_ID = "cs_id",
  DAUM_ID = "daum_id",
}
export const SEARCH_TYPE = Object.freeze(
  new Choices(SearchType, {
    [SearchType.USER_ID]: {
      text: i18n.t("member.search.choices.USER_ID").toString(),
      value: SearchType.USER_ID,
    },
    [SearchType.ACCOUNT_ID]: {
      text: i18n.t("member.search.choices.ACCOUNT_ID").toString(),
      value: SearchType.ACCOUNT_ID,
    },
    [SearchType.DAUM_ID]: {
      text: i18n.t("member.search.choices.DAUM_ID").toString(),
      value: SearchType.DAUM_ID,
    },
    [SearchType.DAUM_USER_ID]: {
      text: i18n.t("member.search.choices.DAUM_USER_ID").toString(),
      value: SearchType.DAUM_USER_ID,
    },
    [SearchType.APP_USER_ID]: {
      text: i18n.t("member.search.choices.APP_USER_ID").toString(),
      value: SearchType.APP_USER_ID,
    },
    [SearchType.EMAIL]: {
      text: i18n.t("member.search.choices.EMAIL").toString(),
      value: SearchType.EMAIL,
    },
    [SearchType.PHONE_NUMBER]: {
      text: i18n.t("member.search.choices.PHONE_NUMBER").toString(),
      value: SearchType.PHONE_NUMBER,
    },
    [SearchType.NICKNAME]: {
      text: i18n.t("member.search.choices.NICKNAME").toString(),
      value: SearchType.NICKNAME,
    },
    [SearchType.CS_ID]: {
      text: i18n.t("member.search.choices.CS_ID").toString(),
      value: SearchType.CS_ID,
    },
  }),
);

export enum TabType {
  BLOCKING_HISTORY = "BLOCKING_HISTORY",
  TICKET_PURCHASE = "TICKET_PURCHASE",
  LOGIN_DEVICE = "LOGIN_DEVICE",
}
export const TAB_TYPE = Object.freeze(
  new Choices(TabType, {
    [TabType.BLOCKING_HISTORY]: {
      text: i18n.t("member.search.choices.BLOCKING_HISTORY").toString(),
      value: TabType.BLOCKING_HISTORY,
    },
    [TabType.TICKET_PURCHASE]: {
      text: i18n.t("member.search.choices.TICKET_PURCHASE").toString(),
      value: TabType.TICKET_PURCHASE,
    },
    [TabType.LOGIN_DEVICE]: {
      text: i18n.t("member.search.choices.LOGIN_DEVICE").toString(),
      value: TabType.LOGIN_DEVICE,
    },
  }),
);

export enum TicketPurchaseMethod {
  rental = "rental",
  possession = "possession",
  welcome_gift = "welcome_gift",
  wait_for_free = "wait_for_free",
  gift = "gift",
}
export const TICKET_PURCHASE_METHOD = Object.freeze(
  new Choices(TicketPurchaseMethod, {
    [TicketPurchaseMethod.rental]: {
      text: i18n.t("member.search.choices.cash").toString(),
      value: TicketPurchaseMethod.rental,
    },
    [TicketPurchaseMethod.possession]: {
      text: i18n.t("member.search.choices.cash").toString(),
      value: TicketPurchaseMethod.possession,
    },
    [TicketPurchaseMethod.welcome_gift]: {
      text: i18n.t("member.search.choices.welcomeGift").toString(),
      value: TicketPurchaseMethod.welcome_gift,
    },
    [TicketPurchaseMethod.wait_for_free]: {
      text: i18n.t("member.search.choices.waitForFree").toString(),
      value: TicketPurchaseMethod.wait_for_free,
    },
    [TicketPurchaseMethod.gift]: {
      text: i18n.t("member.search.choices.gift").toString(),
      value: TicketPurchaseMethod.gift,
    },
  }),
);

export enum TicketType {
  rental = "rental",
  possession = "possession",
}
export const TICKET_TYPE = Object.freeze(
  new Choices(TicketType, {
    [TicketType.rental]: {
      text: i18n.t("member.search.choices.rental").toString(),
      value: TicketType.rental,
    },
    [TicketType.possession]: {
      text: i18n.t("member.search.choices.possession").toString(),
      value: TicketType.possession,
    },
  }),
);

export enum PackageType {
  package = "package",
  single = "single",
}
export const PACKAGE_TYPE = Object.freeze(
  new Choices(PackageType, {
    [PackageType.package]: {
      text: i18n.t("member.search.choices.package").toString(),
      value: PackageType.package,
    },
    [PackageType.single]: {
      text: i18n.t("member.search.choices.single").toString(),
      value: PackageType.single,
    },
  }),
);
