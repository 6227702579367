import Vue from "vue";
import VueI18n from "vue-i18n";
import cms from "@/const/translations/cms";
import sms from "@/const/translations/sms";
import msg from "@/const/translations/msg";
import menu from "@/const/translations/menu";
import rule from "@/const/translations/rule";
import vuetify from "@/const/translations/vuetify";
import filter from "@/const/translations/filter";
import reward from "@/const/translations/reward";
import crm from "@/const/translations/crm";
import cs from "@/const/translations/cs";
import uri from "@/const/translations/uri";
import member from "@/const/translations/member";
import comment from "@/const/translations/comment";
import store from "@/store";
import { AdminLanguage } from "@/common/types";
import auth from "@/const/translations/auth";
import appVersion from "@/const/translations/app-version";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: store.getters.adminLang,
  fallbackLocale: "ko",
  messages: {
    en: {
      cms: cms.en,
      sms: sms.en,
      reward: reward.en,
      crm: crm.en,
      cs: cs.en,
      comment: comment.en,
      auth: auth.en,
      msg: msg.en,
      menu: menu.en,
      rule: rule.en,
      filter: filter.en,
      uri: uri.en,
      member: member.en,
      appVersion: appVersion.en,
      $vuetify: vuetify.en,
    },
    ko: {
      cms: cms.ko,
      sms: sms.ko,
      reward: reward.ko,
      crm: crm.ko,
      cs: cs.ko,
      comment: comment.ko,
      auth: auth.ko,
      msg: msg.ko,
      menu: menu.ko,
      rule: rule.ko,
      filter: filter.ko,
      uri: uri.ko,
      member: member.ko,
      appVersion: appVersion.ko,
      $vuetify: vuetify.ko,
    },
  },
});

export default i18n;

export const availableLocales: AdminLanguage[] = [
  {
    code: "en",
    flag: "us",
    label: "English",
  },
  {
    code: "ko",
    flag: "kr",
    label: "한국어",
  },
];
