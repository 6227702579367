export default Object.freeze({
  en: {
    guide: {
      displayForAdmin:
        "Entered information will not be displayed to the users (for internal reference only).",
    },
    validation: {
      requiredContentStatusSelling: "Only contents on-sale can be selected.",
      requiredContentBM: "Only contents with BM entered can be selected.",
      requiredSchedule: "Schedule is required.",
      requiredReservationContent:
        "You can only set the status of [Available for pre-sale] or [Available for sale] or [On Sale].",
    },
    target: {
      targets: "Target",
      targetUser: "Target user",
      formTitle: "Target user settings",
      applyFilter: "Apply filter",
      newUserLabel: "Satisfactory conditions for new account status",
      requiredFileUrl: "Select csv file.",
      alertTargetAll:
        "Please contact the production team before transferring rewards to all users.",
      targetChecking: "Target user verification... {value} people",
      incorrectTargetCsvFile: "Target File(.csv) is incorrect.",
      incorrectTargetDuplicated:
        "Duplicate user ID found. Please check and upload it again.",
      duplicatedUsers: "Duplicate user",
      choices: {
        TARGET_TYPE: {
          ALL: "All users",
          SELECTED: "Selected users",
          NEW: "Newbie",
          OLD: "Existing",
          NO_LOGIN_USER: "Non-logged in user",
          LOGIN_USER: "Logged in user",
          RETURN: "Return",
        },
        TARGET_FILTER_TYPE: {
          TODAY_READ: "Exclude users who accessed content today",
          BLOCK_FLAG: "Exclude blocked users",
          BLACK_LIST: "Exclude blacklisted users",
        },
      },
    },
    push: {
      push: "PUSH",
      formTitle: "PUSH message settings",
      firstMessage: "Title",
      secondMessage: "Content",
    },
    ticker: {
      ticker: "Ticker",
      formTitle: "Ticker settings",
      firstMessage: "Title",
      secondMessage: "Content",
      buttonText: "Button text",
      buttonTextHint:
        "In case of blank, the default text of 'Confirm' will be exposed.",
      availableDateTimeHint:
        "Ticker will be displayed in the order of approaching end date.",
    },
    notification: {
      notification: "Notifications",
      formTitle: "Notifications settings",
      firstMessage: "First line",
      firstMessageHint:
        "Enter first line of the message that will be displayed on user's notifications.",
      secondMessage: "Second line",
      secondMessageHint:
        "Enter second line of the message that will be displayed on user's notifications.",
      emptyLandingUrlHint:
        "*When saved as blank, it is set to No News Landing.",
    },
    message: {
      listPageTitle: "Message Promotion List",
      listPageTitleHint:
        "Transfer reward, PUSH notifications, ticker to target users.",
      messageType: "Message type",
      rewardInfo: "Reward info",
      transferTarget: "Transfer recipient(s)",
      transferDate: "Transfer time",
      transferAutoDate: "Scheduled time of transfer",
      transferManualDate: "Available time for transfer",
      transferMode: "Auto / manual transfer",
      transferManagement: "Transfer management",
      queueListTitle: "Awaiting transfer ({count})",
      transferredListTitle: "Transferred ({count})",
      transferredDate: "Transferred time",
      inflow: "Inflow",
      inflowPercentage: "Inflow rate",
      newPageTitle: "Create message promotion",
      editPageTitle: "Edit message promotion",
      viewPageTitle: "Completed message promotion",
      transferPageTitle: "Transfer message promotion",
      testTransfer: "Test transfer",
      transfer: "Transfer",
      startTransfer: "Transfer",
      sendAutomatically: "Automatic transfer recipient(s)",
      titleHint:
        "Entered information will be displayed on user's Transaction history.",
      transferDateTimeHint:
        "Please schedule a time to transfer my info, PUSH, reward message.",
      transferredSuccess: "Transferred",
      transferredFailure: "Transfer failed",
      transferResultDateTime: "Last recorded date & time",
      choices: {
        MESSAGE_TRANSFER_MODE_TYPE: {
          AUTO: "Automatic",
          MANUAL: "Manual",
        },
      },
      tester: {
        testerManagement: "Tester management",
        testerManagementHint:
          "Please register user_id you wish to conduct a test.\n" +
          "When conducting a test, message will be transferred to all regestered user_id.",
        management: "Manage",
        addUserId: "Add user ID",
      },
    },
    redeem: {
      listPageTitle: "Redeem code list",
      listPageTitleHint: "Create redeem code and manage groups.",
      redeemType: "Campaign type",
      availablePeriod: "Participation period",
      codeGroupCount: "Number of redeem code groups",
      registered: "Number of codes redeemed",
      totalProvided: "Total rewards provided",
      downloadCodeList: "Download redeem code list",
      newPageTitle: "Create redeem campaign",
      editPageTitle: "Edit redeem campaign",
      viewPageTitle: "View redeem capaign",
      rewardType: "Reward type",
      titleHint:
        "Entered information will be displayed on user's Cash / ticket history.",
      redeemType11Hint:
        "Redeem code will be created according to the set number.",
      redeemType1NHint: "Created alias can be used by multiple users.",
      redeemTypeV2: "Redeem type",
      duplicatableFlag: "Multiple code registration by account",
      differentialPayment: "Differential payment",
      validSameTargetUser: "The same target user cannot be selected.",
      choices: {
        REDEEM_TYPE: {
          _11: "1:1",
          _1N: "1:N",
        },
        REDEEM_STATUS_TYPE: {
          EXPECTED: "Scheduled",
          AVAILABLE: "Available",
          NOT_AVAILABLE: "Expired",
        },
        TARGET_TYPE: {
          ALL_USER: "All users",
          NEW_USER: "Newbie user",
          COMEBACK_USER: "Comeback user",
        },
        GENERATE_STATUS: {
          READY: "Ready",
          IN_PROGRESS: "In progress",
          COMPLETE: "Complete",
        },
      },
      group: {
        listPageTitle: "Redeem code group list",
        newPageTitle: "Create redeem code group",
        editPageTitle: "Edit redeem code group",
        viewPageTitle: "View redeem code group",
        campaignInfo: "Campaign info",
        groupInfo: "Group info",
        numberOfCode: "Number of code(s)",
        codeAlias: "Code Alias",
        codeAliasMessage:
          "When you set an alias, redeem code will be displayed as an alias to users.",
        payableRewards: "Code redemption limit",
        unlimited: "Unlimited",
        limited: "Limited",
      },
    },
    ticket: {
      discount: {
        listPageTitle: "Ticket discount list",
        listPageTitleHint:
          "Create and manage ticket discount campaigns for specific content.",
        newPageTitle: "Create ticket purchase campaign",
        editPageTitle: "Edit ticket purchase campaign",
        viewPageTitle: "View ticket purchase campaign",
        alreadyExistContent: "Content with the same settings already exists.",
        choices: {
          TICKET_DISCOUNT_STATUS_TYPE: {
            READY: "Ready",
            IN_PROGRESS: "Available",
            FINISHED: "Unavailable",
          },
        },
        table: {
          formTitle: "Ticket discount settings",
          defaultAreaTitle: "Existing BM",
          discountAreaTitle: "Ticket discount settings",
          possession: "Possession ticket",
          singlePurchase: "Individual purchase",
          packagePurchases: "Promotional purchase",
          pricePerEpisode: "Price per episode",
          basePackage: "Base number of tickets",
          bonusPackage: "Number of bonus tickets",
          rental: "Rental ticket",
        },
      },
    },
    giftbox: {
      giftbox: "Gift box",
      ticket: {
        listPageTitle: "Gift box ticket list",
        listPageTitleHint:
          "Create and manage tickets displayed on user's Gift box.",
        newPageTitle: "New gift box ticket",
        editPageTitle: "Edit gift box ticket",
        viewPageTitle: "View gift box ticket",
        changeSort: "Change order",
        history: {
          history: "History",
          listPageTitle: "Gift box ticket history",
        },
      },
    },
    segment: {
      ticker: {
        segment: "Segment",
        listPageTitle: "Segment ticker list",
        listPageTitleHint: "Create and manage ticker displayed on main home.",
        newPageTitle: "Create segment ticker",
        editPageTitle: "Edit segment ticker",
        viewPageTitle: "View segment ticker",
      },
    },
    quest: {
      listPageTitle: "Quest campaign list",
      listPageTitleHint:
        "Encourage users to perform certain actions, and reward the users who have completed them.",
      newPageTitle: "Create quest campaign",
      editPageTitle: "Edit quest campaign",
      viewPageTitle: "View quest campaign",
      campaignType: "Campaign type",
      attendanceRule: "Satisfactory conditions for participation",
      numberOfVisit: "Number of visits eligible for rewards",
      readingGoal: "Reading goal",
      readingPaidTicketGoal: "Reading goal with the paid ticket",
      readingGoalPrefix: "Read",
      readingFreeGoalSuffix: "Number of episodes",
      contentReadingFreeHint:
        "Please enter the number of episodes the user needs to read.",
      readingPaidTicketGoalSuffix: "Number of paid episodes",
      readingPaidTicketGoalHint:
        "Select number of episodes that user has to read with paid tickets",
      questCampaign: "Quest campaign",
      eventPage: "Event page",
      giftbox: "Gift box",
      mission: "{type} mission #{index} (#ID: {id})",
      mainReward: "Main Reward",
      alreadyExistEpisode: "Episode already exists.",
      displayTitleForUserApp:
        "Entered title will be displayed on user's Cash / Ticket history.",
      numberOfVisitHint: "Set number of visits eligible for rewards.",
      episodeReadingContentHint: "Select content that user has to read.",
      episodeReadingEpisodeHint: "Select episode that user has to read.",
      setActiveFlagFalse:
        "It may be disabled on save if the target file or schedule changes.",
      targetUploading: "Uploading targets",
      requiredNumberOfVisitAttendanceMission:
        "Set minimum attendance of three.",
      quizTitle: "Quiz Title",
      quizAnswer: "Answer",
      quizAnswerHint:
        "*Please note the spelling. Answers are not case sensitive.",
      hintFlag: "Hint Setting",
      hint: "Hint",
      buttonLandingFlag: "Button Landing Setting",
      buttonLandingUrl: "Button Landing Url",
      allContents: "For all contents",
      guestTarget: "Quest Target",
      INVITATION_HOST_1: "(Host)1 person invitation type",
      INVITATION_HOST_N: "(Host)N person invitation type",
      INVITATION_GUEST: "(Guest)invitation type",
      maxFlag: "Restrictions on invitation personnel",
      maxPerson: "Maximum number of invitations",
      goalPerson: "Invitation personnel",
      requiredInvitationHostMission:
        "One of the 'one host' and 'Host N invitations' must be activated.",
      choices: {
        QUEST_CAMPAIGN_TYPE: {
          CONTENT_READING: "Read content",
          EPISODE_READING: "Read episode",
          CONTENT_RESERVATION: "Advance reservation",
          PARTICIPANTS_ACQUIRE: "Participant recruiting",
          ATTENDANCE: "Attendance",
          JOIN: "Join",
          INVITATION: "Invite friends(Default)",
          QUIZ: "Quiz",
          NO_MISSION: "No mission",
          VOTE: "Voting",
          FIRST_READING: "First reading",
        },
        MISSION_ATTENDANCE_RULE_TYPE: {
          CLICK: "Click button to participate",
          READING: "Read to participate",
        },
      },
      page: {
        url: "Event Page URL",
        backgroundColor: "Background Hex Color Code",
        backgroundImage: "Background image",
        thumbnailImageUrl: "Thumbnail image",
        thumbnailVideoUrl: "Thumbnail video",
        copyright: "Copyright",
        beforeInactiveFlag: "Gift box must be disabled to disable.",
        copyToCampaignPage: "Automatic event page operation",
        module: {
          reward: {
            reward: "Reward",
            line1: "First line",
            line2: "Second line",
          },
          recommend: {
            collectionId: "Recommendations collection",
          },
          video: {
            embedUrl: "Embed URL",
            streamingFlag: "Streaming Video",
          },
          button: {
            buttonTitle: "Button title",
          },
          notice: {
            reward: "Reward payment notice",
            detail: "Note",
          },
          union: {
            addVideo: "Add Video",
            addImage: "Add Image",
            addButton: "Add Button",
            atLeastOneModule:
              "Union modules must have at least one (video/image/button) type.",
          },
        },
      },
      giftboxItem: {
        beforeActiveFlag: "Event page must be enabled to enable.",
        titleImage: "Title image",
        beforeEnter: "Event page must be saved first to set a Gift Box.",
        beforeEnterForNoMission:
          "Reward module of event page must be saved first to set a Gift Box.",
        rewardItemType: "Reward type",
        rewardItemText: "Reward text",
        copyToGiftbox: "Automatic Gift Box operation",
        choices: {
          GIT_BOX_REWARD_ITEM_TYPE: {
            CASH: "CASH",
            TICKET: "TICKET",
            LUCKY_DRAW: "LUCKY_DRAW",
            ETC: "ETC",
          },
        },
      },
    },
    promotion: {
      gift: {
        listPageTitle: "Gift promotion list",
        listPageTitleHint: "Create and manage 'gift promotion event page'.",
      },
    },
    module: {
      choices: {
        MODULE_TYPE: {
          REWARD: "Reward module",
          MAIN: "Main module",
          UNION: "Union module",
          IMAGE: "Image module",
          PUSH_ON: "PUSH ON button module",
          NIGHT_PUSH_ON: "Nighttime PUSH ON button module",
          SHARE: "Share module",
          COMMENT: "Comment module",
          VIDEO: "Video module",
          BUTTON: "Button module",
          RECOMMEND: "Recommendations module",
          NOTICE: "Notice module",
        },
        MODULE_VIDEO_TYPE: {
          KAKAO_TV: "KAKAO TV",
          YOUTUBE: "YOUTUBE",
          AFREECA_TV: "Afreeca TV",
        },
        MODULE_RECOMMEND_TYPE: {
          COLLECTION: "Choose Collection",
          CONTENT: "Choose Contents",
        },
      },
    },
  },
  ko: {
    guide: {
      displayForAdmin:
        "사용자의 화면에선 노출되지 않는 이름입니다.(내부 참고용)",
    },
    validation: {
      requiredContentStatusSelling: "판매 중 상태인 작품만 선택 가능합니다.",
      requiredContentBM: "BM 설정 완료한 작품만 선택 가능합니다.",
      requiredSchedule: "스케쥴 입력은 필수입니다.",
      requiredReservationContent:
        "[발행 전 - 발행 가능][발행 전 - 사전예약] 또는 [판매 중] 상태인 작품만 사전예약 대상 작품으로 설정 가능합니다.",
    },
    target: {
      targets: "타겟",
      targetUser: "타겟 유저",
      formTitle: "타겟 유저 설정",
      applyFilter: "필터 적용",
      newUserLabel: "신규 가입 기준",
      requiredFileUrl: "csv 파일을 선택해주세요.",
      alertTargetAll:
        "전체 유저 대상 리워드 발송 시, 사용 전 제작팀에게 문의주세요.",
      targetChecking: "타겟 유저 검증 중... {value} 명",
      incorrectTargetCsvFile: "올바르지 않은 타겟 CSV 파일",
      incorrectTargetDuplicated:
        "중복된 유저 아이디가 있습니다. 확인 후 다시 업로드해주세요.",
      duplicatedUsers: "중복된 유저",
      choices: {
        TARGET_TYPE: {
          ALL: "전체 유저",
          SELECTED: "선택 유저",
          NEW: "신규 유저",
          OLD: "기존 유저",
          NO_LOGIN_USER: "미로그인 유저",
          LOGIN_USER: "로그인 유저",
          RETURN: "복귀 유저",
          NEW_AND_RETURN: "신규 + 복귀 유저",
        },
        TARGET_FILTER_TYPE: {
          TODAY_READ: "오늘 열람 유저 제외",
          BLOCK_FLAG: "차단 유저 제외",
          BLACK_LIST: "블랙 리스트 제외",
        },
      },
    },
    push: {
      push: "푸시",
      formTitle: "푸시 메시지 설정",
      firstMessage: "제목",
      secondMessage: "내용",
    },
    ticker: {
      ticker: "티커",
      formTitle: "티커 설정",
      firstMessage: "제목",
      secondMessage: "내용",
      buttonText: "버튼 텍스트",
      buttonTextHint:
        "별도 문구를 입력하지 않는 경우, '확인하기' 디폴트 문구가 노출됩니다.",
      availableDateTimeHint: "티커는 종료 임박 순으로 노출 됩니다.",
    },
    notification: {
      notification: "알림",
      formTitle: "알림 설정",
      firstMessage: "첫번째 줄",
      firstMessageHint: "알림함에 노출될 메시지의 첫번째 줄 내용을 입력하세요.",
      secondMessage: "두번째 줄",
      secondMessageHint:
        "알림함에 노출될 메시지의 두번째 줄 내용을 입력하세요.",
      emptyLandingUrlHint: "*공란으로 저장 시 내소식 랜딩 없음으로 설정됩니다.",
    },
    message: {
      listPageTitle: "메세지 프로모션 목록",
      listPageTitleHint: "메시지 프로모션을 생성 및 관리합니다.",
      messageType: "발송 타입",
      rewardInfo: "리워드 정보",
      transferTarget: "발송 대상",
      transferDate: "발송 시간",
      transferAutoDate: "발송 예약 시간",
      transferManualDate: "발송 가능 시간",
      transferMode: "자동/수동 발송",
      transferManagement: "발송 관리",
      queueListTitle: "발송 대기 ({count})",
      transferredListTitle: "발송 완료 ({count})",
      transferredDate: "발송된 시간",
      inflow: "유입",
      inflowPercentage: "유입률",
      newPageTitle: "메시지 프로모션 생성",
      editPageTitle: "메시지 프로모션 수정",
      viewPageTitle: "완료된 메시지 프로모션",
      transferPageTitle: "메시지 프로모션 발송",
      testTransfer: "테스트 발송",
      transfer: "발송",
      startTransfer: "발송 시작",
      sendAutomatically: "자동 발송 대상",
      titleHint: "입력한 제목은 사용자의 캐시 내역에 노출됩니다.",
      transferDateTimeHint:
        "내소식, 푸시, 리워드 메시지를 발송할 시간을 설정해 주세요.",
      transferredSuccess: "전송됨",
      transferredFailure: "전송되지 않음",
      transferResultDateTime: "마지막 집계 시간",
      choices: {
        MESSAGE_TRANSFER_MODE_TYPE: {
          AUTO: "자동",
          MANUAL: "수동",
        },
      },
      tester: {
        testerManagement: "테스터 관리",
        testerManagementHint:
          "테스트를 진행하고자 하는 user_id를 등록해주세요.\n" +
          "테스트 진행 시, 리스트에 등록된 모든 user_id에게 메시지가 발송됩니다.",
        management: "관리",
        addUserId: "유저 아이디 등록",
      },
    },
    redeem: {
      listPageTitle: "리딤코드 목록",
      listPageTitleHint: "리딤코드 생성 및 그룹을 관리합니다.",
      redeemType: "캠페인 타입",
      availablePeriod: "참여 가능 기간",
      codeGroupCount: "리딤코드 그룹 수",
      registered: "등록된 리딤코드 수",
      totalProvided: "총 지급 보상",
      downloadCodeList: "리딤코드 목록 다운로드",
      newPageTitle: "새로운 리딤 캠페인 생성",
      editPageTitle: "리딤 캠페인 수정",
      viewPageTitle: "리딤 캠페인 보기",
      rewardType: "리워드 종류",
      titleHint: "입력한 제목은 사용자의 캐시/이용권 내역에 노출 됩니다.",
      redeemType11Hint: "설정한 개수만큼 리딤코드가 생성됩니다.",
      redeemType1NHint: "생성한 별칭을 다수의 유저가 사용할 수 있습니다.",
      redeemTypeV2: "리딤 타입",
      duplicatableFlag: "계정 별 복수 코드 등록",
      differentialPayment: "차등 지급",
      validSameTargetUser: "동일한 타겟 유저를 선택할 수 없습니다.",
      choices: {
        REDEEM_TYPE: {
          _11: "1:1",
          _1N: "1:N",
        },
        REDEEM_STATUS_TYPE: {
          EXPECTED: "예정",
          AVAILABLE: "사용 가능",
          NOT_AVAILABLE: "만료",
        },
        TARGET_TYPE: {
          ALL_USER: "전체 유저",
          NEW_USER: "신규 유저",
          COMEBACK_USER: "이탈 유저",
        },
        GENERATE_STATUS: {
          READY: "시작전",
          IN_PROGRESS: "생성중",
          COMPLETE: "생성완료",
        },
      },
      group: {
        listPageTitle: "리딤코드 그룹 목록",
        newPageTitle: "새로운 리딤코드 그룹 생성",
        editPageTitle: "리딤코드 그룹 수정",
        viewPageTitle: "리딤코드 그룹 보기",
        campaignInfo: "캠페인 정보",
        groupInfo: "그룹 정보",
        numberOfCode: "코드 개수",
        codeAlias: "별칭 설정",
        codeAliasMessage:
          "별칭 설정 시, 유저에게 별칭으로 리딤코드가 노출됩니다.",
        payableRewards: "리딤 코드 등록 가능 횟수",
        unlimited: "무제한",
        limited: "제한",
      },
    },
    ticket: {
      discount: {
        listPageTitle: "이용권 구매 할인 목록",
        listPageTitleHint:
          "특정 작품에 대한 이용권 구매 할인 캠페인을 생성 및 관리합니다.",
        newPageTitle: "새로운 이용권 구매 캠페인 생성",
        editPageTitle: "이용권 구매 캠페인 수정",
        viewPageTitle: "이용권 구매 캠페인 보기",
        alreadyExistContent: "동일 작품 설정이 이미 존재합니다.",
        choices: {
          TICKET_DISCOUNT_STATUS_TYPE: {
            READY: "준비 완료",
            IN_PROGRESS: "집행 중",
            FINISHED: "집행 종료",
          },
        },
        table: {
          formTitle: "이용권 할인 설정",
          defaultAreaTitle: "디폴트",
          discountAreaTitle: "이용권 할인 설정",
          possession: "소장권",
          singlePurchase: "개별 구매",
          packagePurchases: "패키지 구매",
          pricePerEpisode: "회차 당 가격",
          basePackage: "기준 이용권 개수",
          bonusPackage: "추가 제공 이용권 개수",
          rental: "대여권",
        },
      },
    },
    giftbox: {
      giftbox: "선물함",
      ticket: {
        listPageTitle: "이용권 선물함 목록",
        listPageTitleHint:
          "사용자 선물함에 노출할 이용권을 생성 및 관리합니다.",
        newPageTitle: "새로운 이용권 선물함",
        editPageTitle: "이용권 선물함 수정",
        viewPageTitle: "이용권 선물함 보기",
        changeSort: "순서변경",
        history: {
          history: "이력",
          listPageTitle: "이용권 선물함 이력",
        },
      },
    },
    segment: {
      ticker: {
        segment: "세그먼트",
        listPageTitle: "세그먼트 티커 목록",
        listPageTitleHint: "메인홈에 노출될 티커를 생성하고 관리합니다.",
        newPageTitle: "새로운 세그먼트 티커 생성",
        editPageTitle: "세그먼트 티커 수정",
        viewPageTitle: "세그먼트 티커 보기",
      },
    },
    quest: {
      listPageTitle: "퀘스트 캠페인 목록",
      listPageTitleHint: "캠페인 및 이벤트 페이지를 생성하고 관리합니다.",
      newPageTitle: "새로운 퀘스트 캠페인 생성",
      editPageTitle: "퀘스트 캠페인 수정",
      viewPageTitle: "퀘스트 캠페인 보기",
      campaignType: "캠페인 타입",
      attendanceRule: "참여 완료 조건",
      numberOfVisit: "리워드 지급 회차",
      readingGoal: "열람 목표",
      readingPaidTicketGoal: "유료 이용권 열람 목표",
      readingGoalPrefix: "열람",
      readingFreeGoalSuffix: "열람 회차 수",
      contentReadingFreeHint:
        "유저가 달성해야 하는 열람 회차 수를 선택해주세요",
      readingPaidTicketGoalSuffix: "유료 이용권 열람 유료 회차",
      readingPaidTicketGoalHint:
        "유저가 달성해야 하는 유료 이용권 열람 회차 수를 선택해 주세요.(무료, 이용권 선물, 기다리면 무료 이용권 제외)",
      questCampaign: "퀘스트 캠페인",
      eventPage: "이벤트 페이지",
      giftbox: "선물함",
      mission: "{type} 미션 #{index} (#ID: {id})",
      mainReward: "Main Reward",
      alreadyExistEpisode: "동일 회차가 이미 존재합니다.",
      displayTitleForUserApp:
        "리워드 획득 시 캐시/티켓 내역에 입력한 제목이 사용자에게 노출 됩니다.",
      numberOfVisitHint: "리워드를 지급할 출석 회차를 설정하세요.",
      episodeReadingContentHint: "유저가 열람해야 하는 작품을 선택해 주세요.",
      episodeReadingEpisodeHint: "유저가 열람해야 하는 회차를 선택해 주세요.",
      setActiveFlagFalse:
        "타겟 파일 또는 스케줄이 변경되면 저장 시 비활성화 처리될 수 있습니다.",
      targetUploading: "타겟 등록중",
      requiredNumberOfVisitAttendanceMission:
        "최소 3회차 이상 설정해야 합니다.",
      quizTitle: "퀴즈 제목",
      quizAnswer: "답변",
      quizAnswerHint:
        "*맞춤법에 유의해주세요. 정답은 대소문자를 구분하지 않습니다.",
      hintFlag: "힌트 설정",
      hint: "힌트",
      buttonLandingFlag: "버튼 랜딩 설정",
      buttonLandingUrl: "버튼 랜딩 Url",
      allContents: "전체 작품 대상",
      candidateIndex: "투표 항목 {index}",
      candidateValue: "항목 명칭",
      candidateImage: "항목 이미지 등록",
      guestTarget: "게스트 타겟",
      INVITATION_HOST_1: "1명 초대 유형",
      INVITATION_HOST_N: "N명 초대 유형",
      INVITATION_GUEST: "게스트 유형",
      maxFlag: "초대인원 제한 설정",
      maxPerson: "최대 초대 가능 인원 수",
      goalPerson: "초대 인원",
      requiredInvitationHostMission:
        "'호스트 1명 초대', '호스트 N명 초대' 중 하나는 활성화되어야 합니다.",
      choices: {
        QUEST_CAMPAIGN_TYPE: {
          CONTENT_READING: "작품 열람",
          EPISODE_READING: "회차 열람",
          CONTENT_RESERVATION: "사전 예약",
          PARTICIPANTS_ACQUIRE: "참여자 모집",
          ATTENDANCE: "출석 체크",
          JOIN: "가입",
          INVITATION: "친구초대(기본형)",
          QUIZ: "퀴즈",
          NO_MISSION: "노미션",
          VOTE: "투표",
          FIRST_READING: "첫열람",
        },
        MISSION_ATTENDANCE_RULE_TYPE: {
          CLICK: "출석 버튼 클릭 시 참여 완료",
          READING: "열람 시 참여 완료",
        },
      },
      page: {
        url: "이벤트 페이지 URL",
        backgroundColor: "배경 Hex Color Code",
        backgroundImage: "배경 이미지",
        thumbnailImageUrl: "섬네일 이미지",
        thumbnailVideoUrl: "섬네일 동영상",
        copyright: "Copyright",
        beforeInactiveFlag: "비활성화를 위해 선물함을 비활성화 해야합니다.",
        copyToCampaignPage: "이벤트 페이지 자동 운영",
        module: {
          reward: {
            reward: "리워드",
            line1: "첫번째 줄",
            line2: "두번째 줄",
          },
          recommend: {
            collectionId: "추천 컬렉션",
          },
          video: {
            embedUrl: "Embed URL",
            streamingFlag: "스트리밍 영상 여부",
          },
          button: {
            buttonTitle: "버튼 명",
          },
          notice: {
            reward: "리워드 지급 공지",
            detail: "유의 사항",
          },
          union: {
            addVideo: "비디오 추가",
            addImage: "이미지 추가",
            addButton: "버튼 추가",
            atLeastOneModule:
              "조합 모듈은 최소 하나의 (비디오/이미지/버튼)타입을 가져야 합니다.",
          },
        },
      },
      giftboxItem: {
        beforeActiveFlag: "활성화를 위해 이벤트 페이지를 활성화 해야합니다.",
        titleImage: "제목 이미지",
        beforeEnter: "선물함 설정을 위해 이벤트 페이지를 먼저 저장 해야합니다.",
        beforeEnterForNoMission:
          "선물함 설정을 위해 이벤트 페이지의 리워드 모듈을 먼저 설정 해야합니다.",
        rewardItemSetting: "리워드 명칭 설정",
        rewardItemType: "리워드 타입",
        rewardItemText: "리워드 문구",
        copyToGiftbox: "선물함 자동 운영",
        choices: {
          GIT_BOX_REWARD_ITEM_TYPE: {
            CASH: "캐시",
            TICKET: "이용권",
            LUCKY_DRAW: "뽑기권",
            ETC: "기타",
          },
        },
      },
    },
    promotion: {
      gift: {
        listPageTitle: "통합 선물함 프로모션",
        listPageTitleHint: "통합 선물함의 이벤트 페이지를 생성하고 관리합니다.",
      },
    },
    module: {
      choices: {
        MODULE_TYPE: {
          REWARD: "리워드 모듈",
          MAIN: "메인 모듈",
          UNION: "조합 모듈",
          IMAGE: "이미지 모듈",
          PUSH_ON: "푸시 ON 버튼 모듈",
          NIGHT_PUSH_ON: "야간 푸시 동의 ON 버튼 모듈",
          SHARE: "공유 모듈",
          COMMENT: "댓글 모듈",
          VIDEO: "비디오 모듈",
          BUTTON: "버튼 모듈",
          RECOMMEND: "추천 작품 모듈",
          NOTICE: "공지 모듈",
        },
        MODULE_VIDEO_TYPE: {
          KAKAO_TV: "KAKAO_TV",
          YOUTUBE: "YOUTUBE",
          AFREECA_TV: "아프리카 TV",
        },
        MODULE_RECOMMEND_TYPE: {
          COLLECTION: "컬렉션 단위 선택",
          CONTENT: "작품 단위 선택",
        },
      },
    },
  },
});
