export interface DataLanguage {
  flag: string;
  lang: string;
  label: string;
}

const state: DataLanguage = {
  flag: "",
  lang: "",
  label: "",
};

const getters = {
  dataLanguageFlag: (state: DataLanguage): string => {
    return state.flag;
  },
  dataLanguage: (state: DataLanguage): string => {
    return state.lang;
  },
  dataLanguageLabel: (state: DataLanguage): string => {
    return state.label;
  },
};

const mutations = {
  setDataLanguage(state: DataLanguage, value: DataLanguage): void {
    state.flag = value.flag;
    state.lang = value.lang;
    state.label = value.label;
  },
};

export default {
  state,
  getters,
  mutations,
};
