export default Object.freeze({
  en: {
    section: {
      sectionManagement: "Section management",
      adOn: "Display Advertisement",
      adOff: "Hide advertisement",
      webToAppOn: "Display Web to App Banner",
      webToAppOff: "Hide Web to App Banner",
      id: "ID",
      name: "Name",
      cardGroupType: "Card Group Type",
      cardGroupManagement: "Card group management",
      description: "Description",
      objectType: "Object Type",
      schedule: "Schedule",
      from: "From",
      to: "To",
      enabled: "Enabled",
      custom: "Custom",
      segmentAll: "All",
      userSegment: "User Segment (all users if not selected)",
      osSegment: "OS Segment (all users if not selected)",
      headerUserSegment: "User Segment",
      headerOsSegment: "OS Segment",
      delete: "Delete",
      duplicate: "Duplicate",
      duplicateConfirm: "Duplicate?",
      preview: "Preview",
      featuredCard: {
        contentId: "Content Id",
        url: "Url",
        labelColor: "Anchor Label Color",
        label: "Anchor Label",
        color: "Color",
        additionalValueText: "additional value text",
        additionalDescriptionText: "additional description text",
        videoFile: "Video File",
        manualAsset: "Manual Asset",
        textAsset: "Text Asset",
        multimediaAsset: "Multimedia Asset",
        contentSelectHint:
          "※ Selecting a content will fill the operating asset.",
        contentSelectHint2:
          "Saving or refreshing maintains the asset of selected content but not the content information.",
        type: "Type",
        event: "Event",
        content: "Content",
        bridgePage: "Bridge Page",
        gidamooPlus: "Wait For Free Plus",
      },
      specialCard: {
        url: "URL",
        titleImage: "Title Image",
        backgroundImage: "Background Image",
        characterImages: "Character Images",
        decorationImages: "Decoration Images",
        keywords: "Keywords",
        primaryKeywordOnclick: "Selected keyword will be highlighted",
        parallaxValue: "Parallax Value",
        choices: {
          SPECIAL_CARD_RESOURCE_TYPE: {
            IMAGE: "Image",
            ALPHA_VIDEO: "Alpha Video",
            ALPHA_IMAGE: "Alpha Image",
          },
        },
      },
      cardCollection: {
        collectionId: "Collection ID",
        collectionContents: "Collection Contents",
        primaryContents: "Primary Contents",
        anchorContent: "Anchor Content",
        timetableTopLabel: "Completed Label",
        anchor: "Anchor",
        order: "Order",
        id: "ID",
        title: "Title",
        thumbnail: "Thumbnail",
        status: "Status",
        ongoingStatus: "Ongoing Status",
        duplicateContentIsNotAllowed: "Duplicate content is not allowed.",
        needToSelectContent: "Please select a content",
      },
      banner: {
        type: "Type",
        title: "Title",
        subtitle: "Subtitle",
        textColor: "Text Color",
        black: "Black",
        white: "White",
        backgroundColor: "Background Color",
        image: "Image",
        mainImage: "Main Image",
        objectImage: "Object Image",
        special: "Special",
        url: "Landing URL",
        collection: "Collection",
      },
      choices: {
        USER_SEGMENT: {
          CURRENT: "Existing users",
          NEW: "New users",
        },
        OS_SEGMENT: {
          IA: "iOS",
          AA: "Android",
          PW: "PC web",
          MW: "Mobile web",
        },
        BANNER_TYPE: {
          BASIC: "Basic",
          EVENT_SPECIAL: "Special",
        },
        OBJECT_TYPE: {
          FEATURED_CARD: "Main Featured",
          CARD_COLLECTION: "Collection",
          BANNER_CARD: "Banner",
          BANNER_EVENT_SPECIAL_CARD: "Special Banner",
          SPECIAL_CARD: "Main Special",
          GIDAMOO_CARD: "Main Wait For Free Plus",
        },
      },
    },
    eventPopup: {
      eventPopupManagement: "Event popup management",
      noBackgroundImage:
        "No background image found on selected content / event page.",
      id: "ID",
      description: "Description",
      title: "Title",
      subtitle: "Subtitle",
      buttonText: "Button Text",
      buttonUrl: "Button URL",
      image: "Image",
      enabled: "Enabled",
      userSegment: "User Segment",
      osSegment: "OS Segment",
      from: "From",
      to: "To",
      schedule: "Schedule",
      delete: "Delete",
    },
    bridgePage: {
      bridgePageManagement: "Bridge page management",
      id: "ID",
      description: "Description",
      created: "Created",
      updated: "Updated",
      delete: "Delete",
      firstPageSetting: "1st Page Setting",
      secondPageAndAfterSetting: "2nd Page And After Setting",
      lastPageSetting: "Last Page Setting",
      page: " Page",
      backgroundImage: "Background Image",
      titleImage: "Title Image",
      frontResource: "Front Resource",
      firstPageFrontResource: "First page front resource",
      videoFirstFrame: "Video first frame",
      videoLastFrame: "Video last frame",
      backResource: "Back Resource",
      buttonDecoratorImage: "Button Decorator Image",
      url: "URL",
      choices: {
        BRIDGE_PAGE_RESOURCE_TYPE: {
          VIDEO: "video",
          ALPHA_VIDEO: "alpha video",
          VECTOR_VIDEO: "vector video",
          IMAGE: "image",
          ALPHA_IMAGE: "alpha image",
        },
      },
    },
    matchingCollection: {
      management: "Collection management",
      id: "ID",
      title: "Title",
      contentsCount: "Contents count",
      created: "Created date",
      updated: "Updated date",
      create: "Create",
      delete: "Delete",
      matchingType: "Matching type",
      contentList: "Content list",
      choices: {
        MATCHING_TYPE: {
          OPS: "General operation",
          SPECIAL: "Content home special matching",
        },
      },
    },
    converter: {
      header: "Image converter",
      alpha: "Include alpha channel",
    },
    schedule: {
      itemCount: "{count} item(s)",
      applyToChangeTheOrder: "Apply order change",
    },
  },
  ko: {
    section: {
      sectionManagement: "섹션 관리",
      adOn: "광고 노출",
      adOff: "광고 비노출",
      webToAppOn: "Web to App 배너 노출",
      webToAppOff: "Web to App 배너 비노출",
      id: "ID",
      name: "이름",
      cardGroupType: "카드 그룹 타입",
      cardGroupManagement: "카드 그룹 관리",
      description: "설명",
      objectType: "카드 종류",
      schedule: "스케쥴",
      from: "시작일",
      to: "종료일",
      enabled: "활성",
      custom: "커스텀",
      segmentAll: "전체",
      userSegment: "유저 세그먼트 (선택하지 않았을 시 전체)",
      osSegment: "OS 세그먼트 (선택하지 않았을 시 전체)",
      headerUserSegment: "유저 세그먼트",
      headerOsSegment: "OS 세그먼트",
      delete: "삭제",
      duplicate: "복제",
      duplicateConfirm: "해당 카드를 복제하시겠습니까?",
      preview: "미리보기",
      featuredCard: {
        contentId: "콘텐츠 Id",
        url: "URL",
        labelColor: "앵커 레이블 색깔",
        label: "앵커 레이블",
        color: "컬러",
        additionalValueText: "추가 값 텍스트",
        additionalDescriptionText: "추가 설명 텍스트",
        videoFile: "비디오 파일",
        manualAsset: "메뉴얼 에셋",
        textAsset: "텍스트 에셋",
        multimediaAsset: "멀티미디어 에셋",
        contentSelectHint: "※ 작품을 선택하면 운영된 에셋이 채워집니다.",
        contentSelectHint2:
          "저장/새로고침 시 선택한 작품의 에셋만 유지되며, 선택한 작품 자체의 정보가 유지되지는 않습니다.",
        type: "타입",
        event: "이벤트",
        content: "작품",
        bridgePage: "브릿지 페이지",
        gidamooPlus: "기다무+",
      },
      specialCard: {
        url: "URL",
        titleImage: "타이틀 이미지",
        backgroundImage: "백그라운드 이미지",
        characterImages: "캐릭터 이미지",
        decorationImages: "데코 이미지",
        keywords: "키워드",
        primaryKeywordOnclick: "키워드를 클릭시 강조 노출됩니다",
        parallaxValue: "패럴랙스 값",
        choices: {
          SPECIAL_CARD_RESOURCE_TYPE: {
            IMAGE: "이미지",
            ALPHA_VIDEO: "알파 비디오",
            ALPHA_IMAGE: "알파 이미지",
          },
        },
      },
      cardCollection: {
        collectionId: "컬렉션 ID",
        collectionContents: "작품 컬렉션",
        primaryContents: "주요 작품",
        anchorContent: "앵커 작품",
        timetableTopLabel: "완결 레이블",
        anchor: "앵커",
        order: "순서",
        id: "ID",
        title: "제목",
        status: "상태",
        ongoingStatus: "연재 상태",
        thumbnail: "썸네일",
        duplicateContentIsNotAllowed: "중복 작품은 허용되지 않습니다.",
        needToSelectContent: "작품을 선택해주세요",
      },
      banner: {
        type: "타입",
        title: "제목",
        subtitle: "부제목",
        textColor: "텍스트 컬러",
        black: "블랙",
        white: "화이트",
        backgroundColor: "백그라운드 컬러",
        image: "이미지",
        mainImage: "메인 이미지",
        objectImage: "부가 이미지",
        special: "스페셜",
        url: "랜딩 URL",
        collection: "컬렉션",
      },
      choices: {
        USER_SEGMENT: {
          CURRENT: "기존 유저",
          NEW: "신규 유저",
        },
        OS_SEGMENT: {
          IA: "iOS",
          AA: "Android",
          PW: "PC web",
          MW: "Mobile web",
        },
        BANNER_TYPE: {
          BASIC: "기본",
          EVENT_SPECIAL: "스페셜",
        },
        OBJECT_TYPE: {
          FEATURED_CARD: "메인 홈 추천",
          CARD_COLLECTION: "컬렉션",
          BANNER_CARD: "배너",
          BANNER_EVENT_SPECIAL_CARD: "스페셜 배너",
          SPECIAL_CARD: "메인 홈 스페셜",
          GIDAMOO_CARD: "메인 홈 기다무+",
        },
      },
    },
    eventPopup: {
      eventPopupManagement: "스플래시 팝업 관리",
      noBackgroundImage:
        "지정한 작품/이벤트페이지에 백그라운드 이미지가 없습니다!",
      id: "ID",
      description: "설명",
      title: "제목",
      subtitle: "부제목",
      buttonText: "버튼 문구",
      buttonUrl: "버튼 URL",
      image: "이미지",
      enabled: "활성",
      userSegment: "유저 세그먼트",
      osSegment: "OS 세그먼트",
      from: "시작일",
      to: "종료일",
      schedule: "스케줄",
      delete: "삭제",
    },
    bridgePage: {
      bridgePageManagement: "브릿지 페이지 관리",
      id: "ID",
      description: "설명",
      created: "생성일",
      updated: "수정일",
      delete: "삭제",
      firstPageSetting: "첫번째 페이지 셋팅",
      secondPageAndAfterSetting: "두번째 페이지 이후 셋팅",
      lastPageSetting: "마지막 페이지 셋팅",
      page: " 페이지",
      backgroundImage: "백그라운드 이미지",
      titleImage: "타이틀 이미지",
      frontResource: "프론트 리소스",
      firstPageFrontResource: "첫번째 페이지 프론트 리소스",
      videoFirstFrame: "비디오 첫번째 프레임",
      videoLastFrame: "비디오 마지막 프레임",
      backResource: "백 리소스",
      buttonDecoratorImage: "버튼 데코 이미지",
      url: "URL",
      choices: {
        BRIDGE_PAGE_RESOURCE_TYPE: {
          VIDEO: "비디오",
          ALPHA_VIDEO: "알파 비디오",
          VECTOR_VIDEO: "벡터 비디오",
          IMAGE: "이미지",
          ALPHA_IMAGE: "알파 이미지",
        },
      },
    },
    matchingCollection: {
      management: "컬렉션 관리",
      id: "ID",
      title: "제목",
      contentsCount: "작품 수",
      created: "생성일",
      updated: "수정일",
      create: "생성",
      delete: "삭제",
      matchingType: "매칭 타입",
      contentList: "작품 리스트",
      choices: {
        MATCHING_TYPE: {
          OPS: "일반 운영",
          SPECIAL: "작품홈 스페셜 매칭",
        },
      },
    },
    converter: {
      header: "이미지 컨버터",
      alpha: "알파채널 포함",
    },
    schedule: {
      itemCount: "{count}개 아이템",
      applyToChangeTheOrder: "순서 변경 반영",
    },
  },
});
