import { ServiceLanguage } from "@/const/service/language";
import { CardLabel } from "@/views/sms/section/common/types/featured";
import { ChoiceItem } from "@/common/types";
import { MaxLength } from "@/const/service/common/types";

export class Indonesian extends ServiceLanguage {
  getGenreChoices(): ChoiceItem<string>[] {
    return [
      { text: "Romansa Fantasi", value: "ROMANCE_FANTASY" },
      { text: "Romansa", value: "ROMANCE" },
      { text: "Drama", value: "DRAMA" },
      { text: "Aksi", value: "ACTION" },
      { text: "Thriller/Horor", value: "HORROR_THRILLER" },
    ];
  }

  getCardLabels(): CardLabel[] {
    return [
      // RED
      {
        text: "Upcoming", //
        backgroundColor: "#FF3042",
      },
      {
        text: "New Release", // 신작
        backgroundColor: "#FF3042",
      },
      {
        text: "Comeback",
        backgroundColor: "#FF3042",
      },
      {
        text: "Comeback Season2",
        backgroundColor: "#FF3042",
      },
      {
        text: "Comeback Season3",
        backgroundColor: "#FF3042",
      },
      {
        text: "Comeback Season4",
        backgroundColor: "#FF3042",
      },
      {
        text: "Comeback Season5",
        backgroundColor: "#FF3042",
      },
      {
        text: "Comeback New Episode",
        backgroundColor: "#FF3042",
      },
      {
        text: "Webtoon End",
        backgroundColor: "#FF3042",
      },
      {
        text: "Season End",
        backgroundColor: "#FF3042",
      },
      {
        text: "Hot",
        backgroundColor: "#FF3042",
      },
      {
        text: "Rising UP",
        backgroundColor: "#FF3042",
      },

      // GOLD
      {
        text: "Upcoming",
        backgroundColor: "#C6A66D",
      },
      {
        text: "New Release",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Comeback",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Comeback Season2",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Comeback Season3",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Comeback Season4",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Comeback Season5",
        backgroundColor: "#C6A66D",
      },
      {
        backgroundColor: "#C6A66D",
        text: "Comeback New Episode",
      },
      {
        text: "Pre-comeback",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Webtoon End",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Season End",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Pra-registrasi",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Tiket Hadiah",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Flash Event",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Diskon Cash",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Tunggu Gratis+",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Cek Kehadiran",
        backgroundColor: "#C6A66D",
      },
      {
        text: "Event",
        backgroundColor: "#C6A66D",
      },
    ];
  }

  getCompletedTabLabels(): CardLabel[] {
    return [
      { text: "Legend", backgroundColor: "#FF3042" }, // 레전드
      { text: "Rekomendasi Editor", backgroundColor: "#FF3042" }, // PD추천
      { text: "Trailer film", backgroundColor: "#FF3042" }, // 영화 예고편
      { text: "Rekomendasi", backgroundColor: "#FF3042" }, // 추천
      { text: "Majalah", backgroundColor: "#FF3042" }, // 매거진
      { text: "Tiket Sewa", backgroundColor: "#FF3042" }, // 대여권
      { text: "Cashback", backgroundColor: "#FF3042" }, // 캐시백
    ];
  }

  getContentCatchphraseMaxLength(): MaxLength {
    return {
      threeLines: 25,
      twoLines: 35,
    };
  }

  getIpPromotionVideoTitleMaxLength(): number {
    return 0;
  }

  getIpPromotionVideoSubtitleMaxLength(): number {
    return 0;
  }

  getSpecialMatchingTitleMaxLength(): number {
    return 20;
  }

  getCustomLabelMaxLength(): number {
    return 0;
  }

  getCampaignPageTitleMaxLength(): number {
    return 20;
  }
  getCampaignPageModuleTitleMaxLength(): number {
    return 25;
  }
  getCampaignPageModuleTitleRowLength(): number {
    return 2;
  }
  getCampaignPageModuleSubTitleMaxLength(): number {
    return 35;
  }
  getCampaignPageModuleSubTitleRowLength(): number {
    return 4;
  }
}
