export default Object.freeze({
  en: {
    notice: {
      listPageTitle: "Notice List",
      listPageTitleHint: "Create and manage notice.",
      newPageTitle: "Create a notice",
      editPageTitle: "Edit notice",
      viewPageTitle: "View notice",
      changeSort: "Change order",
      segment: "Segment",
      titleHint: "Entered information will be displayed on user's Notice.",
      choices: {
        NOTICE_PLATFORM: {
          AA: "Android",
          IA: "iOS",
          PW: "PC Web",
          MW: "Mobile Web",
        },
      },
      popup: {
        listPageTitle: "Popup Notification",
        listPageTitleHint:
          "Register popup notification related to service usage.",
        formTitle: "Popup notification",
        titleHint: "Enter title for the notification.",
        subTitleHint:
          "Enter estimated service maintenance timeframe or important details requiring emphasis.",
        shutdownServiceFlag: "Force quit app",
        shutdownServiceHint: "Select only if out of service due to emergency.",
        buttonTitle: "Button title",
        buttonFlag: "Show button",
      },
    },
    faq: {
      listPageTitle: "FAQ List",
      listPageTitleHint: "Create and manage FAQ.",
      newPageTitle: "Create FAQ",
      editPageTitle: "Edit FAQ",
      viewPageTitle: "View FAQ",
      changeSort: "Change order",
      segment: "Segment",
      titleHint: "Entered information will be displayed on user's FAQ.",
      choices: {
        NOTICE_PLATFORM: {
          AA: "Android",
          IA: "iOS",
          PW: "PC Web",
          MW: "Mobile Web",
        },
      },
    },
    textEditor: {
      colorGuide1: "The color of the body text should be #A6A6A6.",
      colorGuide2: "The color of the link should be #DDDDDD..",
      colorGuide3: "Please avoid using other colors.",
    },
  },
  ko: {
    notice: {
      listPageTitle: "공지사항 리스트",
      listPageTitleHint: "공지사항을 등록 및 관리합니다.",
      newPageTitle: "새로운 공지사항 생성",
      editPageTitle: "공지사항 수정",
      viewPageTitle: "공지사항 보기",
      changeSort: "순서변경",
      segment: "세그먼트",
      titleHint: "입력한 제목은 사용자의 공지사항에 노출됩니다.",
      choices: {
        NOTICE_PLATFORM: {
          AA: "Android",
          IA: "iOS",
          PW: "PC Web",
          MW: "Mobile Web",
        },
      },
      popup: {
        listPageTitle: "공지 팝업 관리",
        listPageTitleHint: "서비스 이용과 관련된 공지 팝업을 등록합니다.",
        formTitle: "공지 팝업 운영",
        titleHint: "공지 상단에 노출될 제목을 입력하세요.",
        subTitleHint:
          "서비스 점검 예상 시간 또는 강조하고자 하는 중요 내용 등을 입력하세요.",
        shutdownServiceFlag: "앱 강제 종료",
        shutdownServiceHint:
          "긴급 장애로 서비스 사용이 불가할 경우에만 체크 권장",
        buttonTitle: "버튼 명",
        buttonFlag: "버튼 노출 여부",
      },
    },
    faq: {
      listPageTitle: "FAQ 리스트",
      listPageTitleHint: "FAQ를 등록 및 관리합니다.",
      newPageTitle: "새로운 FAQ 생성",
      editPageTitle: "FAQ 수정",
      viewPageTitle: "FAQ 보기",
      changeSort: "순서변경",
      segment: "세그먼트",
      titleHint: "입력한 제목은 사용자의 FAQ에 노출됩니다.",
      choices: {
        NOTICE_PLATFORM: {
          AA: "Android",
          IA: "iOS",
          PW: "PC Web",
          MW: "Mobile Web",
        },
      },
    },
    textEditor: {
      colorGuide1: "본문 작성 시, 텍스트 색상은 #A6A6A6",
      colorGuide2: "링크 작성 시, 텍스트 색상은 #DDDDDD 작성해주세요.",
      colorGuide3: "해당 색상 외에 다른 색상 사용은 지양해주세요.",
    },
  },
});
