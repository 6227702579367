import { DataOptions } from "vuetify";
import router from "@/router";

export const defaultDataTableOptions: DataOptions = {
  page: 1,
  itemsPerPage: 10,
  sortBy: ["id"],
  sortDesc: [true],
  groupBy: [],
  groupDesc: [],
  multiSort: true,
  mustSort: false,
};

export function getQueryParamsDataTableOptions(
  vueInstance: Vue,
  dataTableOptions: DataOptions = defaultDataTableOptions,
): DataOptions {
  return {
    ...dataTableOptions,
    page: parseInt(vueInstance.$route.query?.page as string)
      ? parseInt(vueInstance.$route.query.page as string)
      : dataTableOptions.page,
    sortBy: vueInstance.$route.query?.sortBy
      ? typeof vueInstance.$route.query.sortBy === "string"
        ? [vueInstance.$route.query.sortBy]
        : (vueInstance.$route.query.sortBy as string[])
      : dataTableOptions.sortBy,
    sortDesc: vueInstance.$route.query?.sortDesc
      ? typeof vueInstance.$route.query.sortDesc === "string"
        ? [JSON.parse(vueInstance.$route.query.sortDesc.toLowerCase())]
        : (vueInstance.$route.query.sortDesc as string[]).map((e) =>
            JSON.parse(e.toLowerCase()),
          )
      : dataTableOptions.sortDesc,
    itemsPerPage: parseInt(vueInstance.$route.query?.itemsPerPage as string)
      ? parseInt(vueInstance.$route.query.itemsPerPage as string)
      : dataTableOptions.itemsPerPage,
  };
}

export function getQueryParamsDataTableOptionsNew(
  dataTableOptions: DataOptions = defaultDataTableOptions,
): DataOptions {
  return {
    ...dataTableOptions,
    page: parseInt(router.app.$route.query?.page as string)
      ? parseInt(router.app.$route.query.page as string)
      : dataTableOptions.page,
    sortBy: router.app.$route.query?.sortBy
      ? typeof router.app.$route.query.sortBy === "string"
        ? [router.app.$route.query.sortBy]
        : (router.app.$route.query.sortBy as string[])
      : dataTableOptions.sortBy,
    sortDesc: router.app.$route.query?.sortDesc
      ? typeof router.app.$route.query.sortDesc === "string"
        ? [JSON.parse(router.app.$route.query.sortDesc.toLowerCase())]
        : (router.app.$route.query.sortDesc as string[]).map((e) =>
            JSON.parse(e.toLowerCase()),
          )
      : dataTableOptions.sortDesc,
    itemsPerPage: parseInt(router.app.$route.query?.itemsPerPage as string)
      ? parseInt(router.app.$route.query.itemsPerPage as string)
      : dataTableOptions.itemsPerPage,
  };
}

export const MAX_SCREEN_WIDTH = 1600;
