export enum Permission {
  REGISTER_ADMIN = "REGISTER_ADMIN",
  EDIT_ADMIN = "EDIT_ADMIN",
  MENU_CMS = "MENU_CMS",
  READ_CONTENT_AUTHOR = "READ_CONTENT_AUTHOR",
  EDIT_CONTENT_AUTHOR = "EDIT_CONTENT_AUTHOR",
  READ_METADATA_ITEM = "READ_METADATA_ITEM",
  EDIT_METADATA_ITEM = "EDIT_METADATA_ITEM",
  MENU_SMS = "MENU_SMS",
  READ_EDIT_SECTION = "READ_EDIT_SECTION",
  READ_COLLECTION = "READ_COLLECTION",
  EDIT_COLLECTION = "EDIT_COLLECTION",
  MENU_REWARD = "MENU_REWARD",
  READ_EDIT_PRESENT = "READ_EDIT_PRESENT",
  READ_EDIT_LUCKY_DRAW = "READ_EDIT_LUCKY_DRAW",
  MENU_CRM = "MENU_CRM",
  READ_MSG_PROMO = "READ_MSG_PROMO",
  EDIT_MSG_PROMO = "EDIT_MSG_PROMO",
  READ_SEGMENT_TICKER = "READ_SEGMENT_TICKER",
  EDIT_SEGMENT_TICKER = "EDIT_SEGMENT_TICKER",
  READ_QUEST_CAMPAIGN = "READ_QUEST_CAMPAIGN",
  EDIT_QUEST_CAMPAIGN = "EDIT_QUEST_CAMPAIGN",
  MENU_NOTICE_CS = "MENU_NOTICE_CS",
  READ_EDIT_NOTICE = "READ_EDIT_NOTICE",
  READ_EDIT_FAQ = "READ_EDIT_FAQ",
  READ_EDIT_POPUP_NOTIFICATION = "READ_EDIT_POPUP_NOTIFICATION",
  MENU_MEMBER = "MENU_MEMBER",
  READ_MEMBER = "READ_MEMBER",
  EDIT_MEMBER = "EDIT_MEMBER",
  READ_BLOCKED_MEMBER = "READ_BLOCKED_MEMBER",
  EDIT_BLOCKED_MEMBER = "EDIT_BLOCKED_MEMBER",
  READ_EDIT_PROHIBITION_NICKNAME = "READ_EDIT_PROHIBITION_NICKNAME",
  MENU_COMMENT = "MENU_COMMENT",
  READ_EDIT_REPORT_COMMENT = "READ_EDIT_REPORT_COMMENT",
  READ_EDIT_COMMENT = "READ_EDIT_COMMENT",
  READ_EDIT_COMMENT_BLOCK_USER = "READ_EDIT_COMMENT_BLOCK_USER",
  READ_EDIT_PROHIBITION_COMMENT = "READ_EDIT_PROHIBITION_COMMENT",
  READ_EDIT_UTILITY = "READ_EDIT_UTILITY",
  MENU_APP_VERSION = "MENU_APP_VERSION",
  READ_EDIT_APP_VERSION = "READ_EDIT_APP_VERSION",
  READ_REDEEM_CAMPAIGN = "READ_REDEEM_CAMPAIGN",
  EDIT_REDEEM_CAMPAIGN = "EDIT_REDEEM_CAMPAIGN",
  READ_TICKET_DISCOUNT_CAMPAIGN = "READ_TICKET_DISCOUNT_CAMPAIGN",
  EDIT_TICKET_DISCOUNT_CAMPAIGN = "EDIT_TICKET_DISCOUNT_CAMPAIGN",
  READ_TICKET_GIFT_BOX = "READ_TICKET_GIFT_BOX",
  EDIT_TICKET_GIFT_BOX = "EDIT_TICKET_GIFT_BOX",
  READ_GIFT_PROMOTION = "READ_GIFT_PROMOTION",
  EDIT_GIFT_PROMOTION = "EDIT_GIFT_PROMOTION",
  READ_EDIT_RAFFLE = "READ_EDIT_RAFFLE",
}
