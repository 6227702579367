export default Object.freeze({
  en: {
    user: {
      user: "Admin",
      userManagement: "Admin management",
      userManagementHint: "Register and manage admins.",
      registerUser: "Register admin",
      id: "ID",
      staffId: "Admin ID",
      created: "Created",
      permitted: "Activate account",
      delete: "Delete",
      register: "Register",
      retrieveUser:
        "Search registered admin (begin the search with first letter)",
      allUsers: "All admins",
      assignedUsers: "Assigned admin",
    },
    role: {
      role: "Role",
      roleManagement: "Role management",
      roleManagementHint: "Create and manage roles.",
      createRole: "Create role",
      id: "ID",
      name: "Name",
      created: "Created",
      delete: "Delete",
      create: "Create",
      allRoles: "All roles",
      grantedRoles: "Assigned role",
    },
    permission: {
      permission: "Permission",
      code: "Permission code",
      description: "Description",
      allPermissions: "All permissions",
      grantedPermissions: "Assigned permissions",
    },
  },
  ko: {
    user: {
      user: "운영자",
      userManagement: "운영자 관리",
      userManagementHint: "운영자를 등록하고 관리합니다.",
      registerUser: "운영자 등록",
      id: "ID",
      staffId: "운영자ID",
      created: "등록일",
      permitted: "계정 활성",
      delete: "삭제",
      register: "등록",
      retrieveUser: "기 가입된 운영자 검색 (앞글자부터 검색 가능합니다.)",
      allUsers: "전체 운영자",
      assignedUsers: "할당된 운영자",
    },
    role: {
      role: "역할",
      roleManagement: "역할 관리",
      roleManagementHint: "역할을 생성하고 관리합니다.",
      createRole: "역할 생성",
      id: "ID",
      name: "이름",
      created: "등록일",
      delete: "삭제",
      create: "생성",
      allRoles: "전체 역할",
      grantedRoles: "부여된 역할",
    },
    permission: {
      permission: "퍼미션",
      code: "퍼미션 코드",
      description: "설명",
      allPermissions: "전체 퍼미션",
      grantedPermissions: "할당된 퍼미션",
    },
  },
});
