import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/HomePage.vue";
import EmptyPage from "@/views/index/PageEmptyRouter.vue";
import { AppVersionRoutes } from "@/views/app-version/common/types";

Vue.use(VueRouter);

const content = [
  {
    path: "/",
    name: "ContentList",
    component: () => import("@/views/cms/content/ContentListPage.vue"),
  },
  {
    path: ":id",
    name: "ContentTab",
    component: () => import("@/views/cms/content/ContentTabPage.vue"),
    props: true,
  },
];

const author = [
  {
    path: "/",
    name: "AuthorList",
    component: () => import("@/views/cms/author/AuthorListPage.vue"),
  },
  {
    path: ":id",
    name: "AuthorDetail",
    component: () => import("@/views/cms/author/AuthorInfoPage.vue"),
    props: true,
  },
];

const submissionEpisode = [
  {
    path: "/",
    name: "SubmissionEpisodeList",
    component: () =>
      import("@/views/cms/submission-episode/SubmissionEpisodeListPage.vue"),
  },
  {
    path: ":id",
    name: "SubmissionEpisodeDetail",
    component: () =>
      import("@/views/cms/submission-episode/SubmissionEpisodeInfoPage.vue"),
    props: true,
  },
];

const cp = [
  {
    path: "/",
    name: "CpList",
    component: () => import("@/views/cms/cp/ContentProviderListPage.vue"),
  },
  {
    path: ":id",
    name: "CpDetail",
    component: () => import("@/views/cms/cp/ContentProviderInfoPage.vue"),
    props: true,
  },
];

const metadataItem = [
  {
    path: "/",
    name: "MetadataItemList",
    component: () =>
      import("@/views/cms/metadata-item/MetadataItemListPage.vue"),
  },
];

const section = [
  {
    path: "/",
    name: "SectionList",
    component: () => import("@/views/sms/section/SectionListPage.vue"),
  },
  {
    path: ":sectionCode/card-groups",
    name: "CardGroupList",
    component: () => import("@/views/sms/section/CardGroupListPage.vue"),
    props: true,
  },
  {
    path: ":sectionCode/card-groups/new",
    name: "CardGroupCreate",
    component: () => import("@/views/sms/section/CardGroupInfoPage.vue"),
  },
  {
    path: ":sectionCode/card-groups/:id",
    name: "CardGroupUpdate",
    component: () => import("@/views/sms/section/CardGroupInfoPage.vue"),
    props: true,
  },
];

const eventPopup = [
  {
    path: "/",
    name: "EventPopupList",
    component: () => import("@/views/sms/event-popup/EventPopupListPage.vue"),
  },
  {
    path: "new",
    name: "EventPopupCreate",
    component: () => import("@/views/sms/event-popup/EventPopupInfoPage.vue"),
  },
  {
    path: ":id",
    name: "EventPopupUpdate",
    component: () => import("@/views/sms/event-popup/EventPopupInfoPage.vue"),
    props: true,
  },
];

const bridgePage = [
  {
    path: "/",
    name: "BridgePageList",
    component: () => import("@/views/sms/bridge-page/BridgePageListPage.vue"),
  },
  {
    path: "new",
    name: "BridgePageCreate",
    component: () => import("@/views/sms/bridge-page/BridgePageInfoPage.vue"),
  },
  {
    path: ":id",
    name: "BridgePageUpdate",
    component: () => import("@/views/sms/bridge-page/BridgePageInfoPage.vue"),
    props: true,
  },
];

const matching = [
  {
    path: "/",
    name: "MatchingList",
    component: () => import("@/views/sms/matching/MatchingListPage.vue"),
  },
  {
    path: ":id",
    name: "MatchingDetail",
    component: () => import("@/views/sms/matching/MatchingInfoPage.vue"),
    props: true,
  },
];

const rewards = [
  {
    path: "/presents",
    component: () => import("@/views/reward/present/PresentListPage.vue"),
  },
  {
    path: "/raffles",
    component: () => import("@/views/reward/raffle/RaffleListPage.vue"),
  },
  {
    path: "/lucky-draws",
    component: () => import("@/views/reward/luckydraw/LuckyDrawListPage.vue"),
  },
];
const crm = [
  {
    path: "/message-promotions",
    component: () => import("@/views/crm/message/MessagePromotionListPage.vue"),
  },
  {
    path: "/redeem-campaigns",
    component: () => import("@/views/crm/redeem/RedeemCampaignListPage.vue"),
  },
  {
    path: "/v2/redeem-campaigns",
    component: () => import("@/views/crm/redeem/v2/RedeemCampaignListPage.vue"),
  },
  {
    path: "/v2/redeem-campaigns/:redeemCampaignId/code-groups",
    component: () =>
      import("@/views/crm/redeem/v2/group/RedeemCodeGroupListPage.vue"),
    props: true,
  },
  {
    path: "/redeem-campaigns/:redeemCampaignId/code-groups/",
    component: () =>
      import("@/views/crm/redeem/group/RedeemCodeGroupListPage.vue"),
  },
  {
    path: "/ticket-discounts",
    component: () =>
      import("@/views/crm/ticket/discount/TicketDiscountListPage.vue"),
  },
  {
    path: "/giftbox-tickets",
    component: () =>
      import("@/views/crm/giftbox/ticket/GiftboxTicketListPage.vue"),
  },
  {
    path: "/quest-campaigns",
    component: () => import("@/views/crm/quest/QuestCampaignListPage.vue"),
  },
  {
    path: "/segment-tickers",
    component: () =>
      import("@/views/crm/segment/ticker/SegmentTickerListPage.vue"),
  },
  {
    path: "/gift-promotions",
    component: () =>
      import("@/views/crm/promotion/gift/GiftPromotionListPage.vue"),
  },
];
const cs = [
  {
    path: "/notices",
    component: () => import("@/views/cs/notice/NoticeListPage.vue"),
  },
  {
    path: "/faqs",
    component: () => import("@/views/cs/faq/FaqListPage.vue"),
  },
  {
    path: "/notice-popups",
    component: () => import("@/views/cs/notice/popup/NoticePopupListPage.vue"),
  },
];
const member = [
  {
    path: "/member-search",
    component: () => import("@/views/member/search/MemberSearchPage.vue"),
  },
  {
    path: "/service-block",
    component: () =>
      import("@/views/member/service-block/ServiceLoginBlockUserPage.vue"),
  },
  {
    path: "/nickname-prohibition",
    component: () =>
      import("@/views/member/nickname-prohibition/NicknameProhibitionPage.vue"),
  },
];

const comment = [
  {
    path: "/reports",
    component: () => import("@/views/comment/comment/CommentListPage.vue"),
    props: { reportPage: true },
  },
  {
    path: "/comments",
    component: () => import("@/views/comment/comment/CommentListPage.vue"),
  },
  {
    path: "/banned-users",
    component: () => import("@/views/comment/user/CommentUserListPage.vue"),
  },
  {
    path: "/banned-words",
    component: () => import("@/views/comment/word/CommentWordListPage.vue"),
  },
];

const users = [
  {
    path: "/",
    name: "UserList",
    component: () => import("@/views/auth/user/UserListPage.vue"),
  },
  {
    path: ":id",
    name: "UserDetail",
    component: () => import("@/views/auth/user/UserInfoPage.vue"),
    props: true,
  },
];

const roles = [
  {
    path: "/",
    name: "RoleList",
    component: () => import("@/views/auth/role/RoleListPage.vue"),
  },
  {
    path: ":id",
    name: "RoleDetail",
    component: () => import("@/views/auth/role/RoleInfoPage.vue"),
    props: true,
  },
];

const appVersion = [
  {
    path: `/${"kor-prod-app-version" as AppVersionRoutes}`,
    component: () => import("@/views/app-version/AppVersionManagementPage.vue"),
  },
  {
    path: `/${"global-prod-app-version" as AppVersionRoutes}`,
    component: () => import("@/views/app-version/AppVersionManagementPage.vue"),
  },
  {
    path: `/${"kor-qa-app-version" as AppVersionRoutes}`,
    component: () => import("@/views/app-version/AppVersionManagementPage.vue"),
  },
  {
    path: `/${"global-qa-app-version" as AppVersionRoutes}`,
    component: () => import("@/views/app-version/AppVersionManagementPage.vue"),
  },
  {
    path: `/${"kor-sandbox-app-version" as AppVersionRoutes}`,
    component: () => import("@/views/app-version/AppVersionManagementPage.vue"),
  },
  {
    path: `/${"global-sandbox-app-version" as AppVersionRoutes}`,
    component: () => import("@/views/app-version/AppVersionManagementPage.vue"),
  },
];

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/contents",
    component: EmptyPage,
    children: content,
  },
  {
    path: "/authors",
    component: EmptyPage,
    children: author,
  },
  {
    path: "/submission-episodes",
    component: EmptyPage,
    children: submissionEpisode,
  },
  {
    path: "/cps",
    component: EmptyPage,
    children: cp,
  },
  {
    path: "/metadata-items",
    component: EmptyPage,
    children: metadataItem,
  },
  {
    path: "/sections",
    component: EmptyPage,
    children: section,
  },
  {
    path: "/event-popups",
    component: EmptyPage,
    children: eventPopup,
  },
  {
    path: "/bridge-pages",
    component: EmptyPage,
    children: bridgePage,
  },
  {
    path: "/matching-collections",
    component: EmptyPage,
    children: matching,
  },
  {
    path: "/converter",
    component: () => import("@/views/sms/converter/ConverterPage.vue"),
  },
  {
    path: "/rewards",
    component: EmptyPage,
    children: rewards,
  },
  {
    path: "/crm",
    component: EmptyPage,
    children: crm,
  },
  {
    path: "/cs",
    component: EmptyPage,
    children: cs,
  },
  {
    path: "/member",
    component: EmptyPage,
    children: member,
  },
  {
    path: "/comments",
    component: EmptyPage,
    children: comment,
  },
  {
    path: "/users",
    component: EmptyPage,
    children: users,
  },
  {
    path: "/roles",
    component: EmptyPage,
    children: roles,
  },
  {
    path: "/app-versions",
    component: EmptyPage,
    children: appVersion,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
