interface ServiceLanguage {
  serviceLang: string;
}

const state: ServiceLanguage = {
  serviceLang: "",
};

const getters = {
  serviceLang: (state: ServiceLanguage): string => {
    return state.serviceLang;
  },
};

const mutations = {
  setServiceLanguage(state: ServiceLanguage, serviceLang: string): void {
    state.serviceLang = serviceLang;
  },
};

export default {
  state,
  getters,
  mutations,
};
