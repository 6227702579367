/**
 * 사용법
  export enum SomeType {
    A = 'A',
    B = 'B',
  }

  const SomeTypes = Object.freeze(
    new Choices(SomeType, {
      [SomeType.A]: {
        text: i18n.t('msg.banners.contentTypes.A').toString(),
        value: SomeType.A,
      },
      [SomeType.B]: {
        text: i18n.t('msg.banners.contentTypes.B').toString(),
        value: SomeType.B,
      },
    }),
  );

  const choices = SomeTypes.getChoices()
  const typeBString = SomeTypes.getText(SomeType.B)
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export class Choices<T extends { [key in keyof T]: any }> {
  constructor(
    public enumObj: T,
    public map: { [key in T[keyof T]]: { text: string; value: T[keyof T] } },
  ) {}

  getChoices(): {
    text: string;
    value: T[keyof T];
  }[] {
    return Object.values(this.map);
  }

  getText(key: keyof T): string {
    const member = this.enumObj[this.enumObj[key]];
    if (member !== undefined) {
      return this.map[member].text;
    } else {
      return "-";
    }
  }
}
