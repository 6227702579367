export default Object.freeze({
  en: {
    search: {
      memberSearch: "Search member",
      searchFilter: "Search filter",
      inputText: "Enter text",
      search: "Search",
      searchResult: "Search results for member",
      UID: "user_id",
      accountId: "kakao account_id",
      csId: "Account number",
      daumId: "daum id",
      daumUserId: "daum user_id",
      migrated: "Migrated",
      migratedMember: "Migrated member",
      newMember: "New member",
      email: "Email address",
      nickname: "Nickname",
      linkedSNS: "linked SNS",
      signUpDate: "Date of sign up ",
      memberStatus: "Member status",
      blockCommentStatus: "Blocked comment status",
      blockServiceLoginStatus: "Blocked login status",
      loginLastDate: "Last login date",
      loginLastPath: "Last login method",
      deviceLoginInform: "Signup/login device info",
      deviceDownloadInform: "Registered device info",
      deviceRemoveCount: "Device deregister count",
      region: "Region / country",
      deleteAccount: "Delete account",
      goToCommentMenu: "Go to comment menu",
      block: "Block",
      unblocking: "Unblock",
      resetChangeCount: "Reset deregister count",
      reallyDeleteUser: "Delete account for this user?",
      deleteUserComment1:
        "* All user records will be permanently removed upon deletion of an account.",
      deleteUserComment2:
        "* Cash / ticket transaction history will be kept for N months.",
      deleteUserComment3:
        "* Unable to reregister with the same account within 7 days after deletion of an account.",
      searchResultDoesNotExist: "No search results",
      pleaseSelectFilter: "Please select search filter",
      blockServiceLogin: "Block login",
      blockReason: "Reason for blocking",
      blockServiceLoginComment1: "Block login for this user?",
      blockServiceLoginComment2:
        "(user will be permanently blocked from using our service until unblocked)",
      writeShortOtherReason: "Briefly write a reason for blocking.",
      toBlock: "Block",
      unblockServiceLogin: "Unblock login",
      unblockServiceLoginComment: "Unblock login for this user?",
      resetChangeCountComment1: "Reset device deregister count for this user?",
      resetChangeCountComment2: "(Reset deregister count to 0)",
      notApplicable: "N/A",
      blockReasonChoice: "Select reason for blocking",
      count: "/ {count} time(s)",
      memberSearchHint: "Access user information and activity history.",
      withinDays: " (within {days}day(s))",
      blocking: "Blocked",
      none: "None",
      memberSearchGuideContext1: "Enter a single value in the field to search.",
      memberSearchGuideContext2:
        "Search is not possible with more than one value in the field.",
      oneExceedInputText: "Please search in a single field.",
      noneInputText: "Enter search value.",
      searchTypeHints: {
        USER_ID_HINT: "Enter user's unique user ID granted by Kakao Webtoon.",
        ACCOUNT_ID_HINT: "Enter user's unique user ID granted by Kakao.",
        DAUM_ID_HINT: "Enter Daum ID.",
        DAUM_USER_ID_HINT: "Enter user ID, the identification key for Daum ID.",
        APP_USER_ID_HINT:
          "Enter the account number, which can be found in Side menu > 'My Info'",
        KOR_EMAIL_HINT: "Enter your Kakao account address in email format.",
        GLO_EMAIL_HINT: "Enter user’s email address used for sign-up.",
        CS_ID_HINT:
          "Enter the account number, which can be found in Side menu > 'My Info'",
      },
      choices: {
        USER_ID: "user_id",
        ACCOUNT_ID: "Account ID (google, facebook, etc.)",
        DAUM_ID: "Daum ID",
        DAUM_USER_ID: "Daum User ID",
        APP_USER_ID: "Account number(Kakao App User ID)",
        EMAIL: "Email",
        PHONE_NUMBER: "Phone number",
        NICKNAME: "Nickname",
        CS_ID: "Account number",
        BLOCKING_HISTORY: "Account block history",
        TICKET_PURCHASE: "Ticket purchase history",
        LOGIN_DEVICE: "Devices Logged in",
        ACCOUNT_ABUSER: "Sign up, login abuse",
        REFUND_CACHE_AFTER_USE: "Cash refund abuse",
        USE_OF_BAD_WORDS: "Use of nickname and comments that violate policy",
        MALFEASANCE: "Malfeasance",
        OTHER: "Other",
        cash: "Cash",
        welcomeGift: "Newbie benefit",
        waitForFree: "Wait or pay",
        gift: "Gift",
        package: "Package",
        single: "Single",
        rental: "Rent",
        possession: "Possess",
      },
      blockingHistoryDetail: {
        classification: "Classification",
        blockingDetail: "Details on account block",
        blockingStartDate: "Blocked date",
        blockingEndDate: "Unblocked date",
        currentStatus: "Current status",
        commentBlockHistory: "Comment block history",
        serviceLoginBlockHistory: "Login block history",
        userCommentSearch: "Search user's comments",
        BAN: "Blocked",
        UNBAN: "Unblock",
        BAN_END: "Unblocked",
        commentBlockingDetail: "Comment block step {banStep} ({isAutoBan})",
        auto: "Automatic",
        manual: "Manual",
        infinity: "Block permanently",
      },
      ticketPurchaseDetail: {
        payCode: "BI number",
        purchaseNumber: "Purchase number",
        contentInformation: "Content info",
        purchaseMethod: "Purchase method",
        purchaseInformation: "Purchase info",
        purchaseChargedDateTime: "Purchase date & time",
        ticketType: "Ticket type",
        totalPaymentAmount: "Total amount paid",
        realCash: "Real cash",
        remainEventCash: "Valid event cash",
        expiredEventCash: "Expired event cash",
        ticketPurchaseInformation: "Ticket purchase info",
        totalTicket: "Total ticket(s) provided",
        bonusTicket: "Bonus ticket(s)",
        totalUsedTicket: "Total used ticket(s)",
        unusedChargedTicket: "Unused paid ticket(s)",
        unitPricePerPurchase: "Price per ticket",
        refundableCash: "Refundable cash",
        purchaseCancel: "Cancel purchase",
        refund: "Refund",
        total: "Total ",
        cash: " Cash",
        number: " ",
        purchaseCancelComment: "Are you sure you want to cancel purchase?",
        searchEachContent: "Search content",
        searchPurchaseId: "Search purchase number",
      },
      loginDevicesDetail: {
        loginDeviceInformation: "Logged in device info",
        platformInformation: "Platform info",
        lastLogin: "Last login date & time",
        loginResult: "Login result",
        loginDeviceInformationHint:
          "Select device under 'Logged-in Device Info' to view login history.",
      },
      deviceLoginDetail: {
        result: " Search results for device",
        loginDate: "Login date",
        userId: "user_id",
        userStatus: "Login blocked status",
        blockingManagement: "Account block management",
      },
    },
    serviceBlock: {
      serviceLoginBlockUserManagement: "Blocked user management",
      userId: "user_id",
      blockDetail: "Reason for blocking",
      blockStartDt: "Blocked date",
      blockEndDt: "Unblocked date",
      blockStatus: "Block status",
      unblock: "Unblock",
      search: "Search",
      block: "Blocked",
      choices: {
        BLOCKED: "Blocked",
        NOT_BLOCKED: "Unblock",
      },
      serviceLoginBlockUserManagementHint: "Manage blocked users",
    },
    nicknameProhibition: {
      nicknameProhibitionRegistration: "Register prohibited words for nickname",
      keyword: "Keyword(s)",
      createdDt: "Date created",
      delete: "Delete",
      addNicknameProhibition: "Add prohibited words for nickname",
      inputNicknameProhibition: "Enter prohibited words for nickname",
      pleaseInputNicknameProhibition:
        "Please enter prohibited words for nickname.",
      nicknameProhibitionRegistrationHint:
        "Register and manage prohibited words for nickname.",
    },
    choices: {
      ACCOUNT_ABUSER: "Sign up, login abuse",
      REFUND_CACHE_AFTER_USE: "Cash refund abuse",
      USE_OF_BAD_WORDS: "Use of nickname / comments that violate policy.",
      MALFEASANCE: "Malfeasance",
      OTHER: "Other",
      OK: "Normal",
      UNREGISTERED: "Account deleted",
      PHONE_NUMBER_STOLEN: "duplicate phone numbers",
      BLOCKED_BY_SECURITY: "security breach",
      BLOCKED_BY_MAINTENANCE: "operations",
      EMAIL_UNVERIFIED: "Email not authenticated",
      OUT_DATED_TERM_OF_USE: "Out dated term",
      NEED_TO_ACCEPT_TERM_OF_USE: "need term",
      READY: "Ready to use daum migration user",
      ZOMBIE: "Unused daum migration user",
    },
  },
  ko: {
    search: {
      memberSearch: "회원조회",
      searchFilter: "조회필터",
      inputText: "텍스트 입력",
      search: "조회",
      searchResult: "회원조회 결과",
      UID: "user_id",
      accountId: "kakao account_id",
      csId: "회원번호",
      daumId: "daum id",
      daumUserId: "daum user_id",
      migrated: "양수도 여부",
      migratedMember: "양수도 회원",
      newMember: "신규회원",
      email: "이메일 주소",
      nickname: "닉네임",
      linkedSNS: "연동 SNS",
      signUpDate: "카카오웹툰 가입일시",
      memberStatus: "회원상태값",
      blockCommentStatus: "댓글 차단 여부",
      blockServiceLoginStatus: "서비스 로그인 차단 여부",
      loginLastDate: "마지막 로그인 일시",
      loginLastPath: "마지막 로그인 경로",
      deviceLoginInform: "가입/로그인 기기 정보",
      deviceDownloadInform: "다운로드 기기 정보",
      deviceRemoveCount: "다운로드 기기 해제횟수",
      region: "리전 / 국가",
      deleteAccount: "탈퇴처리",
      goToCommentMenu: "댓글 메뉴로 이동",
      block: "차단",
      unblocking: "차단해제",
      resetChangeCount: "해제 횟수 리셋",
      reallyDeleteUser: "해당 유저를 탈퇴처리하시겠습니까?",
      deleteUserComment1:
        "* 모든 개인정보, 잔액캐시 소멸, 서비스 이력이 즉시 삭제됩니다.",
      deleteUserComment2: "* 캐시 및 이용권 거래 내역은 N개월간 보관됩니다.",
      deleteUserComment3: "* 같은 소셜계정으로 재가입은 7일간 불가합니다.",
      searchResultDoesNotExist: "조회 결과가 없습니다.",
      pleaseSelectFilter: "조회필터를 선택해주세요.",
      blockServiceLogin: "서비스 로그인 차단",
      blockReason: "차단 사유",
      blockServiceLoginComment1:
        "해당 유저에 대해서 서비스 로그인을 차단하시겠습니까?",
      blockServiceLoginComment2:
        "(해제할 때까지 영구히 서비스 이용이 차단됩니다.)",
      writeShortOtherReason: "기타 차단 사유를 짧게 작성합니다.",
      toBlock: "차단하기",
      unblockServiceLogin: "서비스 로그인 차단 해제",
      unblockServiceLoginComment:
        "해당 유저의 서비스 로그인 차단을 해제하시겠습니까?",
      resetChangeCountComment1:
        "해당 유저의 다운로드 기기 해제횟수를 리셋하시겠습니까?",
      resetChangeCountComment2: "(해제 카운트를 다시 0으로 변경합니다.)",
      notApplicable: "해당없음",
      blockReasonChoice: "차단사유를 선택해주세요.",
      count: " / {count}회",
      withinDays: " ({days}일 이내)",
      memberSearchHint: "회원의 상세 정보 및 활동 이력을 조회합니다.",
      blocking: "차단중",
      none: "없음",
      memberSearchGuideContext1:
        "필드에 1개의 조회 값만 입력하여 조회해주세요.",
      memberSearchGuideContext2:
        "필드에 2개 이상의 값이 입력되어 있을 경우, 조회가 불가합니다.",
      oneExceedInputText: "1개의 필드에서만 조회해주세요.",
      noneInputText: "조회 값을 입력해주세요.",
      searchTypeHints: {
        USER_ID_HINT:
          "카카오웹툰에서 부여한 사용자의 고유 User id를 입력합니다.",
        ACCOUNT_ID_HINT:
          "카카오에서 부여한 사용자의 고유 User id를 입력합니다.",
        DAUM_ID_HINT: "다음 아이디를 입력합니다.",
        DAUM_USER_ID_HINT: "기존 다음 아이디의 식별키인 User id를 입력합니다.",
        APP_USER_ID_HINT:
          "앱/웹 사이드메뉴 > '내 정보'에서 확인되는 회원번호를 입력합니다.",
        KOR_EMAIL_HINT: "이메일 형태의 카카오 계정 주소를 입력합니다.",
        GLO_EMAIL_HINT: "유저가 가입 시 사용한 이메일 주소를 입력합니다.",
        CS_ID_HINT:
          "앱/웹 사이드메뉴 > '내 정보'에서 확인되는 회원번호를 입력합니다.",
      },
      choices: {
        USER_ID: "user_id",
        ACCOUNT_ID: "Kakao Account ID",
        DAUM_ID: "Daum ID",
        DAUM_USER_ID: "Daum User ID",
        APP_USER_ID: "회원번호(Kakao App User ID)",
        EMAIL: "이메일",
        PHONE_NUMBER: "핸드폰 번호",
        NICKNAME: "닉네임",
        CS_ID: "회원번호",
        BLOCKING_HISTORY: "차단 이력 상세",
        TICKET_PURCHASE: "이용권 구매내역 상세",
        LOGIN_DEVICE: "로그인 기기 상세",
        ACCOUNT_ABUSER: "가입, 로그인 어뷰저",
        REFUND_CACHE_AFTER_USE: "캐시 환불 어뷰저",
        USE_OF_BAD_WORDS: "정책에 위배되는 댓글, 닉네임 사용",
        MALFEASANCE: "불법 행위",
        OTHER: "기타",
        cash: "캐시",
        welcomeGift: "신규혜택",
        waitForFree: "기다무",
        gift: "이벤트 선물",
        package: "패키지",
        single: "단일",
        rental: "대여",
        possession: "소장",
      },
      blockingHistoryDetail: {
        classification: "분류",
        blockingDetail: "차단 상세",
        blockingStartDate: "차단 시작일",
        blockingEndDate: "차단 종료일",
        currentStatus: "현재상태",
        commentBlockHistory: "댓글 차단이력",
        serviceLoginBlockHistory: "서비스 로그인 차단이력",
        userCommentSearch: "유저 댓글 조회",
        BAN: "차단중",
        UNBAN: "차단해제",
        BAN_END: "차단종료",
        commentBlockingDetail: "댓글 차단 {banStep}단계 ({isAutoBan})",
        auto: "자동",
        manual: "수동",
        infinity: "영구차단",
      },
      ticketPurchaseDetail: {
        payCode: "BI번호",
        purchaseNumber: "구매번호",
        contentInformation: "작품정보",
        purchaseMethod: "구매수단",
        purchaseInformation: "구매정보",
        purchaseChargedDateTime: "구매일시",
        ticketType: "이용권 타입",
        totalPaymentAmount: "지불 총액 구성",
        realCash: "리얼 캐시",
        remainEventCash: "유효 이벤트 캐시",
        expiredEventCash: "만료 이벤트 캐시",
        ticketPurchaseInformation: "이용권 구매 정보",
        totalTicket: "총 지급 이용권",
        bonusTicket: "보너스 이용권",
        totalUsedTicket: "총 사용 이용권",
        unusedChargedTicket: "미사용 유료 이용권",
        unitPricePerPurchase: "구매 시 장당 단가",
        refundableCash: "환불가능캐시",
        purchaseCancel: "구매취소",
        refund: "환불",
        total: "총 ",
        cash: " 캐시",
        number: " 개",
        purchaseCancelComment: "정말로 구매취소를 하시겠습니까?",
        searchEachContent: "작품별 조회",
        searchPurchaseId: "구매번호 조회",
      },
      loginDevicesDetail: {
        loginDeviceInformation: "로그인 기기 정보",
        platformInformation: "플랫폼 정보",
        lastLogin: "마지막 로그인",
        loginResult: "로그인 결과",
        loginDeviceInformationHint:
          "로그인 기기 정보 선택 시, 하단에 해당 기기에서 발생한 로그인 이력이 노출됩니다.",
      },
      deviceLoginDetail: {
        result: " 기기 조회 결과",
        loginDate: "로그인일시",
        userId: "user_id",
        userStatus: "서비스 로그인 차단상태",
        blockingManagement: "차단 관리",
      },
    },
    serviceBlock: {
      serviceLoginBlockUserManagement: "서비스 로그인 차단 유저 관리",
      userId: "user_id",
      blockDetail: "차단 사유",
      blockStartDt: "차단 시작 일자",
      blockEndDt: "차단 해제 일자",
      blockStatus: "차단상태",
      unblock: "차단해제",
      search: "조회",
      block: "차단중",
      choices: {
        BLOCKED: "차단중",
        NOT_BLOCKED: "차단해제",
      },
      serviceLoginBlockUserManagementHint:
        "서비스 로그인 차단 유저를 관리합니다.",
    },
    nicknameProhibition: {
      nicknameProhibitionRegistration: "닉네임 금칙어 등록",
      keyword: "키워드",
      createdDt: "등록 일자",
      delete: "삭제",
      addNicknameProhibition: "닉네임 금칙어 추가",
      inputNicknameProhibition: "닉네임 금칙어 입력",
      pleaseInputNicknameProhibition: "닉네임 금칙어를 입력해주세요.",
      nicknameProhibitionRegistrationHint:
        "닉네임 금칙어를 등록 및 관리합니다.",
    },
    choices: {
      ACCOUNT_ABUSER: "가입, 로그인 어뷰저",
      REFUND_CACHE_AFTER_USE: "캐시 환불 어뷰저",
      USE_OF_BAD_WORDS: "정책에 위배되는 댓글, 닉네임 사용",
      MALFEASANCE: "불법 행위",
      OTHER: "기타",
      OK: "정상",
      UNREGISTERED: "탈퇴",
      PHONE_NUMBER_STOLEN: "전화번호 뺏김 유저",
      BLOCKED_BY_SECURITY: "보안 차단",
      BLOCKED_BY_MAINTENANCE: "운영자 차단",
      EMAIL_UNVERIFIED: "이메일 미인증",
      OUT_DATED_TERM_OF_USE: "동의한 약관 버전 지남",
      NEED_TO_ACCEPT_TERM_OF_USE: "약관 동의 필요",
      READY: "사용준비된 다음 마이그레이션 사용자",
      ZOMBIE: "더이상 사용되지않는 다음 마이그레이션 사용자",
    },
  },
});
