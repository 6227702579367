export default Object.freeze({
  en: {
    tabs: {
      content: "Content info",
      media: "Media settings",
      bm: "BM settings",
      episode: "Episode management",
      notice: "Notice",
      eventBanner: "Event Banner",
      contentHomeRec: "Content home recommendations",
      statusChange: "Change content status",
    },
    ageLimit: {
      ageLimit: "Age rating",
      all: "All",
      adult: "Adult",
    },
    content: {
      id: "Content ID",
      title: "Content title",
      ipKey: "Common content ID",
      cp: "CP (Contents Provider)",
      cpId: "CP ID",
      cpName: "CP name",
      synopsis: "Summary",
      catchphraseThreeLines: "Introduction (3 lines)",
      catchphraseTwoLines: "Introduction (2 lines)",
      copyright: "Copyright",
      seoId: "SEO ID",
      seoIdDescription: "Search optimization ID. Local language available.",
      status: "Status",
      substatus: "Substatus",
      ongoingStatus: "Serial Status",
      delayEarlyAccessEndDateTime: "Recalculate early access",
      delayEarlyAccessResult: "Preview calculated results",
      delayEarlyAccessEpisodeNum: "Delay start episode",
      delayEarlyAccessCount: "Number of times to delay",
      episodeOrder: "Episode order",
      episodeTitle: "Episode name",
      existingFreeStartDate: "Previous free-of-charge date",
      delayFreeStartDate: "Free-of-charge date after recalculation",
      BM: "BM",
      titleKorean: "Korean title (for admin)",
      channel: "Channel (original)",
      genre: "Genre",
      webtoonType: "Webtoon type",
      fromKakaopage: "This content is the Kakaopage sourcing.",
      original: "KakaoWebtoon Original",
      serialFrequency: "Release frequency",
      serialWeekdays: "Day(s) of release",
      ageLimit: "Age rating",
      seoKeywords: "Related search",
      thumbnailA: "Primary thumbnail A",
      thumbnailB: "Primary thumbnail B",
      language: "Language",
      author: "Author",
      authorType: "Author type",
      emotion1: "Emotion 1",
      emotion2: "Emotion 2",
      emotion3: "Emotion 3",
      emotionRule: "Emotion (at least 2)",
      contentHomeBannerDisplayable: "Display content home banner",
      viewerEventBannerDisplayable: "Display viewer event banner",
      serialStartDateTime: "Series start date",
      serialRestartDateTime: "Series restart date",
      completedDateTime: "Series end date",
      seoIdRule: "Search optimization ID. Local language available",
      preOrderRule: "At least 1 content must be on sale",
      seoKeywordsRule:
        "User searching related terms will result in display of the content.",
      comicContentManagementHint: "Enter and manage content information.",
      comicContentManagement: "Content management",
      contentId: "Content ID",
      thumbnailUri: "Content thumbnail",
      publicationDate: "Sales start date",
      episodeCnt: "Number of episode(s)",
      newContent: "Add content",
      sendManuscriptTool: "송고툴",
      statisticsDisplayable: "statistics displayable",
      paidSalesDisplayable: "paid sales displayable",
      waitForFree: {
        plusEnabled: "WaitForFree+",
      },
      viewerSetting: {
        label: "Viewer settings",
        scrollView: "Scroll",
        turningPageView: "Page",
        defaultView: "Default viewer",
        turningPageDirection: "Swipe direction",
        turningPageDirectionForward: "Forward",
        turningPageDirectionReverse: "Reverse",
        hasMargin: "with image margin",
        noMargin: "without image margin",
      },
      changeStatus: "Change status",
      choices: {
        CONTENT_STATUS: {
          BEING_PUBLISHED: "Unissued",
          SELLING: "On sale",
          STOP_SELLING: "Temporarily Unavailable ",
          PROHIBITED: "Unavailable",
        },
        CONTENT_SUBSTATUS: {
          DRAFT: "Draft",
          ABLE_TO_PRE_ORDER: "Available for pre-sale",
          PUBLISHABLE: "Available for sale",
        },
        CONTENT_BM: {
          charged: "Paid",
          preview: "Early access",
          waitFree: "Wait or pay",
          waitFreeFix: "Wait or pay (fixed)",
          reSerial: "Rerun",
        },
        CONTENT_AUTHOR_TYPE: {
          AUTHOR_ILLUSTRATOR: "Author / illustrator",
          AUTHOR: "Author",
          ILLUSTRATOR: "Illustrator",
          ORIGINAL_STORY: "Original story",
          ADAPTATION: "Adaptation",
          COLOR: "Color",
          PUBLISHER: "Publisher",
        },
        CONTENT_CHANNEL: {
          ONE: "Webtoon",
          TWO: "Novel",
        },
        WEBTOON_TYPE: {
          GENERAL: "General",
          BRAND: "Brand",
        },
        WEEKDAY: {
          MONDAY: "Mon",
          TUESDAY: "Tue",
          WEDNESDAY: "Wed",
          THURSDAY: "Thu",
          FRIDAY: "Fri",
          SATURDAY: "Sat",
          SUNDAY: "Sun",
        },
        ONGOING_STATUS: {
          N_A: "N/A",
          EPISODES_PUBLISHING: "Ongoing",
          EPISODES_NOT_PUBLISHING: "Hiatus",
          END_OF_SEASON: "Season complete (short-term)",
          SEASON_COMPLETED: "Season complete (long-term)",
          COMPLETED: "Completed",
        },
        EXTERNAL_VIDEO_FROM: {
          KAKAO_TV: "Kakao TV",
          YOUTUBE: "Youtube",
        },
        VIEWER_DEFAULT_VIEW: {
          SCROLL: "Scroll",
          TURNING_PAGE: "Page (swipe)",
        },
      },
    },
    episode: {
      id: "ID",
      order: "Order",
      title: "Title",
      status: "Status",
      substatus: "Status detail",
      delete: "Delete",
      update: "Edit",
      season: "Season",
      thumbnail: "Thumbnail",
      seoId: "SEO ID",
      seoIdDescription: "Search optimization ID. Local language available.",
      new: "Add episode",
      publicationDateTime: "Date of issue",
      serialStartDateTime: "Series start date",
      releasedForFreeDateTime: "Free-of-charge date",
      messageFromAuthor: "Author's note",
      messageFromAuthorDisplayable: "Display author's note",
      ageLimit: "Age rating",
      isbn: "Tax free code",
      updatePushEnabled: "Update push",
      version: "Version",
      episodeBM: "Episode BM",
      preview: "Preview",
      previewDelay:
        "It may take 1~2 minutes to apply the preview after uploading the image",
      changeOrder: "Change order",
      changeOrderMsgPrefix: "Selected episode: change ep.{order} to",
      changeOrderMsgSuffix: " .",
      videoLocation: "Video location",
      imageFile: "Image file",
      changeStatus: "Change status",
      bgm: "BGM",
      choices: {
        EPISODE_STATUS: {
          BEING_PUBLISHED: "Unissued",
          SELLING: "On sale",
          STOP_SELLING: "Temporarily Unavailable",
          PROHIBITED: "Unavailable",
        },
        EPISODE_SUBSTATUS: {
          DRAFT: "Draft",
          REVIEWABLE: "Preview available",
          PUBLISHABLE: "Available for sale",
        },
        VIDEO_LOCATION: {
          TOP: "Top",
          BOTTOM: "Bottom",
        },
      },
      freeStatus: "Free status",
      freeEpisode: "Free episode",
      episodeBMDetails: "Episode BM details",
      revision: "Revision",
      revisionHint:
        "Images of on sale episodes can be modified through revision.",
      approvePreview: "Approve preview ",
      approveRevision: "Approve revision",
      createRevision: "Create revision",
      createRevisionConfirm: "Create revision?",
      existRevision:
        "Revision for this version already exists. Modification can only be made in revisions.",
      stopSelling: "Discontinued episodes cannot be edited",
      merge: "Merge episodes",
      mergeHint: "Preview approval is required.",
      noPreviewAvailable: "No preview available",
      free: "Free",
      notFree: "Free X",
      setReleasedForFreeDateTime: "Set Free-of-charge date",
      reservedReleasedForFreeDateTime: "Reserved Free-of-charge date",
      changeReleasedForFreeDateTime: "Changed Free-of-charge date",
      nextReleasedForFreeDateTime: "Next Free-of-charge date",
      getEpisodeImage: "Get episode image",
      kakaoPageEpisodeIDInput: "KakaoPage episode id input",
      search: "Search",
      thumbnailDownload: "Thumbnail download",
      getImage: "Get image",
      createAccessToken: "Access token create",
      reCreateAccessToken: "Access token re-create",
      reSearch: "re-search",
      setReleasedForFreeDateTimeGuideComment:
        "* If the released for free date time is without being entered, it will be switched free in early access BM.",
      applied: " applied",
    },
    author: {
      newAuthor: "Add author",
      id: "ID",
      name: "Pen name",
      description: "Description / note",
      authorManagement: "Author management",
      authorId: "Author ID",
      pseudonym: "Pen name",
      updated: "Modified date",
      updatedBy: "Modified by",
      delete: "Delete",
      contentId: "Content ID",
      thumbnail: "Thumbnail",
      title: "Title",
      contentNum: "Number of contents by the author",
      descriptionRule: "Enter for internal reference.",
      authorManagementHint: "Enter and manage author information.",
    },
    cp: {
      name: "CP name",
      type: "CP type",
      business: "Individual / corporation",
      tax: "Tax free status",
      choices: {
        CONTENT_PROVIDER_TYPE: {
          CP: "CP",
          AUTHOR: "Author",
          PUBLISHER: "Publisher",
          COUPON_CP: "Coupon CP",
          NONE: "N/A",
        },
        CONTENT_PROVIDER_BUSINESS_TYPE: {
          INDIVIDUAL: "Individual",
          CORPORATE: "Corporation",
          NONE: "N/A",
        },
        CONTENT_PROVIDER_TAXATION_TYPE: {
          FREE: "Tax free",
          TAXATION: "Taxation",
          NONE: "N/A",
        },
      },
      cpManagement: "CP Management",
      id: "ID",
      cpManagementHint: "Enter and manage CP information.",
    },
    metadataItem: {
      order: "Order",
      name: "Name",
      description: "Description / note",
      delete: "Delete",
      new: "Add metadata item",
      METADATA_TYPE: {
        MAIN_GENRE: "Genre",
        SUB_GENRE: "Subgenre",
        EMOTION: "Emotion",
        STORY_ELEMENT: "Summary",
        MATERIAL: "Material",
        DRAWING_STYLE: "Drawing style",
      },
      metaDataItemTitleHint:
        "Enter and manage information for content keywords and recommendations.",
    },
    media: {
      featuredCharacterAnimation:
        "Primary thumbnail video A (recommendations tab)",
      featuredCharacterAnimationFirstFrame: "Video A first frame",
      featuredCharacterAnimationLastFrame: "Video A last frame",
      featuredCharacterImageA:
        "Primary thumbnail image A (recommendations tab)",
      featuredCharacterSearchThumbnailImageA:
        "Primary thumbnail image A (search)",
      decorationImage: "Decoration Image (recommendations tab)",
      featuredCharacterImageB: "Primary thumbnail (timetable)",
      titleImageA: "Title A (recommendations tab)",
      titleImageB: "Title B (timetable)",
      backgroundImage: "Background image (common)",
      backgroundColor: "Background color (common)",
      backgroundSearchThumbnailImage: "Backgound thumbnail image (search page)",
      lookThroughImages: "Quick view (timetable)",
      lookThroughImage: "Quick view - merge",
      anchorClip: "Anchor Type",
      anchorClipVideo: "Anchor video",
      anchorClipFirstFrame: "Anchor ALIVE first frame",
      anchorClipLastFrame: "Anchor ALIVE last frame",
      ipPromotionVideo: "IP video",
      ipPromotionVideoTitle: "IP video title",
      ipPromotionVideoSubtitle: "IP video subtitle",
      ipPromotionVideoA: "IP video A",
      ipPromotionVideoAToolTip:
        "750x1100px (mobile resolutions) registered video will be displayed on ranking.",
      ipPromotionVideoB: "IP video B",
      ipPromotionVideoBToolTip:
        "1920x1080px (tablet resolutions) registered video will be displayed on ranking.",
      videoClips: "Content info video",
      thumbnailImage: "Universe thumbnail image",
      sharingThumbnailImage: "Share thumbnail image",
      contentInformationVideoRegister: "Register content info video",
      contentInformationVideoFormClearToolTip:
        "Clears existing information entered for IP video.",
      linkToKakaopageOriginalNovel: "Link to kakaopage original novel",
      linkToKakaopageOriginalNovelToolTip:
        "The original novel of kakaopage is exposed in the content information and viewer. However, it is only exposed if the original novel of kakaopage status is selling.",
      kakaopageContentId: "Kakaopage Content ID",
      originalNovelThumbnail: "Original novel thumbnail",
      kakaopageContentNotSellingComment:
        "Content are not selling cannot be linked. Please check out the content.",
      kakaopageContentNoMatchedComment:
        "No matches found. Please check the ID of the kakaopage.",
      contentTitle: "Content title: ",
      emptyOriginalNovelThumbnail: "Original novel thumbnail is empty.",
      emptyKakaopageContentId: "Kakaopage content ID is empty.",
      choices: {
        ANCHOR_CLIP: {
          ALIVE: "ALIVE",
          LOOP: "Infinite loop",
        },
      },
    },
    bm: {
      productType: "Assign product type",
      choices: {
        PAY: "Paid",
        EARLY_ACCESS: "Early Access",
        WAIT_FOR_FREE: "Wait For Free",
        WAIT_FOR_FREE_FIX: "Wait For Free (fixed)",
        RERUN_SERIES: "Rerun",
        PAY_AND_EARLY_ACCESS: "Paid + Early Access",
        WAIT_FOR_FREE_AND_EARLY_ACCESS: "Wait For Free + Early Access",
        PAY_AND_WAIT_FOR_FREE_FIX: "Paid + Wait For Free (fixed)",
        WAIT_FOR_FREE_AND_WAIT_FOR_FREE_FIX:
          "Wait For Free + Wait For Free (fixed)",
        FREE: "Free",
        button: {
          BEFORE_CHANGE: "Edit button",
          ONGOING_CHANGE: "Edit mode",
          CANCEL_RESERVATION: "Cancel reservation",
        },
      },
      singleType: "Single type",
      combinationType: "Combination type",
      day: "Day(s)",
      hour: "Hour(s)",
      minute: "Minute(s)",
      waitfreePeriod: "Recharge period of wait or pay ticket",
      BlockedWaitfreeEpisodes: "Recent number of episodes for read restriction",
      BlockedWaitfreeEpisodesRule: "Recently updated N episode(s)",
      waitfreeEpisodes: "Number of Wait or pay episode(s)",
      waitfreeEpisodesRule: "N wait or pay episode(s)",
      freeStartDate: "Free-of-charge start date",
      freeStartEpisode: "Free-of-charge start episode",
      freeStartEpisodeRule:
        "Free episodes from ep.N (all prior episodes are free)",
      freeEpisodesNum: "Number of free episode(s)",
      freeEpisodesNumRule:
        "N free episodes (exceeding this number will lock the latest episode).",
      ticketTypeSetting: "Ticket type settings",
      possessionTicket: "Possession ticket",
      rentalTicket: "Rental ticket",
      ticketPriceSetting: "Ticket price settings",
      applyImmediately: "Apply immediately",
      applyReservation: "Apply reservation",
      cancel: "Cancel",
      save: "Save",
      waitForFreeIntervalUpdate: "Interval update",
      toChange: "to be the result of change",
      singleSelling: "Individual sales",
      packageSelling: "Promotional sales",
      oneEpisodePrice: "Price per episode",
      standardTicketNum: "Base number of tickets",
      additionalTicketNum: "Number of bonus tickets",
      welcomeBenefitTickets: "Newbie benefit",
      welcomeBenefitTicketsRule: "Provide N ticket(s) to new users",
      ticketPriceSave: "Save price",
      welcomeBenefitTicketsSave: "Save newbie benefit",
      sameBMSetting: "Please set BM different than previous entry.",
      weekdaysSetting: "Select day(s) of the week for release.",
      samePriceSetting: "Please set a price different than previous entry.",
      doesNotExist: "The value does not exist.",
      setPriceFirst: "Please enter price first.",
      canNotSetTicketCountZero: "Ticket count cannot be set to zero.",
      deleteTicketType: "Are you sure you want to delete?",
      setAfterCurrentTime: "Please set future time.",
      earlyAccessOption: "미리보기 옵션",
      earlyAccessBMStartEpisodeNumber: "미리보기 시작회차",
      waitForFreeFixBMStartEpisodeNumber: "기다무(고정) 시작 회차",
      payRange: "유료 범위",
      earlyAccessRange: "미리보기 범위",
      waitForFreeRange: "기다무 범위",
      waitForFreeFixRange: "기다무(고정) 범위",
      waitForFreeOption: "기다무 옵션",
      waitForFreeFixOption: "기다무(고정) 옵션",
      commonOption: "공통 옵션",
      maxFreeEpisodeCountNone: "무료 회차수 없음",
      maxFreeEpisodeCountNoneComment:
        "무료 회차수 무시하고 오픈 된 무료 회차는 모두 무료(미리보기와 같이 동작)",
    },
    notice: {
      listPageTitle: "Content notice list",
      newPageTitle: "Create content notice",
      editPageTitle: "Edit content notice",
      viewPageTitle: "View content notice",
    },
    universe: {
      saveCurrentOrders: "Save current order",
    },
    immediately: "Immediately",
    reservation: "Reserve",
    reservationDateTime: "Date & time of reservation",
    reservationHint: "Reservations are available in 5-minute increments.",
    changeImmediately: "Change status now?",
    changeReservation: "Reserve status change?",
    selling: "On sale",
  },
  ko: {
    tabs: {
      content: "작품 정보",
      media: "미디어 설정",
      bm: "BM 설정",
      episode: "회차 관리",
      notice: "공지사항",
      eventBanner: "이벤트 배너",
      contentHomeRec: "작품 홈 추천",
      statusChange: "작품 상태 변경",
    },
    ageLimit: {
      ageLimit: "이용등급",
      all: "전체",
      adult: "성인",
    },
    content: {
      id: "작품 ID",
      title: "작품 제목",
      ipKey: "통합 작품 ID",
      cp: "CP (Contents Provider)",
      cpId: "CP ID",
      cpName: "CP 이름",
      synopsis: "줄거리",
      catchphraseThreeLines: "소개 (3줄)",
      catchphraseTwoLines: "소개 (2줄)",
      copyright: "저작권",
      seoId: "SEO ID (검색 친화 URL)",
      seoIdDescription:
        "검색 최적화 ID. 입력하지 않을 경우 작품 이름으로 자동 생성됩니다.",
      status: "상태",
      substatus: "서브상태",
      ongoingStatus: "연재상태",
      delayEarlyAccessEndDateTime: "미리보기 재계산",
      delayEarlyAccessResult: "계산결과 미리보기",
      delayEarlyAccessEpisodeNum: "미루고자 하는 회차 순서",
      delayEarlyAccessCount: "미루고자 하는 횟수(전체 횟수 기준)",
      episodeOrder: "회차 순서",
      episodeTitle: "회차 이름",
      existingFreeStartDate: "기존 무료전환일시",
      delayFreeStartDate: "재계산 후 무료전환일시",
      BM: "BM",
      titleKorean: "한국어 제목 (운영자 확인용)",
      channel: "Channel 구분(원작)",
      genre: "장르",
      webtoonType: "웹툰 타입",
      fromKakaopage: "해당 작품은 카카오 페이지 소싱입니다",
      original: "카카오웹툰오리지널(독점) 여부",
      serialFrequency: "연재 주기",
      serialWeekdays: "연재 요일",
      ageLimit: "연령",
      seoKeywords: "검색 유의어",
      thumbnailA: "대표 썸네일A",
      thumbnailB: "대표 썸네일B",
      language: "언어",
      author: "작가",
      authorType: "작가 타입",
      emotion1: "감정1",
      emotion2: "감정2",
      emotion3: "감정3",
      emotionRule: "감정(2개 이상)",
      contentHomeBannerDisplayable: "컨텐츠 홈 배너 노출",
      viewerEventBannerDisplayable: "뷰어 이벤트 배너 노출",
      serialStartDateTime: "연재 시작일",
      serialRestartDateTime: "연재 재시작일",
      completedDateTime: "연재 종료일",
      seoIdRule: "검색 최적화 ID. 현지어 사용 가능합니다",
      preOrderRule: "1개 이상의 회차가 판매 중이어야 함",
      seoKeywordsRule:
        "입력하신 유의어를 사용자가 검색할 경우 해당 작품이 노출됩니다.",
      comicContentManagementHint: "작품에 대한 정보를 입력하고 관리합니다.",
      comicContentManagement: "만화 작품 관리",
      contentId: "작품 ID",
      thumbnailUri: "작품 썸네일",
      publicationDate: "판매시작일",
      episodeCnt: "회차 개수",
      newContent: "작품 추가",
      sendManuscriptTool: "송고툴",
      statisticsDisplayable: "통계 노출",
      paidSalesDisplayable: "유료 매출 노출",
      waitForFree: {
        plusEnabled: "기다무+",
      },
      viewerSetting: {
        label: "뷰어 설정",
        scrollView: "스크롤",
        turningPageView: "페이지",
        defaultView: "기본 뷰어",
        turningPageDirection: "페이지 넘김 방향",
        turningPageDirectionForward: "정방향",
        turningPageDirectionReverse: "역방향",
        hasMargin: "이미지 간격 있음",
        noMargin: "이미지 간격 없음",
      },
      changeStatus: "상태 변경",
      choices: {
        CONTENT_STATUS: {
          BEING_PUBLISHED: "발행 전",
          SELLING: "판매 중",
          STOP_SELLING: "판매 중지",
          PROHIBITED: "판매 금지",
        },
        CONTENT_SUBSTATUS: {
          DRAFT: "초안",
          ABLE_TO_PRE_ORDER: "사전예약 가능",
          PUBLISHABLE: "발행 가능",
        },
        CONTENT_BM: {
          charged: "유료",
          preview: "미리보기",
          waitFree: "기다무",
          waitFreeFix: "기다무 고정",
          reSerial: "재연재",
        },
        CONTENT_AUTHOR_TYPE: {
          AUTHOR_ILLUSTRATOR: "글/그림",
          AUTHOR: "글",
          ILLUSTRATOR: "그림",
          ORIGINAL_STORY: "원작",
          ADAPTATION: "각색",
          COLOR: "컬러",
          PUBLISHER: "발행처",
        },
        CONTENT_CHANNEL: {
          ONE: "웹툰",
          TWO: "소설",
        },
        WEBTOON_TYPE: {
          GENERAL: "일반",
          BRAND: "브랜드",
        },
        WEEKDAY: {
          MONDAY: "월",
          TUESDAY: "화",
          WEDNESDAY: "수",
          THURSDAY: "목",
          FRIDAY: "금",
          SATURDAY: "토",
          SUNDAY: "일",
        },
        ONGOING_STATUS: {
          N_A: "해당없음",
          EPISODES_PUBLISHING: "연재 중",
          EPISODES_NOT_PUBLISHING: "휴재 중",
          END_OF_SEASON: "시즌 완결(단기)",
          SEASON_COMPLETED: "시즌 완결(장기)",
          COMPLETED: "완결",
        },
        EXTERNAL_VIDEO_FROM: {
          KAKAO_TV: "카카오 TV",
          YOUTUBE: "유튜브",
        },
        VIEWER_DEFAULT_VIEW: {
          SCROLL: "스크롤",
          TURNING_PAGE: "가로",
        },
      },
    },
    episode: {
      id: "ID",
      order: "순서",
      title: "제목",
      status: "상태",
      substatus: "상태 상세",
      delete: "삭제",
      update: "수정",
      season: "시즌",
      thumbnail: "썸네일",
      seoId: "SEO ID",
      seoIdDescription:
        "검색 최적화 ID. 입력하지 않을 경우 작품 이름과 회차 순서로 자동생성됩니다.",
      new: "에피소드 추가",
      publicationDateTime: "발행일",
      serialStartDateTime: "연재 시작일",
      releasedForFreeDateTime: "무료 전환일",
      messageFromAuthor: "작가의 말",
      messageFromAuthorDisplayable: "작가의 말 노출",
      ageLimit: "연령",
      isbn: "면세코드",
      updatePushEnabled: "업데이트 푸시",
      version: "버전",
      episodeBM: "회차BM",
      preview: "미리보기",
      previewDelay:
        "이미지 업로드 후 미리보기 적용까지 1~2분 가량 소요될 수 있습니다.",
      changeOrder: "순서변경",
      changeOrderMsgPrefix: "선택한 회차 : {order} 화를",
      changeOrderMsgSuffix: " 화로 변경",
      videoLocation: "비디오 위치",
      imageFile: "이미지 파일",
      changeStatus: "상태 변경",
      bgm: "bgm",
      choices: {
        EPISODE_STATUS: {
          BEING_PUBLISHED: "발행 전",
          SELLING: "판매 중",
          STOP_SELLING: "판매 중지",
          PROHIBITED: "판매 금지",
        },
        EPISODE_SUBSTATUS: {
          DRAFT: "초안",
          REVIEWABLE: "미리보기 가능",
          PUBLISHABLE: "발행 가능",
        },
        VIDEO_LOCATION: {
          TOP: "상단",
          BOTTOM: "하단",
        },
      },
      freeStatus: "무료여부",
      freeEpisode: "무료회차",
      episodeBMDetails: "회차 BM 상세",
      revision: "리비전",
      revisionHint: "판매중인 회차의 이미지는 리비전을 통해 수정이 가능합니다.",
      approvePreview: "미리보기 승인",
      approveRevision: "리비전 승인",
      createRevision: "리비전 생성",
      createRevisionConfirm: "리비전을 생성하시겠습니까?",
      existRevision:
        "해당 버전의 리비전이 존재합니다. 수정은 리비전에서만 가능합니다.",
      stopSelling: "판매 중지된 회차는 수정할 수 없습니다",
      merge: "회차 병합",
      mergeHint: "미리보기 승인이 필요합니다.",
      noPreviewAvailable: "미리보기 컨텐츠를 불러오지 못했습니다.",
      free: "무료",
      notFree: "무료 X",
      setReleasedForFreeDateTime: "설정할 무료전환일",
      reservedReleasedForFreeDateTime: "현재 설정되어있는 무료전환일",
      changeReleasedForFreeDateTime: "변경할 무료전환일",
      nextReleasedForFreeDateTime: "다음 무료전환일",
      getEpisodeImage: "회차 이미지 불러오기",
      kakaoPageEpisodeIDInput: "카카오페이지 회차 ID 입력",
      search: "조회",
      thumbnailDownload: "썸네일 다운로드",
      getImage: "이미지 불러오기",
      createAccessToken: "Access token 생성",
      reCreateAccessToken: "Access token 재생성",
      reSearch: "재조회",
      setReleasedForFreeDateTimeGuideComment:
        "* 무료전환일이 입력되지 않고 저장될 경우, 미리보기 BM에서 무료로 전환됩니다.",
      applied: " 적용 중",
    },
    author: {
      newAuthor: "작가 추가",
      id: "ID",
      name: "필명",
      description: "설명 및 비고",
      authorManagement: "작가 관리",
      authorId: "작가 ID",
      pseudonym: "필명",
      updated: "수정일",
      updatedBy: "수정자",
      delete: "삭제",
      contentId: "작품 ID",
      thumbnail: "썸네일",
      title: "제목",
      contentNum: "작가의 작품 개수",
      descriptionRule: "내부 참고용으로 기입해주세요.",
      authorManagementHint: "작가에 대한 정보를 입력하고 관리합니다.",
    },
    submissionEpisode: {
      submissionEpisodeManagement: "송고 회차 관리",
      submissionEpisodeManagementHint: "송고된 회차를 검수합니다.",
      submissionEpisodeApproveReject: "송고 회차 승인/반려",
      id: "송고 ID",
      thumbnail: "썸네일",
      webtoonTitle: "웹툰 제목",
      episodeTitle: "회차 제목",
      channel: "채널",
      week: "요일",
      submissionType: "요청 유형",
      submissionStatus: "요청 상태",
      submissionComment: "요청 메세지",
      approveRejectComment: "운영자 메세지",
      submissionDate: "요청일",
      messageFromAuthor: "작가의 말",
      originalThumbnail: "송고 원본 썸네일",
      webtoonEpisodeImages: "송고 원본 회차 이미지",
      viewWebtoonEpisodeImages: "원본 보기",
      download: "다운로드",
      downloadViewerImages: "회차 이미지 다운로드하기",
      episodeApproved: "승인되었습니다!",
      episodeRejected: "반려되었습니다!",
      episodeList: "회차 목록",
      choices: {
        SUBMISSION_TYPE: {
          CREATE: "생성",
          UPDATE: "수정",
        },
        SUBMISSION_STATUS: {
          SUBMITTED: "요청",
          APPROVED: "승인",
          REJECTED: "반려",
        },
      },
    },
    cp: {
      name: "CP 명",
      type: "CP 타입",
      business: "개인/법인",
      tax: "면세 여부",
      choices: {
        CONTENT_PROVIDER_TYPE: {
          CP: "CP",
          AUTHOR: "작가",
          PUBLISHER: "출판사",
          COUPON_CP: "쿠폰 CP",
          NONE: "해당 없음",
        },
        CONTENT_PROVIDER_BUSINESS_TYPE: {
          INDIVIDUAL: "개인",
          CORPORATE: "법인",
          NONE: "해당 없음",
        },
        CONTENT_PROVIDER_TAXATION_TYPE: {
          FREE: "면세",
          TAXATION: "과세",
          NONE: "해당 없음",
        },
      },
      cpManagement: "CP 관리",
      id: "ID",
      cpManagementHint: "CP에 대한 정보를 입력하고 관리합니다.",
    },
    metadataItem: {
      order: "순서",
      name: "이름",
      description: "설명 및 비고",
      delete: "삭제",
      new: "메타데이터 아이템 추가",
      METADATA_TYPE: {
        MAIN_GENRE: "장르",
        SUB_GENRE: "서브장르",
        EMOTION: "감정",
        STORY_ELEMENT: "줄거리 요소",
        MATERIAL: "소재",
        DRAWING_STYLE: "작화",
      },
      metaDataItemTitleHint:
        "작품 키워드 및 추천에 사용될 정보를 입력하고 관리합니다.",
    },
    media: {
      featuredCharacterAnimation: "대표 섬네일 동영상A (추천탭)",
      featuredCharacterAnimationFirstFrame: "동영상 A 첫번째 프레임",
      featuredCharacterAnimationLastFrame: "동영상 A 마지막 프레임",
      featuredCharacterImageA: "대표 섬네일 이미지 A (추천탭)",
      featuredCharacterSearchThumbnailImageA: "대표 검색 섬네일 이미지 A",
      decorationImage: "데코이미지 (추천탭)",
      featuredCharacterImageB: "대표 섬네일 B (시간표)",
      titleImageA: "타이틀 A (추천탭)",
      titleImageB: "타이틀 B (시간표)",
      backgroundImage: "배경이미지 (전체공통)",
      backgroundColor: "배경칼라 (전체공통)",
      backgroundSearchThumbnailImage: "배경 검색섬네일 이미지",
      lookThroughImages: "훑어보기 (시간표)",
      lookThroughImage: "훑어보기 - 병합",
      anchorClip: "앵커 타입",
      anchorClipVideo: "앵커 동영상",
      anchorClipFirstFrame: "앵커 얼라이브 첫번째 프레임",
      anchorClipLastFrame: "앵커 얼라이브 마지막 프레임",
      ipPromotionVideo: "IP 영상",
      ipPromotionVideoTitle: "IP 영상 제목",
      ipPromotionVideoSubtitle: "IP 영상 부제목",
      ipPromotionVideoA: "IP 영상 A",
      ipPromotionVideoAToolTip:
        "(모바일 해상 대응) 영상 등록 시, 랭킹에 노출됩니다.",
      ipPromotionVideoB: "IP 영상 B",
      ipPromotionVideoBToolTip:
        "(테블릿 해상 대응) 영상 등록 시, 랭킹에 노출됩니다.",
      videoClips: "작품 정보 영상",
      thumbnailImage: "유니버스 썸네일 이미지",
      sharingThumbnailImage: "공유 썸네일 이미지",
      contentInformationVideoRegister: "작품 정보 영상 등록",
      contentInformationVideoRegisterToolTip:
        "작품 정보 영상 등록 시, 해당 동영상의 Key 값을 선택해주세요.",
      contentInformationVideoFormClearToolTip:
        "작성된 IP 영상 양식을 비웁니다.",
      linkToKakaopageOriginalNovel: "카카오페이지 원작소설 연동",
      linkToKakaopageOriginalNovelToolTip:
        "작품정보, 뷰어에 카카오페이지 원작소설이 노출됩니다. 단, 카카오페이지 원작 소설의 판매중인 경우에만 노출됩니다.",
      kakaopageContentId: "카카오페이지 작품ID",
      originalNovelThumbnail: "원작소설 섬네일",
      kakaopageContentNotSellingComment:
        "판매 상태가 아닌 작품은 연동할 수 없습니다. 작품을 확인해주세요.",
      kakaopageContentNoMatchedComment:
        "일치하는 작품이 없습니다. 카카오페이지 작품 ID를 확인해주세요.",
      contentTitle: "작품명: ",
      emptyOriginalNovelThumbnail: "원작소설 섬네일이 비어있습니다.",
      emptyKakaopageContentId: "카카오페이지 작품 ID가 비어있습니다.",
      choices: {
        ANCHOR_CLIP: {
          ALIVE: "얼라이브",
          LOOP: "무한루프",
        },
      },
    },
    bm: {
      productType: "상품 타입 지정",
      choices: {
        PAY: "유료",
        EARLY_ACCESS: "미리보기",
        WAIT_FOR_FREE: "기다무",
        WAIT_FOR_FREE_FIX: "기다무(고정)",
        RERUN_SERIES: "재연재",
        PAY_AND_EARLY_ACCESS: "유료 + 미리보기",
        WAIT_FOR_FREE_AND_EARLY_ACCESS: "기다무 + 미리보기",
        PAY_AND_WAIT_FOR_FREE_FIX: "유료 + 기다무(고정)",
        WAIT_FOR_FREE_AND_WAIT_FOR_FREE_FIX: "기다무 + 기다무(고정)",
        FREE: "무료",
        button: {
          BEFORE_CHANGE: "변경버튼",
          ONGOING_CHANGE: "변경모드",
          CANCEL_RESERVATION: "예약취소",
        },
      },
      singleType: "단일형",
      combinationType: "조합형",
      day: "일",
      hour: "시간",
      minute: "분",
      waitfreePeriod: "충전시간",
      BlockedWaitfreeEpisodes: "열람제한수",
      BlockedWaitfreeEpisodesRule: "최근 업데이트된 N회차",
      waitfreeEpisodes: "기다무회차수",
      waitfreeEpisodesRule: "기다무로 제공할 N회차",
      freeStartDate: "무료전환 시작 일자",
      freeStartEpisode: "무료전환 시작 회차",
      freeStartEpisodeRule: "무료전환을 시작할 N번째 회차, 이 회차 이전은 무료",
      freeEpisodesNum: "무료 회차수",
      freeEpisodesNumRule:
        "무료로 제공할 N회차(초과하여 오픈시 가장 마지막 것이 잠김)",
      ticketTypeSetting: "이용권 종류 설정",
      possessionTicket: "소장권",
      rentalTicket: "대여권",
      ticketPriceSetting: "이용권 가격 설정",
      applyImmediately: "즉시 적용",
      applyReservation: "예약 적용",
      cancel: "취소",
      save: "저장",
      waitForFreeIntervalUpdate: "충전시간 변경",
      toChange: "로 변경",
      singleSelling: "개별 판매",
      packageSelling: "패키지 판매",
      oneEpisodePrice: "1회차 가격",
      standardTicketNum: "기준 이용권 개수",
      additionalTicketNum: "추가 제공 이용권 개수",
      welcomeBenefitTickets: "신규고객혜택",
      welcomeBenefitTicketsRule: "신규고객에게 N개 이용권 제공",
      ticketPriceSave: "가격 저장",
      welcomeBenefitTicketsSave: "신규혜택 저장",
      sameBMSetting: "기존과 다른 BM을 설정해주세요.",
      weekdaysSetting: "연재요일을 설정해주세요.",
      samePriceSetting: "기존과 다른 가격을 설정해주세요.",
      doesNotExist: "해당 값이 존재하지 않습니다.",
      setPriceFirst: "가격 먼저 입력해주세요.",
      canNotSetTicketCountZero: "기준 이용권 개수는 0으로 설정할 수 없습니다.",
      deleteTicketType: "정말로 이용권을 해지하시겠습니까?",
      setAfterCurrentTime: "현재시간 이후로 설정해주세요.",
      earlyAccessOption: "미리보기 옵션",
      earlyAccessBMStartEpisodeNumber: "미리보기 시작회차",
      waitForFreeFixBMStartEpisodeNumber: "기다무(고정) 시작 회차",
      payRange: "유료 범위",
      earlyAccessRange: "미리보기 범위",
      waitForFreeRange: "기다무 범위",
      waitForFreeFixRange: "기다무(고정) 범위",
      waitForFreeOption: "기다무 옵션",
      waitForFreeFixOption: "기다무(고정) 옵션",
      commonOption: "공통 옵션",
      maxFreeEpisodeCountNone: "무료 회차수 없음",
      maxFreeEpisodeCountNoneComment:
        "무료 회차수 무시하고 오픈 된 무료 회차는 모두 무료(미리보기와 같이 동작)",
    },
    notice: {
      listPageTitle: "작품 공지사항 리스트",
      newPageTitle: "새로운 작품 공지사항 생성",
      editPageTitle: "작품 공지사항 수정",
      viewPageTitle: "작품 공지사항 보기",
    },
    universe: {
      saveCurrentOrders: "현재 순서 저장",
    },
    immediately: "즉시",
    reservation: "예약",
    reservationDateTime: "예약 일시",
    reservationHint: "예약은 5분 단위로 적용됩니다.",
    changeImmediately: "상태를 즉시 변경 하시겠습니까?",
    changeReservation: "상태 변경을 예약 하시겠습니까?",
    selling: "판매",
  },
});
