import { DateType } from "@/utils/tzDayjs/index";
import { Path } from "@/utils/path";

export type VForm = Vue & {
  validate: () => boolean;
  resetValidation: () => boolean;
  reset: () => void;
};

export interface DeleteItem {
  id?: number;
}

export type DateTime = DateType;

export type Nullable<T> = null | T;

export interface ChoiceItem<T> {
  text: string;
  value: T;
}

export type PathLike = string | Path;

export interface AdminLanguage {
  code: string;
  flag: string;
  label: string;
}

export enum Env {
  LOCAL = "local",
  SANDBOX = "sandbox",
  QA = "qa",
  PROD = "prod",
}
