interface AdminLanguage {
  lang: string;
}

const state: AdminLanguage = {
  lang: "ko",
};

const getters = {
  adminLang: (state: AdminLanguage): string => {
    return state.lang ?? "ko";
  },
};

const mutations = {
  setAdminLanguage(state: AdminLanguage, lang: string): void {
    state.lang = lang;
  },
};

export default {
  state,
  getters,
  mutations,
};
