import i18n from "@/plugins/vue-i18n";
import { Choices } from "@/utils/choices";

export enum MetadataType {
  SUB_GENRE = "SUB_GENRE",
  EMOTION = "EMOTION",
  STORY_ELEMENT = "STORY_ELEMENT",
  MATERIAL = "MATERIAL",
  DRAWING_STYLE = "DRAWING_STYLE",
}

export const METADATA_TYPE = Object.freeze(
  new Choices(MetadataType, {
    [MetadataType.SUB_GENRE]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.SUB_GENRE").toString(),
      value: MetadataType.SUB_GENRE,
    },
    [MetadataType.EMOTION]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.EMOTION").toString(),
      value: MetadataType.EMOTION,
    },
    [MetadataType.STORY_ELEMENT]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.STORY_ELEMENT").toString(),
      value: MetadataType.STORY_ELEMENT,
    },
    [MetadataType.MATERIAL]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.MATERIAL").toString(),
      value: MetadataType.MATERIAL,
    },
    [MetadataType.DRAWING_STYLE]: {
      text: i18n.t("cms.metadataItem.METADATA_TYPE.DRAWING_STYLE").toString(),
      value: MetadataType.DRAWING_STYLE,
    },
  }),
);
