import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import setting from "@/store/modules/setting";
import snack from "@/store/modules/snack";
import adminLanguage from "@/store/modules/admin-language";
import serviceLanguage from "@/store/modules/service-language";
import kakaopageAuth from "@/store/modules/kakaopage-auth";
import createPersistedState from "vuex-persistedstate";
import dataLanguage from "@/store/modules/data-language";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    setting,
    snack,
    adminLanguage,
    serviceLanguage,
    kakaopageAuth,
    dataLanguage,
  },
  plugins: [
    createPersistedState({
      paths: ["auth", "adminLanguage", "kakaopageAuth", "dataLanguage"],
    }),
  ],
});
