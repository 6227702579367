import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "@/plugins/vue-i18n"; // NOTE: 반드시 vuetify 전에 import
import vuetify from "@/plugins/vuetify";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import "./scss/common.scss";

Vue.config.productionTip = false;

if (
  process.env.VUE_APP_SENTRY_DSN &&
  process.env.VUE_APP_ENVIRONMENT !== "local"
) {
  Sentry.init({
    Vue: Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0,
  });
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
