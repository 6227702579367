import dotenvParseVariables from "dotenv-parse-variables";

const parsedEnv: { [key: string]: unknown } = dotenvParseVariables(process.env);

export const envs = {
  FOO: parsedEnv.VUE_APP_FOO as string,
  BAZ: parsedEnv.VUE_APP_BAZ as number,
  BEEP: parsedEnv.VUE_APP_BEEP as boolean,
  BOOP: parsedEnv.VUE_APP_BOOP as string[],
  BLEEP: parsedEnv.VUE_APP_BLEEP as string,
  PING: parsedEnv.VUE_APP_PING as (string | number)[],
  PONG: parsedEnv.VUE_APP_PONG as string[],
  GATEWAY_URL: parsedEnv.VUE_APP_GATEWAY_URL as string,
  DATE_TIME_ZONE: parsedEnv.VUE_APP_DATE_TIME_ZONE as string,
  DATE_FORMAT_STRING: parsedEnv.VUE_APP_DATE_FORMAT_STRING as string,
  DATETIME_FORMAT_STRING: parsedEnv.VUE_APP_DATETIME_FORMAT_STRING as string,
  DATETIME_TZ_API_FORMAT_STRING:
    parsedEnv.VUE_APP_DATETIME_TZ_API_FORMAT_STRING as string,
  TIME_MINUTE_FORMAT_STRING:
    parsedEnv.VUE_APP_TIME_MINUTE_FORMAT_STRING as string,
  DATETIME_MINUTE_FORMAT_STRING:
    parsedEnv.VUE_APP_DATETIME_MINUTE_FORMAT_STRING as string,
  PUBLIC_CONTENTS_BUCKET: parsedEnv.VUE_APP_PUBLIC_CONTENTS_BUCKET as string,
  PRIVATE_CONTENTS_BUCKET: parsedEnv.VUE_APP_PRIVATE_CONTENTS_BUCKET as string,
  PUBLIC_CONTENTS_BUCKET_ROOT_FOLDER:
    parsedEnv.VUE_APP_PUBLIC_CONTENTS_BUCKET_ROOT_FOLDER as string,
  PRIVATE_DATA_BUCKET: parsedEnv.VUE_APP_PRIVATE_DATA_BUCKET as string,
  CDN_HOST: parsedEnv.VUE_APP_CDN_HOST as string,
  SERVICE_REGION_CODE: parsedEnv.VUE_APP_SERVICE_REGION_CODE as string,
  SERVICE_LANG_CODE_LIST: parsedEnv.VUE_APP_SERVICE_LANG_CODE_LIST as string[],
  IMAGE_SERVER_ADDRESS: parsedEnv.VUE_APP_IMAGE_SERVER_ADDRESS as string,
  BILLING_SITE_CODE: parsedEnv.VUE_APP_BILLING_SITE_CODE as string,
  SKIP_AUTH: parsedEnv.VUE_APP_SKIP_AUTH as boolean,
  COGNITO_LOGIN_ENDPOINT: `https://${parsedEnv.VUE_APP_COGNITO_ENDPOINT}/login?client_id=${parsedEnv.VUE_APP_COGNITO_CLIENT_ID}&redirect_uri=${parsedEnv.VUE_APP_COGNITO_LOGIN_REDIRECT_URI}&response_type=code`,
  COGNITO_LOGOUT_ENDPOINT: `https://${parsedEnv.VUE_APP_COGNITO_ENDPOINT}/logout?client_id=${parsedEnv.VUE_APP_COGNITO_CLIENT_ID}&logout_uri=${parsedEnv.VUE_APP_COGNITO_LOGOUT_REDIRECT_URI}&redirect_uri=dummy&response_type=code`,
  ENVIRONMENT: parsedEnv.VUE_APP_ENVIRONMENT as string,
  SERVICE_URL: parsedEnv.VUE_APP_SERVICE_URL as string,
  CLOUD_NAME: parsedEnv.VUE_APP_CLOUD_NAME as string,
};
