import { ServiceLanguage } from "@/const/service/language";
import { ServiceRegion } from "@/const/service/region";
import { Language, Region } from "@/views/cms/content/common/choices";
import { Korean } from "@/const/service/languages/korean";
import { Taiwanese } from "@/const/service/languages/taiwanese";
import { Thai } from "@/const/service/languages/thai";
import { Indonesian } from "@/const/service/languages/indonesian";
import { Korea } from "@/const/service/regions/korea";
import { Taiwan } from "@/const/service/regions/taiwan";
import { Thailand } from "@/const/service/regions/thailand";
import { Indonesia } from "@/const/service/regions/indonesia";
import { envs } from "@/const/dotenv";

function getServiceLanguage(): ServiceLanguage {
  const availableLanguageCodeList = envs.SERVICE_LANG_CODE_LIST;

  // TODO: vuex 를 통해 현재 운영 중인 서비스 언어를 가져온다.
  const currentServiceLanguage = availableLanguageCodeList[0];
  if (currentServiceLanguage === Language.KOREAN) {
    return new Korean();
  } else if (currentServiceLanguage === Language.TAIWANESE) {
    return new Taiwanese();
  } else if (currentServiceLanguage === Language.THAI) {
    return new Thai();
  } else if (currentServiceLanguage === Language.INDONESIAN) {
    return new Indonesian();
  } else {
    return new Korean();
  }
}

function getServiceRegion(): ServiceRegion {
  const currentServiceRegion = envs.SERVICE_REGION_CODE;

  if (currentServiceRegion === Region.KOREA) {
    return new Korea();
  } else if (currentServiceRegion === Region.TAIWAN) {
    return new Taiwan();
  } else if (currentServiceRegion === Region.THAILAND) {
    return new Thailand();
  } else if (currentServiceRegion === Region.INDONESIA) {
    return new Indonesia();
  } else {
    return new Korea();
  }
}

export const serviceLanguage = getServiceLanguage();
export const serviceRegion = getServiceRegion();
