export default Object.freeze({
  en: {
    home: "Home",
    cms: "CMS",
    contents: "Content",
    sms: "SMS",
    sections: "Section",
    eventPopup: "Splash Popup",
    bridgePage: "Special Bridge Page",
    matching: "Collection",
    converter: "Image Converter",
    reward: "Reward",
    present: "Present",
    raffle: "Raffle",
    luckyDraw: "Lucky Draw",
    datePicker: "Date Picker",
    author: "Author",
    cp: "CP",
    metadata: "Metadata",
    crm: "CRM",
    util: "Utility",
    messagePromotion: "Message Promotion",
    segmentTicker: "Segment Ticker",
    questCampaign: "Quest Campaign",
    redeemCodeCampaign: "Redeem Code Campaign",
    contentDiscountCampaign: "Ticket Discount Campaign",
    ticketGiftbox: "Ticket Giftbox",
    giftPromotion: "Gift Promotion",
    noticeCs: "Notice/CS",
    notice: "Notice",
    faq: "FAQ",
    noticePopup: "Popup Notification",
    memberManagement: "Member",
    memberSearch: "Search Member",
    serviceBlockUserManagement: "Blocked User",
    nicknameProhibitionManagement: "Nickname Restrictions",
    commentSystem: "Comment Management System",
    commentReport: "Report Comments Monitoring",
    comment: "Comment Management",
    commentBannedUser: "Blocked Commenter Management",
    commentBannedWord: "Prohibited Words Management",
    userManagement: "Admin management",
    users: "Admin",
    roles: "Role",
    appVersion: "App Version Management",
    korProdAppVersion: "Korea Version",
    globalProdAppVersion: "Global Version",
    korQAAppVersion: "mobil) Korea Version",
    globalQAAppVersion: "mobil) Global Version",
    korSandboxAppVersion: "dev) Korea Version",
    globalSandboxAppVersion: "dev) Global Version",
  },
  ko: {
    home: "홈",
    cms: "CMS",
    contents: "작품",
    sms: "SMS",
    sections: "섹션 관리",
    eventPopup: "스플래시 팝업",
    bridgePage: "스페셜 브릿지 페이지",
    matching: "컬렉션",
    converter: "이미지 컨버터",
    reward: "리워드 관리",
    present: "경품 관리",
    raffle: "응모권 관리",
    luckyDraw: "뽑기권 관리",
    datePicker: "날짜 선택",
    author: "작가 관리",
    submissionEpisodes: "송고 회차 관리",
    cp: "CP",
    metadata: "메타데이터",
    crm: "CRM",
    util: "유틸",
    messagePromotion: "메시지 프로모션",
    segmentTicker: "세그먼트 티커",
    questCampaign: "퀘스트 캠페인",
    redeemCodeCampaign: "리딤코드 캠페인",
    contentDiscountCampaign: "이용권 구매 할인 캠페인",
    ticketGiftbox: "이용권 선물함 관리",
    giftPromotion: "통합 선물함 프로모션",
    noticeCs: "공지/CS 관리",
    notice: "공지 관리",
    faq: "FAQ 관리",
    noticePopup: "공지 팝업 관리",
    memberManagement: "회원관리",
    memberSearch: "회원조회",
    serviceBlockUserManagement: "서비스 차단 유저관리",
    nicknameProhibitionManagement: "닉네임 금칙어 관리",
    commentSystem: "댓글 관리 시스템",
    commentReport: "신고 댓글 모니터링",
    comment: "댓글 관리",
    commentBannedUser: "댓글 차단 유저 관리",
    commentBannedWord: "금칙어 관리",
    userManagement: "운영자 관리",
    users: "운영자",
    roles: "역할",
    appVersion: "앱버전 관리",
    korProdAppVersion: "한국 버전",
    globalProdAppVersion: "글로벌 버전",
    korQAAppVersion: "모빌용) 한국 버전",
    globalQAAppVersion: "모빌용) 글로벌 버전",
    korSandboxAppVersion: "개발용) 한국 버전",
    globalSandboxAppVersion: "개발용) 글로벌 버전",
  },
});
